import React, { FunctionComponent, useMemo } from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../ducks'
import { DNKRoles } from '../../../ducks/globalUser'
import { getSumsOfAreasInInvestments } from '../../../ducks/places'
import { hasRoles } from '../../../services/idp'

import ToggleContainer from '../Containers/ToggleContainer'

import './Table.scss'

const ToggleSumBar: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    chosenInvestment,
    chosenStage,
    investmentsList,
    isArchiveMode,
  } = useSelector((state: RootState) => state.globalInvestment)
  const { placesAreas } = useSelector((state: RootState) => state.places)
  const { roles } = useSelector((state: RootState) => state.globalUser)
  const isDNKCustomer = hasRoles(DNKRoles, roles)
  const investmentsNames = useMemo((): string[] => {
    const allInvestments = investmentsList.map((investment) => investment.name)
    return chosenInvestment?.name !== 'Wszystkie'
      ? [chosenInvestment?.name]
      : allInvestments
  }, [chosenInvestment, investmentsList])

  React.useEffect(() => {
    dispatch(
      getSumsOfAreasInInvestments({
        investmentsNames,
        isTypeService: isDNKCustomer,
        stageID: chosenStage?.id,
        showArchived: isArchiveMode,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    chosenStage?.id,
    investmentsNames,
    isArchiveMode,
    isDNKCustomer,
    dispatch,
  ])

  return (
    <ToggleContainer
      arrowColor='#000000'
      barColor='main-bg-color'
      customWidth={97.7}
      title={t('investment:labels.total-of-areas-in-investment')}
    >
      <>
        <ListGroup as={Col} lg={6} md={12} sm={12}>
          <ListGroup.Item
            as={Row}
            className='mx-0 ik2-bg-color font-weight-bold placeAreaClass'
          >
            {t('investment:labels.sum-of-places-areas')}:
          </ListGroup.Item>
          <Row className='mx-0'>
            <ListGroup.Item as={Col} lg={3} sm={3} className='ik2-bg-color'>
              <p className='m-0'>Proj. (użyt.):</p>
              <p className='m-0'>
                {Number(placesAreas.designedUsablePropertyAreas)
                  .toFixed(2)
                  ?.replace('.', ',') || 0}
              </p>
            </ListGroup.Item>
            <ListGroup.Item as={Col} lg={3} sm={3} className='ik2-bg-color'>
              <p className='m-0'>Proj. (wew.):</p>
              <p className='m-0'>
                {Number(placesAreas.designedInnerPropertyAreas)
                  .toFixed(2)
                  ?.replace('.', ',') || 0}
              </p>
            </ListGroup.Item>
            <ListGroup.Item as={Col} lg={3} sm={3} className='ik2-bg-color'>
              <p className='m-0'>Powyk. (użyt.):</p>
              <p className='m-0'>
                {Number(placesAreas.asBuildUsablePropertyAreas)
                  .toFixed(2)
                  ?.replace('.', ',') || 0}
              </p>
            </ListGroup.Item>
            <ListGroup.Item as={Col} lg={3} sm={3} className='ik2-bg-color'>
              <p className='m-0'>Powyk. (wew.):</p>
              <p className='m-0'>
                {Number(placesAreas.asBuildInnerPropertyAreas)
                  .toFixed(2)
                  ?.replace('.', ',') || 0}
              </p>
            </ListGroup.Item>
          </Row>
        </ListGroup>
        <ListGroup as={Col} lg={6} md={12} sm={12}>
          <ListGroup.Item
            as={Row}
            className='mx-0 ik2-bg-color font-weight-bold '
          >
            {t('investment:labels.sum-of-utility-rooms-areas')}:
          </ListGroup.Item>
          <Row className='mx-0'>
            <ListGroup.Item
              as={Col}
              lg={4}
              sm={3}
              className='ik2-bg-color my-0'
            >
              <p className='m-0'>Proj. (wew.):</p>
              <p className='m-0'>
                {Number(placesAreas.designedInnerUtilRoomAreas)
                  .toFixed(2)
                  ?.replace('.', ',') || 0}
              </p>
            </ListGroup.Item>
            <ListGroup.Item as={Col} lg={4} sm={3} className='ik2-bg-color'>
              <p className='m-0'>Powyk. (wew.):</p>
              <p className='m-0'>
                {Number(placesAreas.asBuildInnerUtilRoomAreas)
                  .toFixed(2)
                  ?.replace('.', ',') || 0}
              </p>
            </ListGroup.Item>
            <ListGroup.Item as={Col} lg={4} sm={3} className='ik2-bg-color'>
              <p className='m-0'>Powyk. (użyt.):</p>
              <p className='m-0'>
                {Number(placesAreas.asBuildUsableUtilRoomAreas)
                  .toFixed(2)
                  ?.replace('.', ',') || 0}
              </p>
            </ListGroup.Item>
          </Row>
        </ListGroup>
      </>
    </ToggleContainer>
  )
}

export default ToggleSumBar
