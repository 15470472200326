import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../ducks'
import { hasRoles } from '../services/idp'

export const useMainTableHiddenColumns = (
  isAlreadyHidden?: boolean
): string[] => {
  const { roles } = useSelector((state: RootState) => state.globalUser)

  const hiddenColumnsByRoles = useMemo(() => {
    const sensitiveToHide = isAlreadyHidden
      ? ['firstName1', 'firstName2', 'lastName1', 'lastName2']
      : []
    if (hasRoles(['DSP', 'DSP-ADM'], roles)) {
      return ['designedUsableUtilRoomArea', ...sensitiveToHide]
    } else if (hasRoles(['DAR'], roles)) {
      return ['Walor.', 'Wysyłka FV na @', 'Kredyt', ...sensitiveToHide]
    } else if (hasRoles(['DPI'], roles) || hasRoles(['DPP'], roles)) {
      return [
        'Walor.',
        'Wysyłka FV na @',
        'Kredyt',
        'firstName1',
        'firstName2',
        'lastName1',
        'lastName2',
      ]
    } else if (hasRoles(['SEK'], roles)) {
      return ['Walor.', 'Kredyt', ...sensitiveToHide]
    } else if (hasRoles(['DKO'], roles)) {
      return ['Wysyłka FV na @', 'Kredyt', ...sensitiveToHide]
    } else if (hasRoles(['DNI'], roles) || hasRoles(['SKA'], roles)) {
      return ['Wysyłka FV na @', ...sensitiveToHide]
    }
    return [''] as string[]
  }, [isAlreadyHidden, roles])

  return hiddenColumnsByRoles
}
