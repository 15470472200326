import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../ducks'
import { clearUnassignedCustomer } from '../../ducks/unassignedCustomers'
import { TableBodyWrapper } from './TableBodyWrapper'
import Table from '../commons/Table2/Table'
import unassignedCustomersCols from '../../mockups/UnassignedCustomers_Columns'
import { getTraderInitialsOfCustomer } from '../../ducks/interestedCustomers'
import { getUserInitialsListWithIDs } from '../../ducks/globalUser'
import {
  fetchTimeToServeNotTaken,
  fetchTimeToServeTaken,
} from '../../ducks/settings'
import UnassignedCustomers from './UnassignedCustomers'

const TableUnassignedCustomers: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch()
  const { unassignedCustomers, totalUnassignedCustomers } = useSelector(
    (state: RootState) => state.unassignedCustomers
  )

  const unassignedCustomersColumns = React.useMemo(
    () => unassignedCustomersCols,
    []
  )

  useEffect((): void => {
    dispatch(getTraderInitialsOfCustomer())
    dispatch(getUserInitialsListWithIDs(['DSP', 'DSP-ADM']))
  }, [dispatch])

  useEffect(() => {
    return (): void => {
      dispatch(clearUnassignedCustomer())
    }
  }, [])

  useEffect(() => {
    dispatch(fetchTimeToServeTaken())
    dispatch(fetchTimeToServeNotTaken())
  }, [dispatch])

  return (
    <Table
      customLoading={false}
      columns={unassignedCustomersColumns}
      data={unassignedCustomers ?? []}
      defaultNumberOfRows={20}
      totalRows={totalUnassignedCustomers}
      manualSortBy={true}
    >
      <TableBodyWrapper>
        {(pageSize: string, selectedRow: string, sortBy: any) => (
          <UnassignedCustomers {...{ pageSize, selectedRow, sortBy }} />
        )}
      </TableBodyWrapper>
    </Table>
  )
}

export default TableUnassignedCustomers
