import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Button, Col, Toast } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../ducks'
import {
  IAttachment,
  IMessage,
  IUnassignedCustomer,
  forwardUnassignedCustomer,
  getMessageFileByMessageFileID,
  getUnassignedCustomerByID,
  getUnassignedCustomers,
  setNumberOfMessagesChange,
} from '../../ducks/unassignedCustomers'
import NewInterested from '../InterestedCustomers/NewInterested'
import ReduxModal from '../commons/Modals/ReduxModal'
import {
  refetchPagination,
  setUnassignedCustomersSearchQuery,
} from '../commons/Table2/paginationSlice'
import Table from '../commons/Table2/Table'
import TableMessagesOfUnassignedCustomers from './TableMessages'
import { StorageKeys } from '../../hooks/useBrowserStorage'
import { getCellStyle } from './functions'
import ActionCell from './ActionCell'
import TradersModal from '../commons/Modals/tradersModal'
import TableInterestedCustomer from './TableInterestedCustomer'
import AssignCustomerModal from './AssignCustomerModal'
import TableHeaderWrapper from './TableHeaderWrapper'
import { toastZIndex } from '../../constants/zIndexes'

const UnassignedCustomers: FunctionComponent<any> = ({
  pageSize,
  selectedRow,
  sortBy,
}) => {
  const [showToast, setShowToast] = useState(true)
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const { unassignedCustomersSearchQuery } = useSelector(
    (state: RootState) => state.pagination
  )
  const fetchParams = {
    perPage: pageSize,
    sortField: sortBy?.id,
    sortOrder: sortBy?.desc,
  }
  const { timeToServeTaken, timeToServeNotTaken } = useSelector(
    (state: RootState) => state.settings
  )
  const {
    isLoadingUnassignedCustomers,
    unassignedCustomers,
    selectedUnassignedCustomer,
    numberOfMessagesChange,
  } = useSelector((state: RootState) => state.unassignedCustomers)

  const { userInitialsListWithIDs } = useSelector(
    (state: RootState) => state.globalUser
  )

  const [
    isExistingInterestedToAssignModalVisible,
    setIsExistingInterestedToAssignModalVisible,
  ] = useState(false)
  const [isTakingUnassigned, setIsTakingUnassigned] = useState(false)

  const [
    isNewInterestedToAssignModalVisible,
    setIsNewInterestedToAssignModalVisible,
  ] = useState(false)
  const [isSpamModalVisible, setIsSpamModalVisible] = useState(false)
  const [isClientModalVisible, setIsClientModalVisible] = useState(false)
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [
    isMessageContentModalVisible,
    setIsMessageContentModalVisible,
  ] = useState(false)
  const selectedUnassigned: IUnassignedCustomer =
    unassignedCustomers[Number(selectedRow)]
  const [showMyCustomers, setShowMyCustomers] = useState<boolean>(false)
  const [showAllCustomers, setShowAllCustomers] = useState<boolean>(false)
  const [showNotTakenCustomers, setShowNotTakenCustomers] = useState<boolean>(
    true
  )
  const [showAllMessages, setShowAllMessages] = useState<boolean>(true)
  const [assignModalType, setAssignModalType] = useState<string | undefined>(
    undefined
  )
  const [showTradersModal, setShowTradersModal] = useState(false)

  const showAssignActionsButtons =
    selectedUnassigned?.id && selectedUnassigned?.status === 'taken'

  const emailOfUnassignedCustomer =
    sessionStorage.getItem('emailOfUnassignedCustomer') ?? ''

  const handleShowInterestedModal = useCallback(
    (isNew?: boolean) => {
      isNew
        ? setIsNewInterestedToAssignModalVisible(true)
        : setIsExistingInterestedToAssignModalVisible(true)
      setAssignModalType(undefined)
    },
    [dispatch]
  )

  const showAttachments = (): void => {
    selectedUnassignedCustomer?.messages?.map((m: IMessage) => {
      m?.attachments?.map((attachment: IAttachment) => {
        dispatch(
          getMessageFileByMessageFileID({ messageFileID: attachment.id })
        ).then((response: any) => {
          const downloadLink = document.createElement('a')
          document.body.appendChild(downloadLink)
          downloadLink.href = downloadLink.href = `data:application;base64,${response.payload.messageFile.getMessageFile.content}`
          downloadLink.target = '_self'
          downloadLink.download = attachment?.name ?? 'Załącznik'
          downloadLink.click()
        })
      })
    })
  }

  const handleClose = () => {
    setIsExistingInterestedToAssignModalVisible(false)
    setIsNewInterestedToAssignModalVisible(false)
    setIsTakingUnassigned(false)
  }

  const handleShowMyCustomers = (): void => {
    const newShowMyCustomers = !showMyCustomers
    setShowNotTakenCustomers(false)
    dispatch(
      getUnassignedCustomers({
        statuses: showAllCustomers ? undefined : ['taken'],
        isTraderInitials: newShowMyCustomers,
        ...fetchParams,
      })
    )
    setShowMyCustomers(newShowMyCustomers)
  }

  const handleShowAllCustomers = (): void => {
    const newShowAllCustomers = !showAllCustomers
    setShowNotTakenCustomers(true)
    dispatch(
      getUnassignedCustomers({
        statuses: newShowAllCustomers ? undefined : ['notTaken'],
        isTraderInitials: showMyCustomers,
        ...fetchParams,
      })
    )

    setShowAllCustomers(newShowAllCustomers)
  }
  const handleShowNotTakenCustomers = (): void => {
    const newShowNotTakenCustomers = !showNotTakenCustomers
    setShowAllCustomers(false)

    dispatch(
      getUnassignedCustomers({
        statuses: newShowNotTakenCustomers
          ? ['notTaken']
          : ['notTaken', 'taken'],
        isTraderInitials: showMyCustomers,
        ...fetchParams,
      })
    )

    setShowNotTakenCustomers(newShowNotTakenCustomers)
  }

  const fetchUnassignedCustomers = useCallback(
    (variables: any) => {
      dispatch(
        getUnassignedCustomers({
          ...variables,
          sortField: variables?.sortBy?.id,
          sortOrder: variables?.sortBy?.desc,
        })
      )
    },
    [dispatch]
  )

  const getActionCell = (props: any): JSX.Element => (
    <ActionCell
      {...props}
      setShowModal={setShowTradersModal}
      setIsTakingUnassigned={setIsTakingUnassigned}
    />
  )
  const forwardCustomer = (traderInitials: string): void => {
    if (selectedUnassigned?.id) {
      dispatch(
        forwardUnassignedCustomer({
          traderInitials,
          unassignedCustomerID: selectedUnassigned.id,
        })
      ).then(() => dispatch(refetchPagination(true)))
      setShowTradersModal(false)
    }
  }

  useEffect(() => {
    selectedUnassigned?.id &&
      dispatch(
        getUnassignedCustomerByID({
          unassignedCustomerID: selectedUnassigned.id,
        })
      )
  }, [dispatch, selectedUnassigned])

  const allModalNotShow =
    !showTradersModal &&
    !assignModalType &&
    !isExistingInterestedToAssignModalVisible &&
    !isNewInterestedToAssignModalVisible &&
    !isClientModalVisible &&
    !isAddModalVisible &&
    !isSpamModalVisible &&
    !isMessageContentModalVisible &&
    !isTakingUnassigned

  useEffect(() => {
    if (numberOfMessagesChange && allModalNotShow) {
      dispatch(setNumberOfMessagesChange(false))
      dispatch(refetchPagination(true))
    }
  }, [numberOfMessagesChange, allModalNotShow])

  const [selectedUserId, setSelectedUserId] = useState(undefined)

  const onSelectRow = (row: any): any => {
    if (!selectedRow || selectedRow != row.id) {
      setSelectedUserId(row.original.id)
      dispatch(refetchPagination(true))
    } else {
      setSelectedUserId(undefined)
    }
    setShowAllMessages(true)
  }

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <TableHeaderWrapper top={'150px'}>
        <Table.Header align='end'>
          <Col
            className='d-flex justify-content-end mx-1 px-0'
            lg='auto'
            md='auto'
            sm='auto'
          >
            {showAssignActionsButtons ? (
              <>
                <Button
                  className='primary'
                  onClick={(): void =>
                    selectedUnassigned?.customerID
                      ? setAssignModalType('assign-to-interested')
                      : handleShowInterestedModal()
                  }
                >
                  {t('customers:actions.assign-to-existing-interested')}
                </Button>
                <Button
                  className='primary'
                  onClick={(): void =>
                    selectedUnassigned?.customerID
                      ? setAssignModalType('assign-to-new-interested')
                      : handleShowInterestedModal(true)
                  }
                >
                  {t('customers:actions.assign-to-new-interested')}
                </Button>
              </>
            ) : null}
            <Button
              className='primary'
              disabled={isLoadingUnassignedCustomers}
              onClick={handleShowAllCustomers}
            >
              {isLoadingUnassignedCustomers
                ? t('commons:actions.uploading-in-progress')
                : !showAllCustomers
                ? t('table:actions.show-all-unassigned')
                : t('table:actions.hide-archive')}
            </Button>
            <Button
              className='primary'
              disabled={
                isLoadingUnassignedCustomers ||
                showAllCustomers ||
                showMyCustomers
              }
              onClick={handleShowNotTakenCustomers}
            >
              {isLoadingUnassignedCustomers
                ? t('commons:actions.uploading-in-progress')
                : showNotTakenCustomers
                ? t('table:actions.show-taken')
                : t('table:actions.show-not-taken')}
            </Button>
            <Button
              className='primary'
              disabled={isLoadingUnassignedCustomers}
              onClick={handleShowMyCustomers}
            >
              {isLoadingUnassignedCustomers
                ? t('commons:actions.uploading-in-progress')
                : !showMyCustomers
                ? t('table:actions.show-my')
                : t('table:actions.back-from-show-my')}
            </Button>
          </Col>
          <Table.TableSearchWithPagination
            defaultValue={emailOfUnassignedCustomer}
            isLoading={isLoadingUnassignedCustomers}
            query={unassignedCustomersSearchQuery}
            setQuery={setUnassignedCustomersSearchQuery}
            refreshPage={refetchPagination}
          />
        </Table.Header>
      </TableHeaderWrapper>

      <Table.TableBody
        defaultRowSelectedId={selectedUserId}
        onSelectRow={onSelectRow}
        isLoading={isLoadingUnassignedCustomers}
        getCellStyle={(row, cell) =>
          getCellStyle(row, cell, timeToServeTaken, timeToServeNotTaken)
        }
        getActionCell={getActionCell}
      />
      <Table.PaginationWithFetch
        storageType='localStorage'
        tableKey={StorageKeys.UnassignedCustomersTable}
        genericFetch={fetchUnassignedCustomers}
        additionalParameters={{
          keyWords: unassignedCustomersSearchQuery,
          isTraderInitials: showMyCustomers,
          statuses:
            showAllCustomers === true
              ? undefined
              : showNotTakenCustomers
              ? ['notTaken']
              : ['notTaken', 'taken'],
        }}
        isGenericFetchDispatched
      />
      {selectedUnassigned && selectedUnassignedCustomer?.id ? (
        <TableMessagesOfUnassignedCustomers
          {...{
            selectedUnassignedCustomer: selectedUnassigned,
            showAttachments,
            showAllMessages,
            setShowAllMessages: (v: boolean) => setShowAllMessages(v),
            isSpamModalVisible,
            setIsSpamModalVisible,
            isClientModalVisible,
            setIsClientModalVisible,
            isAddModalVisible,
            setIsAddModalVisible,
            isMessageContentModalVisible,
            setIsMessageContentModalVisible,
          }}
        />
      ) : null}
      {isNewInterestedToAssignModalVisible && selectedUnassigned?.id ? (
        <NewInterested
          externalDefaultData={{
            id: selectedUnassigned.id,
            firstName: selectedUnassigned.firstName,
            lastName: selectedUnassigned.lastName,
            email: selectedUnassigned.email,
            phone: selectedUnassigned?.phone,
            traderInitials: selectedUnassigned?.traderInitials,
            source: selectedUnassigned?.source,
            marketingConsents: selectedUnassigned?.marketingConsents,
          }}
          handleClose={handleClose}
          isUnassignedCustomersView
          show={isNewInterestedToAssignModalVisible}
          unassignedCustomerID={selectedUnassigned.id}
        />
      ) : null}
      {(isExistingInterestedToAssignModalVisible || isTakingUnassigned) &&
      selectedUnassigned ? (
        <ReduxModal
          body={
            <TableInterestedCustomer
              selectedUnassigned={selectedUnassigned}
              isTakingUnassigned={isTakingUnassigned}
              handleClose={handleClose}
            />
          }
          cancelAction={handleClose}
          customWidth={100}
          onHide={handleClose}
          show={isExistingInterestedToAssignModalVisible || isTakingUnassigned}
          title={t('customers:actions.assign-to-interested')}
        />
      ) : null}
      <AssignCustomerModal
        title={t(
          assignModalType === 'assign-to-new-interested'
            ? 'customers:actions.assign-to-new-interested'
            : 'customers:actions.assign-to-interested'
        )}
        show={Boolean(assignModalType)}
        onSubmit={() =>
          handleShowInterestedModal(
            assignModalType === 'assign-to-new-interested'
          )
        }
        handleClose={() => setAssignModalType(undefined)}
      />
      <TradersModal
        cancelAction={(): void => setShowTradersModal(false)}
        submitAction={forwardCustomer}
        show={showTradersModal && Boolean(selectedUnassigned)}
        traders={userInitialsListWithIDs || []}
        selectedTrader={selectedUnassigned?.traderInitials}
      />
      {selectedUnassigned?.id && (
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          style={{
            bottom: '1em',
            right: '1em',
            position: 'fixed',
            zIndex: toastZIndex,
          }}
        >
          <Toast.Header>
            <strong style={{ paddingTop: '5px' }}>
              {`${t('customers:labels.customer')}: ` +
                selectedUnassigned?.firstName +
                ' ' +
                selectedUnassigned?.lastName}
            </strong>
          </Toast.Header>
        </Toast>
      )}
    </div>
  )
}

export default UnassignedCustomers
