import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Tab,
  Tabs,
  Toast,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { reset } from 'redux-form'

import { IOptionElement } from '../commons/Inputs/SimpleInputs/SimpleInputList'
import SimpleInputList2 from '../commons/Inputs/SimpleInputs/SimpleInputList2'
import EditInterested from './EditInterested'
import NewInterested from './NewInterested'
import InterestedCustomerContracts from './InterestedCustomerContracts'
import InterestedCustomerEventsAndNotes from './InterestedCustomerEventsAndNotes'
import InterestedCustomerMarketingConsents from './InterestedCustomerMarketingConsents'
import InterestedCustomerProposedPlaces from './InterestedCustomersProposedPlaces/InterestedCustomerProposedPlaces'
import { AppDispatch, RootState } from '../../ducks'
import {
  anonymizeInterestedCustomer,
  deleteInterestedCustomer,
  fetchInterestedCustomersPagination,
  getInterestedCustomerByID,
  setCurrentInterestedCustomer,
  setCurrentInterestedCustomerID,
} from '../../ducks/interestedCustomers'
import { columnsData } from '../../mockups/InterestedCustomers_Columns'
import Table from '../commons/Table2/Table'
import { refetchPagination } from '../commons/Table2/paginationSlice'
import { setInterestedCustomersSearchQuery } from '../commons/Table2/paginationSlice'
import { hasRoles } from '../../services/idp'
import { DSPADMRoles } from '../../ducks/globalUser'
import ReduxModal from '../commons/Modals/ReduxModal'

import { StorageKeys } from '../../hooks/useBrowserStorage'
import { getTraderInitialsOfCustomer } from '../../ducks/interestedCustomers'

import { InterestedCustomersTableWrapper } from './InterestedCustomersTableWrapper'

import { toastZIndex } from '../../constants/zIndexes'

export const customerStatusOptions: IOptionElement[] = [
  { name: 'Wszyscy', id: 'Wszyscy' },
  { name: 'nowy', id: 'new' },
  { name: 'aktywny', id: 'active' },
  { name: 'zrezygnował', id: 'resigned' },
  { name: 'zamrożony', id: 'frozen' },
  { name: 'zakończony', id: 'finished' },
  { name: 'sukces', id: 'success' },
]

export const InterestedCustomers: FunctionComponent = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()

  const [showToast, setShowToast] = useState(true)
  const {
    interestedCustomers,
    isLoadingCustomers,
    selectedInterestedCustomer: customer,
    selectedInterestedCustomerID,
    traderInitialsOfCustomer,
  } = useSelector((state: RootState) => state.interestedCustomers)
  const { totalInterestedCustomers } = useSelector(
    (state: RootState) => state.interestedCustomers
  )
  const { interestedCustomersSearchQuery } = useSelector(
    (state: RootState) => state.pagination
  )
  const { numberOfNotTakenUnassignedCustomers } = useSelector(
    (state: RootState) => state.unassignedCustomers
  )
  const userInitialsListWithAllOption = [
    'Wszyscy',
    ...traderInitialsOfCustomer.slice(),
  ]
  const { roles } = useSelector((state: RootState) => state.globalUser)
  const hasADMRoles = hasRoles(DSPADMRoles, roles)

  const handleChooseSelectedCustomer = useCallback(
    (passedData: { id: number }): void => {
      const sameAsPrevious = passedData.id === selectedInterestedCustomerID
      if (sameAsPrevious) {
        dispatch(setCurrentInterestedCustomer(null))
        return dispatch(setCurrentInterestedCustomerID(undefined))
      }
      dispatch(setCurrentInterestedCustomerID(passedData.id))
    },
    [dispatch, selectedInterestedCustomerID]
  )

  const toggleShowToast = (): void => {
    setShowToast(!showToast)
  }

  useEffect(() => {
    selectedInterestedCustomerID &&
      dispatch(getInterestedCustomerByID(selectedInterestedCustomerID))
  }, [dispatch, selectedInterestedCustomerID])

  useEffect(() => {
    return (): void => {
      dispatch(setCurrentInterestedCustomer(null))
      dispatch(setCurrentInterestedCustomerID(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(getTraderInitialsOfCustomer())
  }, [dispatch])

  const [
    isVisibleAddNewInterestedModal,
    setIsVisibleAddNewInterestedModal,
  ] = useState(false)
  const handleIsNewInterestedModalVisible = (): void => {
    setIsVisibleAddNewInterestedModal(true)
  }
  const handleClose = (): void => {
    setIsVisibleAddNewInterestedModal(false)
    dispatch(reset('addNewInterestedMarketing'))
  }

  const handleAnonymize = useCallback(
    (e: any) => {
      e.preventDefault()
      if (customer) {
        dispatch(anonymizeInterestedCustomer(customer.id)).then(() => {
          dispatch(refetchPagination(true))
          setIsAnonymizeDataModalVisible(false)
        })
      }
    },
    [customer, dispatch]
  )

  const [
    isAnonymizeDataModalVisible,
    setIsAnonymizeDataModalVisible,
  ] = useState(false)
  const [
    isDeleteCutomerModalVisible,
    setIsDeleteCustomerModalVisible,
  ] = useState(false)
  const switchAnonymizeDataModal = useCallback(
    () => setIsAnonymizeDataModalVisible(!isAnonymizeDataModalVisible),
    [isAnonymizeDataModalVisible]
  )
  const switchDeleteCustomerModal = useCallback(
    () => setIsDeleteCustomerModalVisible(!isDeleteCutomerModalVisible),
    [isDeleteCutomerModalVisible]
  )
  const [customerStatusToFilter, setCustomerStatusToFilter] = useState<
    IOptionElement
  >(customerStatusOptions[0])
  const [traderInitialsToFilter, setTraderInitialsToFilter] = useState(
    userInitialsListWithAllOption?.[0] ?? undefined
  )

  const handleCustomerStatusToFilterChange = (e: any): void => {
    const option =
      customerStatusOptions.find(
        (option: IOptionElement) => option.id === e.target.value
      ) ?? customerStatusOptions[0]
    setCustomerStatusToFilter(option)
    dispatch(refetchPagination(true))
  }

  const handleTraderInitialsToFilterChange = (e: any): void => {
    setTraderInitialsToFilter(e.target.value)
    dispatch(refetchPagination(true))
  }

  const onDeleteCustomer = useCallback(
    (e: any) => {
      e.preventDefault()
      dispatch(deleteInterestedCustomer(customer?.id)).then(() => {
        dispatch(setCurrentInterestedCustomer(null))
        dispatch(setCurrentInterestedCustomerID(undefined))
        dispatch(refetchPagination(true))
        setIsDeleteCustomerModalVisible(false)
      })
    },
    [customer, dispatch]
  )

  const anonymizeCustomerModal: JSX.Element = React.useMemo(
    () => (
      <Form className='w-100 p4' onSubmit={handleAnonymize}>
        <Row className='d-flex justify-content-center mb-3 px-3'>
          <span>{t('adminIT:labels.warning-anonymize-customer')}</span>
        </Row>
        <Row className='d-flex justify-content-center'>
          <Button onClick={switchAnonymizeDataModal}>
            {t('commons:actions.cancel')}
          </Button>
          <Button type='submit'>{t('commons:actions.anonymize')}</Button>
        </Row>
      </Form>
    ),
    [handleAnonymize, switchAnonymizeDataModal, t]
  )

  const deleteCustomerModal: JSX.Element = React.useMemo(
    () => (
      <Form className='w-100 p4' onSubmit={onDeleteCustomer}>
        <Row className='d-flex justify-content-center mb-3 px-3'>
          <span>
            {customer?.contracts?.length
              ? t('adminIT:labels.warning-delete-contract-customer')
              : t('adminIT:labels.warning-delete-customer')}
          </span>
        </Row>
        <Row className='d-flex justify-content-center'>
          <Button onClick={switchDeleteCustomerModal}>
            {t('commons:actions.cancel')}
          </Button>
          <Button type='submit'>{t('commons:actions.delete')}</Button>
        </Row>
      </Form>
    ),
    [customer, onDeleteCustomer, switchDeleteCustomerModal, t]
  )

  return (
    <Router>
      <Container fluid>
        <Row className='d-flex align-items-start justify-content-center'>
          <Col>
            <Table
              columns={columnsData}
              data={interestedCustomers}
              defaultNumberOfRows={5}
              totalRows={totalInterestedCustomers}
              manualSortBy={true}
              customLoading
            >
              <InterestedCustomersTableWrapper />
              <Table.Header align='end'>
                <Col
                  className='d-flex justify-content-end mx-1 px-0'
                  lg='auto'
                  md='auto'
                  sm='auto'
                >
                  <Button
                    className='primary'
                    onClick={handleIsNewInterestedModalVisible}
                  >
                    {t('commons:actions.add')}
                  </Button>
                </Col>
                {hasADMRoles && (
                  <Col
                    className='d-flex justify-content-end mx-1 px-0'
                    lg='auto'
                    md='auto'
                    sm='auto'
                  >
                    <Button
                      disabled={!customer}
                      className='primary'
                      onClick={switchAnonymizeDataModal}
                    >
                      {t('commons:actions.anonymize-data')}
                    </Button>
                  </Col>
                )}
                {hasADMRoles && (
                  <Col
                    className='d-flex justify-content-end mx-1 px-0'
                    lg='auto'
                    md='auto'
                    sm='auto'
                  >
                    <Button
                      disabled={!customer}
                      className='primary'
                      onClick={switchDeleteCustomerModal}
                    >
                      {t('commons:actions.delete')}
                    </Button>
                  </Col>
                )}
                {numberOfNotTakenUnassignedCustomers > 0 && (
                  <>
                    <Col
                      className='d-flex justify-content-end mx-1 px-0'
                      lg='auto'
                      md='auto'
                      sm='auto'
                    >
                      <SimpleInputList2
                        name='traderInitialsToFilter'
                        additionalOnChange={handleTraderInitialsToFilterChange}
                        defaultValue={
                          traderInitialsToFilter ??
                          userInitialsListWithAllOption[0]
                        }
                        options={userInitialsListWithAllOption}
                        label={String(t('commons:labels.trader-initials'))}
                        customSelectWidth={9}
                      />
                    </Col>
                    <Col
                      className='d-flex justify-content-end mx-1 px-0'
                      lg='auto'
                      md='auto'
                      sm='auto'
                    >
                      <SimpleInputList2
                        name='customerStatusToFilter'
                        additionalOnChange={handleCustomerStatusToFilterChange}
                        defaultValue={
                          customerStatusToFilter ?? customerStatusOptions[0]
                        }
                        options={customerStatusOptions}
                        label={String(t('commons:labels.customer-status'))}
                        customSelectWidth={9}
                      />
                    </Col>
                  </>
                )}
                <Table.TableSearchWithPagination
                  isLoading={isLoadingCustomers}
                  query={interestedCustomersSearchQuery}
                  setQuery={setInterestedCustomersSearchQuery}
                  refreshPage={refetchPagination}
                />
              </Table.Header>
              <Table.TableBody
                onRowClick={handleChooseSelectedCustomer}
                timeout={5000}
                isLoading={isLoadingCustomers}
              />
              <Table.PaginationWithFetch
                storageType='localStorage'
                tableKey={StorageKeys.InterestedCustomersTable}
                genericFetch={fetchInterestedCustomersPagination}
                additionalParameters={
                  customerStatusToFilter.name === 'Wszyscy'
                    ? {
                        keyWords: interestedCustomersSearchQuery,
                        traderInitials:
                          traderInitialsToFilter === 'Wszyscy'
                            ? undefined
                            : traderInitialsToFilter,
                      }
                    : {
                        customerStatus: customerStatusToFilter.id,
                        keyWords: interestedCustomersSearchQuery,
                        traderInitials:
                          traderInitialsToFilter === 'Wszyscy'
                            ? undefined
                            : traderInitialsToFilter,
                      }
                }
              />
            </Table>
          </Col>
          {customer?.id && (
            <Toast
              onClose={toggleShowToast}
              show={showToast}
              style={{
                bottom: '1em',
                right: '1em',
                position: 'fixed',
                zIndex: toastZIndex,
              }}
            >
              <Toast.Header>
                <strong style={{ paddingTop: '5px' }}>
                  {`${t('customers:labels.customer')}: ` +
                    customer?.firstName +
                    ' ' +
                    customer?.lastName}
                </strong>
              </Toast.Header>
            </Toast>
          )}
        </Row>
        {customer?.id && (
          <Tabs
            className='d-flex justify-content-center my-2'
            defaultActiveKey='editInterested'
            id='registerSale'
          >
            <Tab eventKey='editInterested' title='Edycja Danych Klienta'>
              <EditInterested
                customer={customer}
                key={`editInterested${customer?.id}`}
              />
            </Tab>
            {customer?.marketingConsents.length ? (
              <Tab
                eventKey='marketingConsents'
                title='Zgody Marketingowe i Obowiązki Informacyjne'
              >
                <InterestedCustomerMarketingConsents
                  customer={customer}
                  key={`interestedMarketingConsents${customer?.id}`}
                />
              </Tab>
            ) : null}
            {customer?.contracts?.length ? (
              <Tab eventKey='contractsViewOnly' title='Podgląd zawartych umów'>
                <InterestedCustomerContracts
                  customer={customer}
                  key={`interestedContractsViewOnly${customer?.id}`}
                />
              </Tab>
            ) : null}
            <Tab eventKey='eventsAndNotes' title='Zdarzenia i Notatki'>
              <InterestedCustomerEventsAndNotes
                customer={customer}
                key={`interestedEventsAndNotes${customer?.id}`}
              />
            </Tab>
            <Tab
              eventKey='proposedPlaces'
              title='Propozycje inwestycji i lokali'
            >
              <InterestedCustomerProposedPlaces
                customer={customer}
                key={`interestedProposedPlaces${customer?.id}`}
              />
            </Tab>
          </Tabs>
        )}
        {isVisibleAddNewInterestedModal && (
          <NewInterested
            handleClose={handleClose}
            show={isVisibleAddNewInterestedModal}
          />
        )}
        <ReduxModal
          body={anonymizeCustomerModal}
          cancelAction={switchAnonymizeDataModal}
          onHide={switchAnonymizeDataModal}
          show={isAnonymizeDataModalVisible}
          title={t('adminIT:labels.anonymize-customer')}
        />
        <ReduxModal
          body={deleteCustomerModal}
          cancelAction={switchDeleteCustomerModal}
          onHide={switchDeleteCustomerModal}
          show={isDeleteCutomerModalVisible}
          title={t('adminIT:labels.delete-customer')}
        />
      </Container>
    </Router>
  )
}

export default InterestedCustomers
