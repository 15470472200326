import React, { FunctionComponent } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'

interface IValidationTooltipProps {
  isEmail?: boolean
  isPhoneNumber?: boolean
  number?: number
  number2?: number
  show: boolean
  target: any
}

const ValidationTooltip: FunctionComponent<IValidationTooltipProps> = (
  props: IValidationTooltipProps
) => {
  const { t } = useTranslation()
  const { isPhoneNumber, isEmail, number, number2, show, target } = props

  return (
    <>
      <Overlay target={target} show={show} placement='right'>
        {(props): JSX.Element => (
          <Tooltip id='overlay-example' {...props}>
            {isPhoneNumber
              ? t('commons:labels.phone-number-validation')
              : isEmail
              ? t('commons:labels.email-sign-is-required')
              : t('commons:labels.number-of-chars-needs-to-be-equal') +
                ' ' +
                number}
            {!isPhoneNumber &&
              number2 &&
              ` ${t('commons:labels.or')} ${number2}`}
            .
          </Tooltip>
        )}
      </Overlay>
    </>
  )
}

export default ValidationTooltip
