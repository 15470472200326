import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import './Reports.scss'

import { RootState } from '../../ducks'
import { fetchDNIShareTableReport } from '../../ducks/reports'

import { ReportWithList } from './ReportWithList'

const DNIShareTableReport: FunctionComponent = () => {
  const { isLoading } = useSelector(
    (state: RootState) => state.combinedReports.DNIShareTableReport
  )

  return (
    <ReportWithList
      reportAPIName='DNIShareTableReport'
      reportName='Raport-udziałów.xlsx'
      reduxAction={fetchDNIShareTableReport}
      isLoading={isLoading}
    />
  )
}

export default DNIShareTableReport
