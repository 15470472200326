import { gql } from 'graphql-request'
import {
  IUnassignedCustomer,
  ProposedInvestment,
} from '../ducks/unassignedCustomers'
import GraphqlService from './graphqlService'

interface GenericProps {
  sortOrder?: boolean
  sortField?: string
  perPage?: number
  page?: number
}
export interface MessagesOfUnassignedCustomerPaginationProps
  extends GenericProps {
  keywords?: string
  unassignedCustomerID: number
  showArchive?: boolean
}

export interface UnassignedCustomersPaginationProps extends GenericProps {
  active?: boolean
  keyWords?: string
  statuses?: string[]
  isTraderInitials?: boolean
}

export const getNotTakenUnassignedCustomers = (): Promise<any> => {
  const FETCH_UNASSIGNED_CUSTOMERS_WITH_STATUS = gql`
    query UnassignedCustomers($statuses: [String!]) {
      unassignedCustomers(statuses: $statuses) {
        total
      }
    }
  `

  return GraphqlService.send(FETCH_UNASSIGNED_CUSTOMERS_WITH_STATUS, {
    statuses: ['notTaken'],
  })
}

export const getMessagesByUnassignedCustomerID = (
  arg: MessagesOfUnassignedCustomerPaginationProps
): Promise<any> => {
  const FETCH_MESSAGES_BY_UNASSIGNED_CUSTOMER_ID = gql`
    query GetMessagesByUnassignedCustomerID(
      $sortOrder: Boolean
      $sortField: String
      $perPage: Float
      $page: Float
      $keywords: String
      $unassignedCustomerID: Float!
      $showArchive: Boolean
    ) {
      getMessagesByUnassignedCustomerID(
        sortOrder: $sortOrder
        sortField: $sortField
        perPage: $perPage
        page: $page
        keywords: $keywords
        unassignedCustomerID: $unassignedCustomerID
        showArchive: $showArchive
      ) {
        total
        messages {
          id
          content
          htmlContent
          subject
          date
          type
          isLinkedWithContact
          eml
          isSpam
          isArchive
          fromCustomer
          attachments {
            id
            name
          }
          unassignedCustomer {
            customerID
          }
          unassignedCustomerID
        }
      }
    }
  `
  return GraphqlService.send(FETCH_MESSAGES_BY_UNASSIGNED_CUSTOMER_ID, {
    sortOrder: arg?.sortOrder,
    sortField: arg?.sortField,
    perPage: arg.perPage,
    page: arg.page,
    keywords: arg.keywords,
    unassignedCustomerID: arg.unassignedCustomerID,
    showArchive: arg?.showArchive,
  })
}

export const getNumberOfMessagesFromUnassignedCustomers = (): Promise<any> => {
  const FETCH_NUMBER_OF_MESSAGES = gql`
    query getNumberOfMessagesFromUnassignedCustomers {
      getNumberOfMessagesFromUnassignedCustomers
    }
  `

  return GraphqlService.send(FETCH_NUMBER_OF_MESSAGES, {})
}

export const getUnassignedCustomers = (
  arg?: UnassignedCustomersPaginationProps
): Promise<any> => {
  const FETCH_UNASSIGNED_CUSTOMERS = gql`
    query UnassignedCustomers(
      $statuses: [String!]
      $active: Boolean
      $sortOrder: Boolean
      $sortField: String
      $perPage: Float
      $page: Float
      $keyWords: String
      $isTraderInitials: Boolean
    ) {
      unassignedCustomers(
        statuses: $statuses
        active: $active
        sortOrder: $sortOrder
        sortField: $sortField
        perPage: $perPage
        page: $page
        keyWords: $keyWords
        isTraderInitials: $isTraderInitials
      ) {
        total
        unassignedCustomers {
          id
          lastName
          firstName
          email
          customerID
          phone
          status
          takenDate
          traderInitials
          statusDate
          source
          countOfMessages
          firstMessageDate
          customer {
            traderInitials
            lastContactDate
          }
          marketingConsents {
            isInfoDutyFulfilled
            infoDutyForm
            infoDutyFormDate
            isAgreementPhone
            agreementPhoneDate
            isAgreementPhoneCancelled
            agreementPhoneCancellationDate
            isAgreementEmail
            agreementEmailDate
            isAgreementEmailCancelled
            agreementEmailCancellationDate
            isMSWiAAgreement
          }
        }
      }
    }
  `
  return GraphqlService.send(FETCH_UNASSIGNED_CUSTOMERS, {
    statuses: arg?.statuses,
    active: arg?.active,
    sortOrder: arg?.sortOrder,
    sortField: arg?.sortField,
    perPage: arg?.perPage,
    page: arg?.page,
    keyWords: arg?.keyWords,
    isTraderInitials: arg?.isTraderInitials,
  })
}

export const getUnassignedCustomerByID = (variables: {
  unassignedCustomerID: number
}): Promise<any> => {
  const FETCH_UNASSIGNED_CUSTOMER_BY_ID = gql`
    query getUnassignedCustomerByID($unassignedCustomerID: Float!) {
      unassignedCustomer(unassignedCustomerID: $unassignedCustomerID) {
        id
        firstName
        lastName
        email
        customerID
        status
        messages {
          id
          content
          htmlContent
          subject
          date
          type
          isLinkedWithContact
          attachments {
            id
            name
          }
          unassignedCustomer {
            customerID
          }
          unassignedCustomerID
        }
      }
    }
  `

  return GraphqlService.send(FETCH_UNASSIGNED_CUSTOMER_BY_ID, variables)
}

export const updateUnassignedCustomer = ({
  unassignedCustomer,
  unassignedCustomerID,
}: {
  unassignedCustomer: Partial<IUnassignedCustomer>
  unassignedCustomerID: number
}): Promise<any> => {
  const UPDATE_UNASSIGNED_CUSTOMER_QUERY = gql`
    mutation updateUnassignedCustomer(
      $unassignedCustomer: UnassignedCustomerInput!
      $unassignedCustomerID: Float!
    ) {
      updateUnassignedCustomer(
        unassignedCustomer: $unassignedCustomer
        unassignedCustomerID: $unassignedCustomerID
      ) {
        id
      }
    }
  `
  return GraphqlService.send(UPDATE_UNASSIGNED_CUSTOMER_QUERY, {
    unassignedCustomer,
    unassignedCustomerID,
  })
}
export type createCustomerFromUnassignedCustomerProps = {
  unassignedCustomerID: number
  marketingConsents?: any
  contacts?: any
  proposedInvestments?: any
  customer?: any
}
export const createCustomerFromUnassignedCustomer = ({
  marketingConsents,
  contacts,
  proposedInvestments,
  customer,
  unassignedCustomerID,
}: createCustomerFromUnassignedCustomerProps): Promise<any> => {
  const CREATE_CUSTOMER_FROM_UNASSIGNED_CUSTOMER_QUERY = gql`
    mutation createCustomerFromUnassignedCustomer(
      $contacts: [ContactInput2!]
      $proposedInvestments: [ProposedInvestmentInput2!]
      $marketingConsents: [MarketingConsentInput2!]
      $customer: CustomerInput
      $unassignedCustomerID: Float!
    ) {
      createCustomerFromUnassignedCustomer(
        contacts: $contacts
        proposedInvestments: $proposedInvestments
        marketingConsents: $marketingConsents
        customer: $customer
        unassignedCustomerID: $unassignedCustomerID
      ) {
        id
      }
    }
  `
  return GraphqlService.send(CREATE_CUSTOMER_FROM_UNASSIGNED_CUSTOMER_QUERY, {
    unassignedCustomerID,
    marketingConsents,
    contacts,
    proposedInvestments,
    customer,
  })
}
export type assignUnassignedCustomerToExistingCustomerProps = {
  unassignedCustomerUpdate?: Partial<IUnassignedCustomer>
  unassignedCustomerID: number
  marketingConsents?: any
  contacts?: any
  proposedInvestments?: any
  customerUpdate?: any
  customerID: number
  takingUnassigned?: boolean
}
export const assignUnassignedCustomerToExistingCustomer = ({
  unassignedCustomerUpdate,
  marketingConsents,
  contacts,
  proposedInvestments,
  customerUpdate,
  unassignedCustomerID,
  customerID,
  takingUnassigned,
}: assignUnassignedCustomerToExistingCustomerProps): Promise<any> => {
  const ASSIGN_UNASSIGNED_CUSTOMER_QUERY = gql`
    mutation assignUnassignedCustomerToExistingCustomer(
      $contacts: [ContactInput2!]
      $proposedInvestments: [ProposedInvestmentInput2!]
      $marketingConsents: [MarketingConsentInput2!]
      $customerUpdate: CustomerInput
      $customerID: Float
      $unassignedCustomerUpdate: UnassignedCustomerInput
      $unassignedCustomerID: Float!
      $takingUnassigned: Boolean
    ) {
      assignUnassignedCustomerToExistingCustomer(
        contacts: $contacts
        proposedInvestments: $proposedInvestments
        marketingConsents: $marketingConsents
        customerUpdate: $customerUpdate
        customerID: $customerID
        unassignedCustomerUpdate: $unassignedCustomerUpdate
        unassignedCustomerID: $unassignedCustomerID
        takingUnassigned: $takingUnassigned
      ) {
        id
      }
    }
  `
  return GraphqlService.send(ASSIGN_UNASSIGNED_CUSTOMER_QUERY, {
    unassignedCustomerUpdate,
    unassignedCustomerID,
    marketingConsents,
    contacts,
    proposedInvestments,
    customerUpdate,
    customerID,
    takingUnassigned,
  })
}

export const takeUnassignedCustomer = ({
  unassignedCustomerID,
}: {
  unassignedCustomerID: number
}): Promise<any> => {
  const TAKE_UNASSIGNED_CUSTOMER_QUERY = gql`
    mutation takeUnassignedCustomer($unassignedCustomerID: Float!) {
      takeUnassignedCustomer(unassignedCustomerID: $unassignedCustomerID) {
        id
      }
    }
  `
  return GraphqlService.send(TAKE_UNASSIGNED_CUSTOMER_QUERY, {
    unassignedCustomerID,
  })
}

export const forgotUnassignedCustomer = ({
  unassignedCustomerID,
}: {
  unassignedCustomerID: number
}): Promise<any> => {
  const FORGOT_UNASSIGNED_CUSTOMER_QUERY = gql`
    mutation forgoUnassignedCustomer($unassignedCustomerID: Float!) {
      forgoUnassignedCustomer(unassignedCustomerID: $unassignedCustomerID) {
        id
      }
    }
  `
  return GraphqlService.send(FORGOT_UNASSIGNED_CUSTOMER_QUERY, {
    unassignedCustomerID,
  })
}

export const forwardUnassignedCustomer = ({
  traderInitials,
  unassignedCustomerID,
}: {
  traderInitials: string
  unassignedCustomerID: number
}): Promise<any> => {
  const FORWARD_UNASSIGNED_CUSTOMER_QUERY = gql`
    mutation forwardUnassignedCustomer(
      $traderInitials: String!
      $unassignedCustomerID: Float!
    ) {
      forwardUnassignedCustomer(
        traderInitials: $traderInitials
        unassignedCustomerID: $unassignedCustomerID
      ) {
        id
      }
    }
  `
  return GraphqlService.send(FORWARD_UNASSIGNED_CUSTOMER_QUERY, {
    traderInitials,
    unassignedCustomerID,
  })
}
export const markAsSpamMessage = ({
  messageID,
}: {
  messageID: number
}): Promise<any> => {
  const MARK_AS_SPAM_MESSAGE_QUERY = gql`
    mutation markAsSpamMessage($messageID: Float!) {
      markAsSpamMessage(messageID: $messageID)
    }
  `
  return GraphqlService.send(MARK_AS_SPAM_MESSAGE_QUERY, { messageID })
}

export const getMessageByMessageID = (variables: {
  messageID: number
}): Promise<any> => {
  const FETCH_MESSAGE_BY_MESSAGE_ID = gql`
    query message($messageID: Float!) {
      message(messageID: $messageID) {
        id
        content
        attachments {
          id
          content
          name
        }
      }
    }
  `
  return GraphqlService.send(FETCH_MESSAGE_BY_MESSAGE_ID, variables)
}

export const getMessageFileByMessageFileID = (variables: {
  messageFileID: number
}): Promise<any> => {
  const FETCH_UNASSIGNED_CUSTOMER_BY_ID = gql`
    query getMessageFile($messageFileID: Float!) {
      getMessageFile(messageFileID: $messageFileID) {
        id
        content
      }
    }
  `

  return GraphqlService.send(FETCH_UNASSIGNED_CUSTOMER_BY_ID, variables)
}

export const saveContactFromMailMessage = (variables: {
  messageID: number
  unassignedCustomerEmail: string
  contact: any
  proposedInvestments?: ProposedInvestment[]
}): Promise<any> => {
  const SAVE_CONTACT_FROM_MAIL_MESSAGE = gql`
    mutation saveContactFromMailMessage(
      $messageID: Float!
      $unassignedCustomerEmail: String!
      $contact: ContactInput
      $proposedInvestments: [ProposedInvestmentInput2!]
    ) {
      saveContactFromMailMessage(
        messageID: $messageID
        unassignedCustomerEmail: $unassignedCustomerEmail
        contact: $contact
        proposedInvestments: $proposedInvestments
      )
    }
  `
  return GraphqlService.send(SAVE_CONTACT_FROM_MAIL_MESSAGE, variables)
}
export const markUnassignedCustomerFromMessage = (variables: {
  messageID: number
}): Promise<any> => {
  const MUTATION = gql`
    mutation markAsClient($messageID: Float!) {
      markAsFromCustomerMessage(messageID: $messageID)
    }
  `
  return GraphqlService.send(MUTATION, variables)
}

const queries = {
  getNotTakenUnassignedCustomers,
  getMessageByMessageID,
  getMessageFileByMessageFileID,
  getMessagesByUnassignedCustomerID,
  getNumberOfMessagesFromUnassignedCustomers,
  getUnassignedCustomerByID,
  getUnassignedCustomers,
  markAsSpamMessage,
  saveContactFromMailMessage,
  updateUnassignedCustomer,
  takeUnassignedCustomer,
  forgotUnassignedCustomer,
  forwardUnassignedCustomer,
  createCustomerFromUnassignedCustomer,
  assignUnassignedCustomerToExistingCustomer,
  markUnassignedCustomerFromMessage,
}

export default queries
