import { faSearch } from '@fortawesome/free-solid-svg-icons'
import * as React from 'react'
import { Button, Col, Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

import { useTableContext } from './TableContext'

import './Table.scss'

const TableSearch: React.FC = () => {
  const { t } = useTranslation()
  const { globalFilter, setGlobalFilter, data } = useTableContext()

  const [phrase, setPhrase] = React.useState(globalFilter)

  const clearQuery = React.useCallback(() => {
    setGlobalFilter('')
    setPhrase('')
  }, [setGlobalFilter])

  React.useEffect(() => {
    setGlobalFilter(phrase)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setPhrase(e.target.value)
    },
    []
  )

  const onButtonClick = React.useCallback(() => {
    setPhrase(phrase)
    setGlobalFilter(phrase)
  }, [phrase, setGlobalFilter])

  const onEnter = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setPhrase(phrase)
        setGlobalFilter(phrase)
      }
    },
    [phrase, setGlobalFilter]
  )

  return (
    <Col className='d-flex justify-content-end' lg='auto' md='auto' sm='auto'>
      <InputGroup>
        <Form.Control
          className='searchPadding'
          onKeyDown={onEnter}
          placeholder={`${t('commons:labels.type-searched-keyword')}...`}
          value={phrase}
          onChange={handleChange}
        />
        <div>
          <Button
            className='searchButton'
            type='submit'
            onClick={onButtonClick}
          >
            <FontAwesomeIcon icon={faSearch} className='searchIcon' />
          </Button>
          <Button
            className='searchButton'
            type='button'
            style={{ marginLeft: '5px' }}
            onClick={clearQuery}
          >
            <span className='searchIcon'>X</span>
          </Button>
        </div>
      </InputGroup>
    </Col>
  )
}

export default TableSearch
