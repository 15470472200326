import React, { ReactNode } from 'react'

import { IContract } from '../../graphql/contracts/contracts'
import { IContractCustomerCompany } from '../../graphql/contractCustomerCompany'
import { IContractCustomer } from '../../graphql/contractCustomers'
import { IPlace } from '../../graphql/places'
import { IParkingPlace } from '../../graphql/parkingPlaces'

import { formatAreas } from '../../utils/formatAreas'
import { getProperDateForTable } from '../../utils/getProperDate'
import { sortByID } from '../../utils/sortData'

import YslabCheckbox from '../commons/Inputs/YslabCheckbox'

interface MainTableColumnProps {
  getActiveContract: (activeContracts: IContract[]) => IContract | undefined
}

export const getMainTableColumns = ({
  getActiveContract,
}: MainTableColumnProps): any[] => {
  return [
    {
      Header: 'L.p.',
      accessor: 'orderNo',
      width: '50',
      disableSortBy: true,
      sticky: 'left',
      Cell: (row: any): any => {
        return <div>{row.data.length - row.row.index}</div>
      },
    },
    {
      Header: 'Kod inwestycji',
      accessor: 'investment.name',
      disableSortBy: true,
      width: '100',
      sticky: 'left',
    },
    {
      Header: 'Nr bud. lokalu',
      accessor: 'placeCode',
      width: '150',
      sticky: 'left',
    },
    {
      Header: 'Liczba pokoi',
      accessor: 'roomCount',
      width: '75',
    },
    {
      Header: 'Ulica',
      accessor: 'stage.street',
      disableSortBy: true,
      width: '150',
    },
    {
      Header: 'Nr admin.',
      disableSortBy: true,
      accessor: (a: {
        administrativeCode1: string
        administrativeCode2: string
      }): string =>
        `${a.administrativeCode1 ?? ''} / ${a.administrativeCode2 ?? ''}`,
      width: '150',
    },
    {
      id: 'designedUsablePropertyArea',
      Header: 'PUL proj.',
      accessor: (a: { designedUsablePropertyArea: string }): string => {
        const valueWithComma = String(
          a.designedUsablePropertyArea ?? '0'
        ).replace('.', ',')
        return formatAreas(valueWithComma, true)
      },
      fullName: 'Pow. użyt. projekt.',
      width: '75',
    },
    {
      id: 'designedInnerPropertyArea',
      Header: 'PWL proj.',
      accessor: (a: { designedInnerPropertyArea: string }): string => {
        const valueWithComma = String(
          a.designedInnerPropertyArea ?? '0'
        ).replace('.', ',')
        return formatAreas(valueWithComma, true)
      },
      fullName: 'Pow. wew. projekt.',
      width: '75',
    },
    {
      id: 'asBuildUsablePropertyArea',
      Header: 'PUL powyk.',
      accessor: (a: { asBuildUsablePropertyArea: string }): string => {
        const valueWithComma = String(
          a.asBuildUsablePropertyArea ?? '0'
        ).replace('.', ',')
        return formatAreas(valueWithComma)
      },
      fullName: 'Pow. użyt. powyk.',
      width: '75',
    },
    {
      id: 'asBuildInnerPropertyArea',
      Header: 'PWL powyk.',
      accessor: (a: { asBuildInnerPropertyArea: string }): string => {
        const valueWithComma = String(
          a.asBuildInnerPropertyArea ?? '0'
        ).replace('.', ',')
        return formatAreas(valueWithComma, true)
      },
      fullName: 'Pow. wew. powyk.',
      width: '75',
    },
    {
      Header: 'Liczba nabywców',
      disableSortBy: true,
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        const contractCustomerCompanies = contract?.contractCustomerCompanies
        const contractCustomers = contract?.contractCustomers

        const companyOwners =
          contractCustomerCompanies?.filter(
            (customer: IContractCustomerCompany) => {
              return (
                !customer.isAgentOfMainCustomer &&
                !customer.isRepresentativePerson
              )
            }
          ) || []
        const owners =
          contractCustomers?.filter((customer: IContractCustomer) => {
            return (
              !customer.isAgentOfMainCustomer &&
              !customer.isAgentOfCustomerSpouse &&
              !customer.isSpouseOfMainCustomer
            )
          }) || []

        if (owners.length) {
          return String(owners?.length)
        } else if (companyOwners.length) {
          return String(companyOwners?.length)
        } else {
          return ''
        }
      },
      width: '100',
    },
    {
      id: 'firstName1',
      disableSortBy: true,
      Header: 'Imię / nazwa spółki (Klient 1.)',
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        const sortedContractCustomers =
          contract?.contractCustomers?.slice().sort(sortByID) ?? []
        const sortedContractCustomerCompanies =
          contract?.contractCustomerCompanies?.slice().sort(sortByID) ?? []
        const owner = sortedContractCustomers.filter(
          (c) =>
            !c.isSpouseOfMainCustomer &&
            !c.isAgentOfCustomerSpouse &&
            !c.isAgentOfMainCustomer
        )
        const companyOwner = sortedContractCustomerCompanies?.filter(
          (customer) =>
            !customer.isAgentOfMainCustomer && !customer.isRepresentativePerson
        )
        const spouse = sortedContractCustomers.filter(
          (c) => c.isSpouseOfMainCustomer
        )
        const ownerSpouse = spouse?.find(
          (s) =>
            s.customerParentID === owner?.[0]?.id || s.uuid === owner?.[0]?.uuid
        )
        const ownerFirstName =
          companyOwner?.[0]?.businessName || owner?.[0]?.firstName || ''
        const spouseFirstNameWithSeparator = owner?.[0]?.maritalStatus?.startsWith(
          '2 wspólnota majątkowa'
        )
          ? `, ${ownerSpouse?.firstName ?? ''}`
          : ''
        return `${ownerFirstName}${spouseFirstNameWithSeparator}`
      },
      width: '150',
    },
    {
      id: 'lastName1',
      disableSortBy: true,
      Header: 'Nazwisko (Klient 1.)',
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        const areContractCustomerCompaniesOnContract =
          contract?.contractCustomerCompanies?.length
        const sortedContractCustomers =
          contract?.contractCustomers.slice().sort(sortByID) ?? []
        const owner = sortedContractCustomers.filter(
          (c) =>
            !c.isSpouseOfMainCustomer &&
            !c.isAgentOfCustomerSpouse &&
            !c.isAgentOfMainCustomer
        )
        const spouse = sortedContractCustomers.filter(
          (c) => c.isSpouseOfMainCustomer
        )
        const ownerSpouse = spouse?.find(
          (s) =>
            s.customerParentID === owner?.[0]?.id || s.uuid === owner?.[0]?.uuid
        )
        const ownerLastName = owner?.[0]?.lastName ?? ''
        const spouseLastNameWithSeparator = owner?.[0]?.maritalStatus?.startsWith(
          '2 wspólnota majątkowa'
        )
          ? `, ${ownerSpouse?.lastName ?? ''}`
          : ''

        return areContractCustomerCompaniesOnContract
          ? ''
          : `${ownerLastName}${spouseLastNameWithSeparator}`
      },
      width: '180',
    },
    {
      id: 'firstName2',
      disableSortBy: true,
      Header: 'Imię / nazwa spółki (Klient 2.)',
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        const sortedContractCustomers =
          contract?.contractCustomers.slice().sort(sortByID) ?? []
        const sortedContractCustomerCompanies =
          contract?.contractCustomerCompanies.slice().sort(sortByID) ?? []
        const owner = sortedContractCustomers.filter(
          (c) =>
            !c.isSpouseOfMainCustomer &&
            !c.isAgentOfCustomerSpouse &&
            !c.isAgentOfMainCustomer
        )
        const companyOwner = sortedContractCustomerCompanies.filter(
          (customer) =>
            !customer.isAgentOfMainCustomer && !customer.isRepresentativePerson
        )
        const spouse =
          sortedContractCustomers.filter((c) => c.isSpouseOfMainCustomer) ?? []
        const ownerSpouse = spouse?.find(
          (s) =>
            s.customerParentID === owner?.[1]?.id || s.uuid === owner?.[1]?.uuid
        )
        const ownerFirstName =
          companyOwner?.[1]?.businessName || owner?.[1]?.firstName || ''
        const spouseFirstNameWithSeparator = owner?.[1]?.maritalStatus?.startsWith(
          '2 wspólnota majątkowa'
        )
          ? `, ${ownerSpouse?.firstName ?? ''}`
          : ''
        return `${ownerFirstName}${spouseFirstNameWithSeparator}`
      },
      width: '150',
    },
    {
      id: 'lastName2',
      disableSortBy: true,
      Header: 'Nazwisko (Klient 2.)',
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        const areContractCustomerCompaniesOnContract =
          contract?.contractCustomerCompanies?.length
        const sortedContractCustomers =
          contract?.contractCustomers.slice().sort(sortByID) ?? []
        const owner = sortedContractCustomers.filter(
          (c) =>
            !c.isSpouseOfMainCustomer &&
            !c.isAgentOfCustomerSpouse &&
            !c.isAgentOfMainCustomer
        )
        const spouse =
          sortedContractCustomers.filter((c) => c.isSpouseOfMainCustomer) ?? []
        const ownerSpouse = spouse?.find(
          (s) =>
            s.customerParentID === owner?.[1]?.id || s.uuid === owner?.[1]?.uuid
        )
        const ownerLastName = owner?.[1]?.lastName ?? ''
        const spouseLastNameWithSeparator = owner?.[1]?.maritalStatus?.startsWith(
          '2 wspólnota majątkowa'
        )
          ? `, ${ownerSpouse?.lastName ?? ''}`
          : ''
        return areContractCustomerCompaniesOnContract
          ? ''
          : `${ownerLastName}${spouseLastNameWithSeparator}`
      },
      width: '180',
    },
    {
      Header: 'Nr miejsca postoj.',
      disableSortBy: true,
      accessor: (a: IPlace): string => {
        let parkingPlacesFromContract = [] as string[]
        let parkingPlacesFromPlace = [] as string[]
        const parkingPlacesLinkedToUtilityRoom = [] as string[]
        if (
          a.activeContracts.length > 0 &&
          a.activeContracts[0].parkingPlaces
        ) {
          parkingPlacesFromContract = a.activeContracts[0].parkingPlaces.map(
            (parkingPlace: IParkingPlace) => parkingPlace.name
          )
        }
        if (a.parkingPlaces.length > 0) {
          parkingPlacesFromPlace = a.parkingPlaces.map(
            (parkingPlace: IParkingPlace) => parkingPlace.name
          )
        }
        if (a.activeContracts.length > 0 && a.activeContracts[0].utilityRooms) {
          parkingPlacesLinkedToUtilityRoom.push(
            ...a.activeContracts[0].utilityRooms.map(
              (ur: any) => ur?.parkingPlace?.name ?? ''
            )
          )
        }
        if (a.utilityRooms?.length) {
          parkingPlacesLinkedToUtilityRoom.push(
            ...a.utilityRooms.map((ur: any) => ur?.parkingPlace?.name ?? '')
          )
        }
        const fromPlace = parkingPlacesFromPlace
          .filter((p) => p != null)
          .reduce((prev, next) => {
            if (prev) {
              return prev + ',' + next
            } else {
              return next
            }
          }, '')
        const fromContract = parkingPlacesFromContract
          .filter((p) => p != null)
          .reduce((prev, next) => {
            if (prev) {
              return prev + ',' + next
            } else {
              return next
            }
          }, '')
        const fromUtilityRoom = parkingPlacesLinkedToUtilityRoom
          .filter((p) => p != null)
          .reduce((prev, next) => {
            if (prev) {
              return prev + ',' + next
            } else {
              return next
            }
          }, '')
        return fromPlace + fromContract + fromUtilityRoom
      },
      width: '100',
    },
    {
      Header: 'Nr pom. gosp.',
      disableSortBy: true,
      accessor: (a: IPlace): string => {
        const utilityRoomsByPlace = a?.utilityRooms
        const utilityRoomsByContract =
          a.activeContracts && a?.activeContracts[0]
            ? a.activeContracts[0]?.utilityRooms ?? []
            : []

        const fromPlace =
          utilityRoomsByPlace?.length &&
          utilityRoomsByPlace?.map((ur, i) => {
            if (i > 0) {
              return String(' ' + ur?.number)
            } else {
              return String(ur?.number ?? '')
            }
          }, '')

        const fromContract =
          utilityRoomsByContract?.length &&
          utilityRoomsByContract?.map((ur, i) => {
            if (i > 0) {
              return String(' ' + ur?.number)
            } else {
              return String(ur?.number ?? '')
            }
          }, '')

        return `${fromPlace !== 0 ? fromPlace : ''}${
          fromContract !== 0 ? fromContract : ''
        }`
      },
      width: '100',
    },
    {
      Header: 'PWPG proj.',
      disableSortBy: true,
      accessor: (a: IPlace): string => {
        const utilityRoomsByPlace = a?.utilityRooms
        const utilityRoomsByContract =
          a.activeContracts && a?.activeContracts[0]
            ? a.activeContracts[0]?.utilityRooms ?? []
            : []

        if (utilityRoomsByPlace?.length > 0) {
          const valueWithComma = String(
            utilityRoomsByPlace[0]?.designedInnerUtilRoomArea
          ).replace('.', ',')
          return formatAreas(valueWithComma, true)
        } else if (utilityRoomsByContract.length > 0) {
          const valueWithComma = String(
            utilityRoomsByContract[0]?.designedInnerUtilRoomArea ?? ''
          ).replace('.', ',')
          return formatAreas(valueWithComma, true)
        } else {
          return '0,0'
        }
      },
      fullName: 'Pow. wew. projekt. pom. gosp.',
      width: '75',
    },
    {
      id: 'designedUsableUtilRoomArea',
      Header: 'PUPG proj.',
      disableSortBy: true,
      accessor: (a: IPlace): string => {
        const utilityRoomsByPlace = a?.utilityRooms
        const utilityRoomsByContract =
          a.activeContracts && a?.activeContracts[0]
            ? a.activeContracts[0]?.utilityRooms ?? []
            : []

        if (utilityRoomsByPlace?.length > 0) {
          const valueWithComma = String(
            utilityRoomsByPlace[0]?.designedUsableUtilRoomArea
          ).replace('.', ',')
          return utilityRoomsByPlace[0]?.designedUsableUtilRoomArea
            ? formatAreas(valueWithComma, true)
            : '0,0'
        } else if (utilityRoomsByContract.length > 0) {
          const valueWithComma = String(
            utilityRoomsByContract[0]?.designedUsableUtilRoomArea ?? ''
          ).replace('.', ',')
          return utilityRoomsByContract[0]?.designedUsableUtilRoomArea
            ? formatAreas(valueWithComma, true)
            : '0,0'
        } else {
          return '0,0'
        }
      },
      fullName: 'Pow. użyt. projekt. pom. gosp.',
      width: '75',
    },
    {
      Header: 'PWPG powyk.',
      disableSortBy: true,
      accessor: (a: IPlace): string => {
        const utilityRoomsByPlace = a?.utilityRooms
        const utilityRoomsByContract =
          a.activeContracts && a?.activeContracts[0]
            ? a.activeContracts[0]?.utilityRooms ?? []
            : []

        if (utilityRoomsByPlace?.length > 0) {
          const valueWithComma = String(
            utilityRoomsByPlace[0]?.asBuildInnerUtilRoomArea
          ).replace('.', ',')
          return formatAreas(valueWithComma, true)
        } else if (utilityRoomsByContract.length > 0) {
          const valueWithComma = String(
            utilityRoomsByContract[0]?.asBuildInnerUtilRoomArea ?? ''
          ).replace('.', ',')
          return formatAreas(valueWithComma, true)
        } else {
          return '0,0'
        }
      },
      fullName: 'Pow. wew. powyk. pom. gosp.',
      width: '75',
    },
    {
      Header: 'PUPG powyk.',
      disableSortBy: true,
      accessor: (a: IPlace): string => {
        const utilityRoomsByPlace = a?.utilityRooms
        const utilityRoomsByContract =
          a.activeContracts && a.activeContracts[0]
            ? a.activeContracts[0].utilityRooms ?? []
            : []

        if (utilityRoomsByPlace?.length > 0) {
          const valueWithComma = String(
            utilityRoomsByPlace[0]?.asBuildUsableUtilRoomArea
          ).replace('.', ',')
          return formatAreas(valueWithComma)
        } else if (utilityRoomsByContract.length > 0) {
          const valueWithComma = String(
            utilityRoomsByContract[0].asBuildUsableUtilRoomArea ?? ''
          ).replace('.', ',')
          return formatAreas(valueWithComma)
        } else {
          return '0,00'
        }
      },
      fullName: 'Pow. użyt. powyk. pom. gosp.',
      width: '75',
    },
    {
      Header: 'Data zaw. umowy',
      disableSortBy: true,
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        const contractDealDate = contract?.contractDealDate ?? ''
        return getProperDateForTable(contractDealDate)
      },
      width: '120',
    },
    {
      Header: 'Um. termin odb.',
      disableSortBy: true,
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        const contractualAcceptanceDate =
          contract?.contractualAcceptanceDate ?? ''
        return getProperDateForTable(contractualAcceptanceDate)
      },
      width: '120',
    },
    {
      Header: 'Um. termin aktu',
      disableSortBy: true,
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        const contractualActDate = contract?.contractualActDate ?? ''
        return getProperDateForTable(contractualActDate)
      },
      width: '120',
    },
    {
      Header: 'Data odbioru',
      disableSortBy: true,
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        const acceptanceDate = contract?.acceptanceDate ?? ''
        return getProperDateForTable(acceptanceDate)
      },
      width: '120',
    },
    {
      Header: 'Data aktu not.',
      disableSortBy: true,
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        const actDate = contract?.actDate ?? ''
        return getProperDateForTable(actDate)
      },
      width: '120',
    },
    {
      id: 'traderInitials',
      Header: 'Handl.',
      disableSortBy: true,
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        const areContractCustomerCompaniesOnContract =
          contract?.contractCustomerCompanies?.length
        const sortedContractCustomers =
          contract?.contractCustomers.slice().sort(sortByID) ?? []
        const sortedContractCustomerCompanies =
          contract?.contractCustomerCompanies.slice().sort(sortByID) ?? []
        const owners = areContractCustomerCompaniesOnContract
          ? sortedContractCustomerCompanies.filter(
              (c) => !c.isAgentOfMainCustomer && !c.isRepresentativePerson
            )
          : sortedContractCustomers.filter(
              (c) =>
                !c.isSpouseOfMainCustomer &&
                !c.isAgentOfCustomerSpouse &&
                !c.isAgentOfMainCustomer
            )

        return owners?.[0]?.traderInitials ?? ''
      },
      width: '80',
    },
    {
      Header: 'Zakup na dział gosp.',
      disableSortBy: true,
      width: '100',
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contractCustomers =
          getActiveContract(a.activeContracts)?.contractCustomers ?? []
        let isBusinesss = false
        for (let i = 0; i < contractCustomers?.length; i++) {
          if (contractCustomers[i]?.isBusiness) {
            isBusinesss = true
            return '☑'
          }
        }
        if (!isBusinesss) {
          return '☐'
        } else {
          return '☑'
        }
      },
    },
    {
      Header: 'Spółka',
      disableSortBy: true,
      width: '100',
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contractCustomersCompanies =
          getActiveContract(a.activeContracts)?.contractCustomerCompanies ?? []
        return contractCustomersCompanies.length ? '☑' : '☐'
      },
    },
    {
      Header: 'Walor.',
      disableSortBy: true,
      width: '50',
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        return contract && contract.valorization ? '☑' : '☐'
      },
    },
    {
      Header: 'Kredyt',
      disableSortBy: true,
      width: '50',
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        return contract && contract.isCredit ? '☑' : '☐'
      },
    },
    {
      Header: 'Wysyłka FV na @',
      disableSortBy: true,
      width: '80',
      accessor: (a: { activeContracts: IContract[] }): string => {
        const contract = getActiveContract(a.activeContracts)
        return contract && contract.isEmailInvoice ? '☑' : '☐'
      },
    },
    {
      Header: 'F',
      disableSortBy: true,
      accessor: (a: IContract): ReactNode => {
        return <YslabCheckbox name={a.id} value={a.isYslabAvailable} />
      },
      width: '50',
    },
    {
      Header: 'ID',
      accessor: 'id',
      width: '10',
    },
  ]
}

export const mainTableColumnsFilterTitles = [
  'investment.name',
  'placeCode',
  'roomCount',
  'stage.street',
  'Nr admin.',
  'designedUsablePropertyArea',
  'designedInnerPropertyArea',
  'asBuildUsablePropertyArea',
  'asBuildInnerPropertyArea',
  'Liczba nabywców',
  'firstName1',
  'lastName1',
  'firstName2',
  'lastName2',
  'Nr miejsca postoj.',
  'Nr pom. gosp.',
  'PWPG proj.',
  'designedUsableUtilRoomArea',
  'PWPG powyk.',
  'PUPG powyk.',
  'Data zaw. umowy',
  'Um. termin odb.',
  'Um. termin aktu',
  'Data odbioru',
  'Data aktu not.',
  'traderInitials',
  'Zakup na dział gosp.',
  'Spółka',
  'Walor.',
  'Kredyt',
  'Wysyłka FV na @',
  'id',
]
