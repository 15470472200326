import * as React from 'react'
import 'moment/locale/pl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppDispatch, RootState } from '../../ducks'
import {
  downloadXlsxReport,
  fetchDSPCustomerByTradersReport,
} from '../../ducks/reports'
import { RaportWithDatesState } from './RaportWithDates/actions'
import { Dates } from './RaportWithDates/Dates'
import { Investments } from './RaportWithDates/Investments'
import { RaportWithDates } from './RaportWithDates/RaportWithDates'
import { Traders } from './RaportWithDates/Traders'
import { Stages } from './RaportWithDates/Stages'

const CustomerByTradersReport: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const history = useHistory()
  const isLoading = useSelector(
    (state: RootState) =>
      state.combinedReports.DSPCustomerByTradersReport.isLoading
  )

  const generateReport = React.useCallback(
    async (state: RaportWithDatesState) => {
      const stagesIDs = state.chosenStages.map((stage) => stage.id)
      const investmentIDs = state.chosenInvestments.map(
        (investment) => investment?.id
      )

      const response = await dispatch(
        fetchDSPCustomerByTradersReport({
          fromDate: state.fromDate,
          toDate: state.toDate,
          traderInitials: state.chosenTraderInitials,
          investmentIDs,
          stagesIDs,
        })
      )
      const content = response.payload.DSPCustomerByTradersReport
      dispatch(
        downloadXlsxReport({
          content,
          filename: 'Zainteresowani - wg handlowcow.xlsx',
        })
      ).then(() => {
        history.push('/')
      })
    },
    [dispatch, history]
  )

  return (
    <RaportWithDates isLoading={isLoading} generateReport={generateReport}>
      <Dates />
      <Investments />
      <Stages />
      <Traders />
    </RaportWithDates>
  )
}

export default CustomerByTradersReport
