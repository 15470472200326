import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { hasRoles } from '../../services/idp'
import { DNKRoles } from '../../ducks/globalUser'

import { RootState } from '../../ducks'
import { fetchDSPDailyInterestedPartiesReport } from '../../ducks/reports'
import Report from './Report'

const DSPDailyInterestedPartiesReport: FC = () => {
  const { roles } = useSelector((state: RootState) => state.globalUser)
  const { DSPDailyInterestedPartiesReport } = useSelector(
    (state: RootState) => state.reports
  )

  return (
    <Report
      reportData={DSPDailyInterestedPartiesReport}
      reportName={
        hasRoles(DNKRoles, roles)
          ? 'Dzienny Zainteresowani DNK.xlsx'
          : 'Dzienny Zainteresowani DSP.xlsx'
      }
      reduxAction={fetchDSPDailyInterestedPartiesReport}
    />
  )
}

export default DSPDailyInterestedPartiesReport
