import React, { FunctionComponent } from 'react'
import { AppDispatch, RootState } from 'src/ducks'
import { useDispatch, useSelector } from 'react-redux'
import {
  forgotUnassignedCustomer,
  takeUnassignedCustomer,
} from '../../ducks/unassignedCustomers'
import { refetchPagination } from '../commons/Table2/paginationSlice'
import InterestedCustomerService from '../../services/interestedCustomerService'

const ActionCell: FunctionComponent = (props: any) => {
  const dispatch: AppDispatch = useDispatch()
  const { profile } = useSelector((state: RootState) => state.globalUser)
  const userInitials = profile?.attributes.initials
  const canTake = props?.original?.status === 'notTaken'
  const canAssign =
    props?.original?.status === 'notTaken' ||
    props?.original?.status === 'taken'
  const canResign =
    props?.original?.status === 'taken' &&
    userInitials &&
    String(userInitials) === String(props?.original?.traderInitials)
  const disabledStyle = { color: 'grey' }
  const availableStyle = { cursor: 'pointer' }

  const takeCustomer = () =>
    dispatch(
      takeUnassignedCustomer({ unassignedCustomerID: props?.original?.id })
    )
      .then(() => dispatch(refetchPagination(true)))
      .then(
        () =>
          !props?.original.customerID &&
          InterestedCustomerService.fetchTotalCustomerForUnassignedCustomer({
            filterForUnassignedCustomerID: props?.original?.id,
          })
            .then(
              (res: any) =>
                res?.paginateCustomersByUnassignedCustomerEmail?.total
            )
            .then(
              (total: number) => total > 1 && props.setIsTakingUnassigned(true)
            )
      )
  const forgotCustomer = () =>
    dispatch(
      forgotUnassignedCustomer({ unassignedCustomerID: props?.original?.id })
    ).then(() => dispatch(refetchPagination(true)))

  return (
    <div>
      <span
        style={canTake ? availableStyle : disabledStyle}
        onClick={canTake ? takeCustomer : undefined}
      >
        Podejmij
      </span>
      {' | '}
      <span
        style={canResign ? availableStyle : disabledStyle}
        onClick={canResign ? forgotCustomer : undefined}
      >
        Rezygnuj
      </span>
      {' | '}
      <span
        style={canAssign ? availableStyle : disabledStyle}
        onClick={() => {
          canAssign && props.setShowModal(true)
        }}
      >
        Przypisz do / Przekaż
      </span>
    </div>
  )
}

export default ActionCell
