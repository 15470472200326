import React, { FunctionComponent, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../ducks'
import {
  fetchInterestedCustomersByUnassignedEmail,
  fetchInterestedCustomersPagination,
} from '../../ducks/interestedCustomers'
import { customerStatusOptions } from '../InterestedCustomers/InterestedCustomers'
import { InterestedCustomersTableWrapper } from '../InterestedCustomers/InterestedCustomersTableWrapper'
import NewInterested from '../InterestedCustomers/NewInterested'
import { IOptionElement } from '../commons/Inputs/SimpleInputs/SimpleInputList'
import {
  refetchPagination,
  setInterestedCustomersSearchQuery,
} from '../commons/Table2/paginationSlice'
import SimpleInputList2 from '../commons/Inputs/SimpleInputs/SimpleInputList2'
import Table from '../commons/Table2/Table'
import { columnsData } from '../../mockups/InterestedCustomers_Columns'
import { StorageKeys } from '../../hooks/useBrowserStorage'
import { assignUnassignedCustomerToExistingCustomer } from '../../ducks/unassignedCustomers'

const TableInterestedCustomer: FunctionComponent<{
  selectedUnassigned: any
  isTakingUnassigned?: boolean
  handleClose?: () => void
}> = ({ selectedUnassigned, isTakingUnassigned, handleClose }) => {
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()

  const {
    interestedCustomers,
    isLoadingCustomers,
    totalInterestedCustomers,
    traderInitialsOfCustomer,
  } = useSelector((state: RootState) => state.interestedCustomers)

  const { interestedCustomersSearchQuery } = useSelector(
    (state: RootState) => state.pagination
  )
  const interestedCustomersColumns = React.useMemo(() => columnsData, [])
  const [editNewInterestedModal, setEditNewInterestedModal] = useState(false)
  const [showAllCustomer, setShowAllCustomer] = useState(false)
  const [selectedInterestedCustomer, setSelectedInterestedCustomer] = useState<
    any | undefined
  >(undefined)

  const fetchFunction =
    isTakingUnassigned && !showAllCustomer
      ? fetchInterestedCustomersByUnassignedEmail
      : fetchInterestedCustomersPagination

  const handleSelectInterestedCustomerRowcallback = ({
    id: selectedCustomerID,
  }: {
    id: number
  }): void => {
    const selectedCustomer = interestedCustomers?.find(
      (uc: { email?: string; id: number }) => uc.id === selectedCustomerID
    )
    selectedInterestedCustomer !== selectedCustomer
      ? setSelectedInterestedCustomer(selectedCustomer)
      : setSelectedInterestedCustomer(undefined)
  }

  const assignToInterested = () => {
    isTakingUnassigned
      ? dispatch(
          assignUnassignedCustomerToExistingCustomer({
            customerID: selectedInterestedCustomer.id,
            takingUnassigned: isTakingUnassigned,
            unassignedCustomerID: selectedUnassigned?.id,
          })
        ).then(() => dispatch(refetchPagination(true)))
      : setEditNewInterestedModal(true)
  }
  const [customerStatusToFilter, setCustomerStatusToFilter] = useState<
    IOptionElement
  >(customerStatusOptions[0])
  const [traderInitialsToFilter, setTraderInitialsToFilter] = useState(
    'Wszyscy' ?? undefined
  )
  const userInitialsListWithAllOption = [
    'Wszyscy',
    ...traderInitialsOfCustomer.slice(),
  ]

  const handleCustomerStatusToFilterChange = (e: any): void => {
    const option =
      customerStatusOptions.find(
        (option: IOptionElement) => option.id === e.target.value
      ) ?? customerStatusOptions[0]
    setCustomerStatusToFilter(option)
    dispatch(refetchPagination(true))
  }
  const handleTraderInitialsToFilterChange = (e: any): void => {
    setTraderInitialsToFilter(e.target.value)
    dispatch(refetchPagination(true))
  }

  const unassignedCustomerEmail =
    Boolean(selectedUnassigned?.email) &&
    selectedUnassigned.email !== selectedInterestedCustomer?.email &&
    selectedUnassigned.email !== selectedInterestedCustomer?.email2
      ? selectedUnassigned.email
      : undefined
  const onClickShowAllCustomer = () => {
    const newShowAllCustomer = !showAllCustomer
    dispatch(
      fetchFunction({
        filterForUnassignedCustomerID: newShowAllCustomer
          ? undefined
          : selectedUnassigned.id,
        customerStatus:
          customerStatusToFilter.name !== 'Wszyscy'
            ? String(customerStatusToFilter.id)
            : undefined,
        keyWords: interestedCustomersSearchQuery,
        traderInitials:
          traderInitialsToFilter === 'Wszyscy'
            ? undefined
            : traderInitialsToFilter,
      })
    ).then(() => setShowAllCustomer(newShowAllCustomer))
  }

  return (
    <>
      <Table
        columns={interestedCustomersColumns}
        data={interestedCustomers ?? []}
        defaultNumberOfRows={5}
        totalRows={totalInterestedCustomers}
        manualSortBy
        customLoading
      >
        <InterestedCustomersTableWrapper />
        <Table.Header align='end'>
          <Col
            className='d-flex justify-content-end mx-1 px-0'
            lg='auto'
            md='auto'
            sm='auto'
          >
            {selectedInterestedCustomer?.id && (
              <Button className='primary' onClick={assignToInterested}>
                {t('commons:actions.assign')}
              </Button>
            )}
            <Button className='primary' onClick={onClickShowAllCustomer}>
              {!showAllCustomer
                ? t('table:actions.show-all')
                : t('table:actions.show-only-for-lead')}
            </Button>
          </Col>
          <SimpleInputList2
            name='traderInitialsToFilter'
            additionalOnChange={handleTraderInitialsToFilterChange}
            defaultValue={
              traderInitialsToFilter ?? userInitialsListWithAllOption[0]
            }
            options={userInitialsListWithAllOption}
            label={String(t('commons:labels.trader-initials'))}
            customSelectWidth={7.5}
          />
          <SimpleInputList2
            name='customerStatusToFilter'
            additionalOnChange={handleCustomerStatusToFilterChange}
            defaultValue={customerStatusToFilter ?? customerStatusOptions[0]}
            options={customerStatusOptions}
            label={String(t('commons:labels.customer-status'))}
            customSelectWidth={7.5}
          />
          <Table.TableSearchWithPagination
            isLoading={isLoadingCustomers}
            query={interestedCustomersSearchQuery}
            setQuery={setInterestedCustomersSearchQuery}
            refreshPage={refetchPagination}
          />
        </Table.Header>
        <Table.TableBody
          isLoading={isLoadingCustomers}
          onRowClick={handleSelectInterestedCustomerRowcallback}
          timeout={5000}
        />
        <Table.PaginationWithFetch
          storageType='localStorage'
          tableKey={StorageKeys.InterestedCustomersTable}
          genericFetch={fetchFunction}
          additionalParameters={{
            filterForUnassignedCustomerID: showAllCustomer
              ? undefined
              : selectedUnassigned.id,
            customerStatus:
              customerStatusToFilter.name !== 'Wszyscy'
                ? customerStatusToFilter.id
                : undefined,
            keyWords: interestedCustomersSearchQuery,
            traderInitials:
              traderInitialsToFilter === 'Wszyscy'
                ? undefined
                : traderInitialsToFilter,
          }}
        />
      </Table>
      {selectedUnassigned?.id && selectedInterestedCustomer?.id ? (
        <NewInterested
          externalDefaultData={{
            id: selectedInterestedCustomer.id,
            firstName: selectedInterestedCustomer?.firstName,
            lastName: selectedInterestedCustomer?.lastName,
            email:
              selectedInterestedCustomer?.email ?? selectedUnassigned.email,
            email2: selectedInterestedCustomer?.email2
              ? selectedInterestedCustomer.email2
              : selectedUnassigned?.email
              ? selectedUnassigned.email
              : '',
            phone: selectedInterestedCustomer?.phone1,
            traderInitials: selectedInterestedCustomer?.traderInitials,
            marketingConsents: selectedUnassigned?.marketingConsents?.[0]
              ? selectedUnassigned?.marketingConsents
              : selectedInterestedCustomer?.marketingConsents,
            source:
              selectedUnassigned?.source && selectedUnassigned.source !== 'mail'
                ? selectedUnassigned.source
                : selectedInterestedCustomer.sourceInfo,
          }}
          unassignedCustomerEmail={unassignedCustomerEmail}
          handleClose={() => {
            handleClose && handleClose()
            setEditNewInterestedModal(false)
          }}
          isEditCustomersView
          isTakingUnassigned={isTakingUnassigned}
          show={editNewInterestedModal}
          unassignedCustomerID={selectedUnassigned.id}
        />
      ) : null}
    </>
  )
}

export default TableInterestedCustomer
