import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Logo from '../commons/Logo/Logo'

import { hasRoles } from '../../services/idp'

import { RootState } from '../../ducks'
import { AdminITRoles, DMAAndDMAADMRoles } from '../../ducks/globalUser'

const HeaderLogo: FunctionComponent = () => {
  const { roles } = useSelector((state: RootState) => state.globalUser)

  return (
    <Link
      to={
        hasRoles(AdminITRoles, roles) || hasRoles(DMAAndDMAADMRoles, roles)
          ? '/main-table-view'
          : '/'
      }
    >
      <Logo />
    </Link>
  )
}

export default HeaderLogo
