import {
  downloadXlsxReport,
  fetchDKSBusinessPlacesReport,
} from '../../ducks/reports'
import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AppDispatch, RootState } from '../../ducks'

import { Investments } from './RaportWithDates/Investments'
import { CustomerType } from './RaportWithDates/CustomerType'
import { EmailInvoiceAndValorization } from './RaportWithDates/EmailInvoiceAndValorization'
import { Stages } from './RaportWithDates/Stages'
import { RaportWithDatesState } from './RaportWithDates/actions'
import { RaportWithDates } from './RaportWithDates/RaportWithDates'

const DKSBusinessPlacesReport: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const history = useHistory()
  const isLoading = useSelector(
    (state: RootState) =>
      state.combinedReports.DKSBusinessPlacesReport.isLoading
  )

  const generateReport = useCallback(
    async (state: RaportWithDatesState) => {
      const stagesIDs = state.chosenStages.map((stage) => stage.id)
      let type = ''
      switch (state.customerType) {
        case 'Osoba fizyczna':
          type = 'CUSTOMER'
          break
        case 'Działalność gospodarcza':
          type = 'BUSINESS_CUSTOMER'
          break
        case 'Podmiot gospodarczy':
          type = 'COMPANY'
          break
      }
      dispatch(
        fetchDKSBusinessPlacesReport({
          customerType: type,
          emailInvoice: state.isEmailInvoice,
          valorization: state.isValorization,
          stagesIDs,
        })
      )
        .then((res: any) => {
          if (res.payload?.DKSBusinessPlacesReport) {
            const parameters = {
              content: res.payload.DKSBusinessPlacesReport,
              filename: 'DKSRaport-LokaleNaDzialalnosc.xlsx',
            }
            return dispatch(downloadXlsxReport(parameters))
          }
          alert('Błąd podczas pobierania raportu')
        })
        .then(() => {
          history.push('/')
        })
    },
    [dispatch, history]
  )

  return (
    <RaportWithDates isLoading={isLoading} generateReport={generateReport}>
      <CustomerType />
      <EmailInvoiceAndValorization />
      <Investments />
      <Stages />
    </RaportWithDates>
  )
}

export default DKSBusinessPlacesReport
