import moment from 'moment'

const columnsData = [
  {
    id: 'customerID',
    Header: 'Zainteresowany',
    accessor: (a: { customerID?: number | null }): string =>
      a.customerID ? '☑' : '☐',
    width: '150',
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: (a: { status?: string | null }): string => {
      if (a?.status === 'taken') {
        return 'Podjęty'
      } else if (a?.status === 'notTaken') {
        return 'Niepodjęty'
      } else if (a?.status === 'SPAM') {
        return 'Spam'
      } else if (a?.status === 'client') {
        return 'Klient'
      } else if (a?.status === 'finished') {
        return 'Zakończony'
      } else {
        return ''
      }
    },
    width: '150',
  },
  {
    id: 'countOfMessages',
    Header: 'L. wiadomości składowych',
    accessor: 'countOfMessages',
    width: '130',
  },
  {
    id: 'firstName',
    Header: 'Imię',
    accessor: (a?: { firstName?: string | null }): string => {
      return `${a?.firstName || ''}`
    },
    width: '175',
  },
  {
    id: 'lastName',
    Header: 'Nazwisko',
    accessor: (a?: { lastName?: string | null }): string => {
      return `${a?.lastName || ''}`
    },
    width: '175',
  },
  {
    id: 'firstMessageDate',
    Header: 'Data wpływu',
    accessor: 'firstMessageDate',
    width: '120',
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: '300',
  },
  {
    Header: 'Telefon',
    accessor: 'phone',
    width: '150',
  },
  {
    id: 'lastContactDate',
    Header: 'L. dni',
    accessor: (a?: { customer?: { lastContactDate?: string } }): string => {
      const dayDiff = String(
        moment().diff(moment(a?.customer?.lastContactDate), 'day')
      )
      return a?.customer?.lastContactDate ? dayDiff : ''
    },
    width: '50',
    disableSortBy: true,
  },
  {
    id: 'customerTraderInitials',
    Header: 'Opiekun',
    accessor: (a?: { customer?: { traderInitials?: string } }): string =>
      a?.customer?.traderInitials || '',
    width: '110',
  },
  {
    id: 'traderInitials',
    Header: 'Handlowiec',
    accessor: (a?: { traderInitials?: string }): string =>
      a?.traderInitials || '',
    width: '110',
  },
  {
    id: 'actions',
    Header: 'Akcje',
    width: '350',
  },
]

export default columnsData
