import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../ducks'
import {
  downloadXlsxReport,
  fetchLeadAndInterestedReport,
} from '../../ducks/reports'
import { RaportWithDates } from './RaportWithDates/RaportWithDates'
import MainRow from './../commons/Containers/MainRow'
import { useTranslation } from 'react-i18next'
import SimpleInputList2, {
  IOptionElement,
} from './../commons/Inputs/SimpleInputs/SimpleInputList2'
import { useState } from 'react'

const LeadAndInterestedReport: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const todayYear = new Date().getFullYear()
  const [selectedOption, setSelectedOption] = useState<number>(todayYear)
  const isLoading = useSelector(
    (state: RootState) =>
      state.combinedReports.LeadAndInterestedReport.isLoading
  )
  const yearOptions = Array.from(Array(todayYear - 2019))
    .map((v, index) => ({ id: 2020 + index, name: 2020 + index }))
    .reverse()

  const generateReport = React.useCallback(async () => {
    const response = await dispatch(
      fetchLeadAndInterestedReport({ year: selectedOption })
    )
    const content =
      response.payload?.MiniCRMUnassignedCustomersAndCustomersReport
    if (!content) {
      return
    }
    dispatch(
      downloadXlsxReport({
        content,
        filename: 'Raport - leadów i zainteresowanych.xlsx',
      })
    )
  }, [dispatch, selectedOption])

  return (
    <RaportWithDates isLoading={isLoading} generateReport={generateReport}>
      <MainRow isRowContentCentered>
        <p className='icr-header mt-4'>{t('commons:labels.choose-year')}</p>
      </MainRow>
      <div className='mb-4'>
        <SimpleInputList2
          label='Rok'
          options={yearOptions}
          disabled={isLoading}
          defaultValue={selectedOption}
          onChangeValue={(o: IOptionElement) => setSelectedOption(Number(o.id))}
        />
      </div>
    </RaportWithDates>
  )
}

export default LeadAndInterestedReport
