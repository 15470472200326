import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { IContractCustomerInput } from '../graphql/contractCustomers'
import { IContractCustomerCompanyInput } from '../graphql/contractCustomerCompany'
import { v4 as uuidv4 } from 'uuid'
import { IInterestedCustomer } from '../graphql/customers'
import InterestedCustomerService from '../services/interestedCustomerService'
import RegisterSalesService from '../services/registerSalesService'
import { RootState } from './index'
import { defaultMarketingConsents } from './marketingConsents'
import { IMarketingConsentInput } from '../graphql/marketingConsents'
import { hasRoles } from '../services/idp'
import { DNKRoles } from './globalUser'

export const defaultContractCustomer = {
  id: -1,
  uuid: '',
  parentUuid: '',
  IDNumberAndSeries: '',
  businessAddressIsForeign: false,
  businessAddressPostalCode: '',
  businessAddressStreet: '',
  businessAddressNumber: '',
  businessAddressVoivodeship: '',
  citizenship: '',
  correspondenceCity: '',
  businessAddressBusinessName: '',
  businessAddressCity: '',
  businessAddressCountry: '',
  businessAddressName: '',
  businessAddressSurname: '',
  correspondenceBusinessName: '',
  correspondenceCountry: '',
  correspondenceIsForeign: false,
  correspondenceName: '',
  correspondencePostalCode: '',
  correspondenceStreet: '',
  correspondenceNumber: '',
  correspondenceSurname: '',
  correspondenceVoivodeship: '',
  customerCode: '',
  customerType: '',
  email: '',
  email2: '',
  firstName: '',
  isBusiness: false,
  isToughCustomer: false,
  lastName: '',
  maidenName: '',
  parentsNames: '',
  passportNumberAndSeries: '',
  pesel: '',
  phone1: '',
  phone2: '',
  regon: '',
  residenceBusinessName: '',
  residenceCity: '',
  residenceCountry: '',
  residenceIsForeign: false,
  residenceName: '',
  residencePostalCode: '',
  residenceStreet: '',
  residenceNumber: '',
  residenceSurname: '',
  residenceVoivodeship: '',
  secondName: '',
  sex: '',
  taxNumber: '',
  isBlueList: false,
  isSpouseOfMainCustomer: false,
  isAgentOfMainCustomer: false,
  isAgentOfCustomerSpouse: false,
  maritalStatus: '',
  purchasePurpose: '',
  businessType: '',
  businessName: '',
  sourceInfo: '',
  isBroker: false,
  brokerName: '',
  isCommissionDivision: false,
  commissionDivision: '',
  customerInvestmentCode: '',
  traderInitials: '',
  placeShares: '/100',
  isAfterMigration: true,
  isFromMigration: false,
}

export const defaultContractCustomerCompany = {
  id: -1,
  uuid: '',
  customerType: 'Podmiot gospodarczy',
  parentUuid: '',
  businessName: '',
  krsString: '',
  taxNumber: '',
  phone1: '',
  phone2: '',
  representativePhone: '',
  businessActivityRegister: 0,
  regon: '',
  email: '',
  representativeEmail: '',
  businessType: '',
  placeShares: '/100',
  lastName: '',
  firstName: '',
  pesel: '',
  residenceName: '',
  residenceSurname: '',
  residenceBusinessName: '',
  residenceCountry: '',
  residenceCity: '',
  residenceStreet: '',
  residenceNumber: '',
  residencePostalCode: '',
  residenceVoivodeship: '',
  residenceIsForeign: false,
  correspondenceName: '',
  correspondenceSurname: '',
  correspondenceBusinessName: '',
  correspondenceCountry: '',
  correspondenceCity: '',
  correspondenceStreet: '',
  correspondenceNumber: '',
  correspondencePostalCode: '',
  correspondenceVoivodeship: '',
  correspondenceIsForeign: false,
  businessAddressName: '',
  businessAddressSurname: '',
  businessAddressBusinessName: '',
  businessAddressCountry: '',
  businessAddressCity: '',
  businessAddressStreet: '',
  businessAddressNumber: '',
  businessAddressPostalCode: '',
  businessAddressVoivodeship: '',
  businessAddressIsForeign: false,
  isInfoDutyFulfilled: false,
  infoDutyForm: '',
  isAgreementPhone: false,
  isAgreementPhoneCancelled: false,
  isAgreementEmail: false,
  isAgreementEmailCancelled: false,
  customerInvestmentCode: '',
  sourceInfo: '',
  isCommissionDivision: false,
  commissionDivision: '',
  isBroker: false,
  brokerName: '',
  purchasePurpose: '',
  traderInitials: '',
  isBusinessActivity: false,
  isAfterMigration: true,
} as IContractCustomerCompanyInput

interface RegisterSalesState {
  contractDealDateFromDucks: string | null
  interestedCustomers: IInterestedCustomer[]
  isSaveContractPending: boolean
  isSaveCompanyContractPending: boolean
  newContractCustomers: IContractCustomerInput[]
  newContractCustomerCompanies: IContractCustomerCompanyInput[]
  newRelativeCustomers: IContractCustomerInput[]
  newRelativeCustomerCompanies: IContractCustomerCompanyInput[]
  interestedCustomerIDs: number[]
  selectedInterestedCustomerID: number
  registerProcessIsBusiness: boolean
}

export interface AddNewRelativeCustomerProps {
  customerParentID: number | null
  parentUuid: string
  isSpouseOfMainCustomer: boolean
  isAgentOfMainCustomer: boolean
  isAgentOfCustomerSpouse: boolean
  maritalStatus?: string
  placeShares?: string
}

export interface AddNewRelativeCustomerCompanyProps {
  isAgentOfMainCustomer: boolean
  isRepresentativePerson: boolean
  parentUuid: string
}

export interface AddNewRelativeCustomerCompanyProps {
  parentUuid: string
  isAgentOfMainCustomer: boolean
  isRepresentativePerson: boolean
}

const initialState = {
  contractDealDateFromDucks: null,
  interestedCustomers: [],
  isSaveContractPending: false,
  isSaveCompanyContractPending: false,
  newContractCustomers: [],
  newContractCustomerCompanies: [],
  newRelativeCustomers: [],
  newRelativeCustomerCompanies: [],
  interestedCustomerIDs: [],
  selectedInterestedCustomerID: 0,
  registerProcessIsBusiness: false,
} as RegisterSalesState

const stateSelector = (state: RootState): RootState => state

export const CustomerCodeForSaleRegister = createSelector(
  stateSelector,
  (state: RootState): string => {
    const salesState = state.registerSales
    const contractCustomers = salesState.newContractCustomers
    const relativeContractCustomers = salesState.newRelativeCustomers
    const contractOwners = contractCustomers?.filter(
      (c) =>
        !c?.isAgentOfMainCustomer &&
        !c?.isSpouseOfMainCustomer &&
        !c?.isAgentOfCustomerSpouse
    )
    const relativeCustomersWithoutAgents = relativeContractCustomers?.filter(
      (c) => !c?.isAgentOfMainCustomer && !c?.isAgentOfCustomerSpouse
    )
    const investmentName =
      state.globalInvestment.chosenPlacesContract?.investment?.name ?? ''
    const placeCode =
      state.globalInvestment.chosenPlacesContract?.placeCode ?? ''
    const femaleContractCustomers = contractCustomers?.filter(
      (customer) => customer.sex === 'K'
    )
    const femaleRelativeCustomers = relativeCustomersWithoutAgents?.filter(
      (customer) => customer?.sex === 'K'
    )
    const femaleCustomers = [
      ...femaleContractCustomers,
      ...femaleRelativeCustomers,
    ]

    const femaleCustomersSorted = femaleCustomers?.sort((a, b) =>
      a.lastName.localeCompare(b.lastName)
    )
    const femaleContractCustomersSorted = femaleContractCustomers?.sort(
      (a, b) => a.lastName.localeCompare(b.lastName)
    )
    const equalLastNameContractCustomerFemale = femaleCustomersSorted.filter(
      (a) => a?.lastName === femaleContractCustomersSorted?.[0]?.lastName
    )
    const customersSorted = contractOwners
      ?.slice()
      ?.sort((a, b) => a?.lastName?.localeCompare(b?.lastName))

    let customer
    if (equalLastNameContractCustomerFemale.length >= 2) {
      const sorted = equalLastNameContractCustomerFemale.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      )
      customer = sorted?.[0]
    } else if (femaleCustomersSorted?.length) {
      customer = femaleCustomersSorted?.[0]
    } else if (customersSorted?.length) {
      customer = customersSorted?.[0]
    }
    return String(
      investmentName +
        placeCode.split('.').join('') +
        customer?.lastName.substring(0, 3) +
        customer?.firstName.substring(0, 3)
    ).toUpperCase()
  }
)

export const CustomerCodeForEditData = createSelector(
  stateSelector,
  (state: RootState): string => {
    const investmentName =
      state.globalInvestment.chosenPlacesContract?.investment?.name ?? ''
    const placeCode =
      state.globalInvestment.chosenPlacesContract?.placeCode ?? ''
    const placesState = state.places.selectedPlace?.activeContracts?.[0]
    const contractCustomers = placesState?.contractCustomers

    const customersWithoutAgents = contractCustomers?.filter(
      (c) => !c?.isAgentOfMainCustomer && !c?.isAgentOfCustomerSpouse
    )
    const contractCustomersWithoutAgents = contractCustomers?.filter(
      (c) => !c?.isAgentOfMainCustomer && !c?.isAgentOfCustomerSpouse
    )
    const femaleContractCustomers = contractCustomersWithoutAgents?.filter(
      (customer) => customer.sex === 'K'
    )
    const femaleContractCustomersSorted = femaleContractCustomers?.sort(
      (a, b) => a.lastName.localeCompare(b.lastName)
    )
    const customersSorted = customersWithoutAgents
      ?.slice()
      ?.sort((a, b) => a?.lastName?.localeCompare(b?.lastName))
    const equalLastNameContractCustomerFemale =
      femaleContractCustomersSorted?.filter(
        (a) => a?.lastName === femaleContractCustomersSorted?.[0]?.lastName
      ) || []

    let customer
    if (equalLastNameContractCustomerFemale?.length >= 2) {
      const sorted = equalLastNameContractCustomerFemale.sort((a, b) =>
        a?.firstName.localeCompare(b?.firstName)
      )
      customer = sorted?.[0]
    } else if (femaleContractCustomersSorted?.length) {
      customer = femaleContractCustomersSorted?.[0]
    } else if (customersSorted?.length) {
      customer = customersSorted?.[0]
    }
    return String(
      investmentName +
        placeCode.split('.').join('') +
        customer?.lastName.substring(0, 3) +
        customer?.firstName.substring(0, 3)
    ).toUpperCase()
  }
)

export const CompaniesCustomerCodeForSaleRegister = createSelector(
  stateSelector,
  (state: RootState): string => {
    const salesState = state.registerSales
    const investmentName =
      state.globalInvestment.chosenPlacesContract?.investment?.name ?? ''
    const placeCode =
      state.globalInvestment.chosenPlacesContract?.placeCode ?? ''
    const customersSorted = salesState.newContractCustomerCompanies
      ?.slice()
      ?.sort((a, b) => a?.businessName?.localeCompare(b?.businessName))
    const customerCompany = customersSorted?.[0]?.businessName.length
      ? customersSorted?.[0]
      : customersSorted?.[1]

    return String(
      (investmentName ?? '') +
        (placeCode.split('.').join('') || '') +
        (customerCompany?.businessName?.substring(0, 3) || '') +
        (customerCompany?.companyForm?.substring(0, 3) || '')
    ).toUpperCase()
  }
)

export const CompaniesCustomerCodeForEditData = createSelector(
  stateSelector,
  (state: RootState): string => {
    const placesState = state.places.selectedPlace
    const activeContract = placesState?.activeContracts?.[0]
    const contractCustomerCompanies =
      activeContract?.contractCustomerCompanies || []
    const investmentName =
      state.globalInvestment.chosenPlacesContract?.investment?.name ?? ''
    const placeCode =
      state.globalInvestment.chosenPlacesContract?.placeCode ?? ''
    const owners = contractCustomerCompanies.filter(
      (customer) =>
        !customer.isAgentOfMainCustomer && !customer.isRepresentativePerson
    )
    const customersSorted = owners
      ?.slice()
      ?.sort((a, b) => a?.businessName?.localeCompare(b?.businessName))
    const customerCompany = customersSorted?.[0]?.businessName.length
      ? customersSorted?.[0]
      : customersSorted?.[1]

    return String(
      (investmentName ?? '') +
        (placeCode.split('.').join('') || '') +
        (customerCompany?.businessName?.substring(0, 3) || '') +
        (customerCompany?.companyForm?.substring(0, 3) || '')
    ).toUpperCase()
  }
)

export const getInterestedCustomerMarketingConsentsFromContractCustomer = (
  id?: number
): any =>
  createSelector(
    (state: RootState) => state.interestedCustomers,
    (interestedCustomers) => {
      const index = interestedCustomers.interestedCustomers.findIndex(
        (i) => i.id === id
      )
      if (index !== -1) {
        return interestedCustomers.interestedCustomers[index].marketingConsents
      }
    }
  )

export const fetchInterestedCustomersByContractID = createAsyncThunk(
  'registerSales/fetchInterestedCustomersByContractID',
  async (contractID: number[]) =>
    InterestedCustomerService.fetchInterestedCustomersByContractID(contractID)
)

export const saveCompanyContract = createAsyncThunk(
  'registerSales/saveCompanyContract',
  async (variables: { contract?: any }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const {
      newContractCustomerCompanies,
      newRelativeCustomerCompanies,
      interestedCustomerIDs,
    } = state.registerSales
    const { roomsLinkedToContract } = state.utilityRooms
    const { parkingPlacesLinkedToContract } = state.parkingPlaces
    const utilityRoomsList = [] as number[]
    const parkingPlacesList = [] as number[]

    roomsLinkedToContract.forEach((r) => {
      if (r.id) {
        utilityRoomsList.push(r.id)
      }
    })
    parkingPlacesLinkedToContract.forEach((r) => {
      if (r.id) {
        parkingPlacesList.push(r.id)
      }
    })

    const ids = [] as number[]
    const contractCustomerCompaniesList = [] as any[]
    const contractCustomerCompanies = [
      ...newContractCustomerCompanies,
      ...newRelativeCustomerCompanies,
    ]

    const newMarketingConsents = [] as Partial<IMarketingConsentInput>[]
    contractCustomerCompanies.forEach(
      (customerCompany: IContractCustomerCompanyInput) => {
        if (customerCompany?.marketingConsents?.[0]) {
          const {
            id: _,
            ...consentsWithoutID
          } = customerCompany?.marketingConsents?.[0]
          newMarketingConsents.push(consentsWithoutID)
        } else {
          newMarketingConsents.push(defaultMarketingConsents)
        }
      }
    )

    newContractCustomerCompanies.forEach((customerCompany) => {
      const {
        id,
        marketingConsents,
        contract,
        isFromMigration,
        ...customerWithoutId
      } = customerCompany
      customerWithoutId.uuid = customerWithoutId.parentUuid
      const { parentUuid, ...withoutParentUuid } = customerWithoutId
      contractCustomerCompaniesList.push(withoutParentUuid)
      const index = interestedCustomerIDs.findIndex((i) => i === id)
      if (id > 0 && index > -1) {
        ids.push(id)
      }
    })
    newRelativeCustomerCompanies.forEach((customerCompany) => {
      const {
        id,
        marketingConsents,
        contract,
        isFromMigration,
        ...customerWithoutId
      } = customerCompany
      customerWithoutId.uuid = customerWithoutId.parentUuid
      const { parentUuid, ...withoutParentUuid } = customerWithoutId
      contractCustomerCompaniesList.push(withoutParentUuid)
      const index = interestedCustomerIDs.findIndex((i) => i === id)
      if (id > 0 && index > -1) {
        ids.push(id)
      }
    })

    return RegisterSalesService.saveContractWithCustomerCompanies({
      utilityRoomsList,
      parkingPlacesList,
      customersList: ids,
      contractCustomerCompanys: contractCustomerCompaniesList,
      contract: variables.contract,
      marketingConsents: newMarketingConsents,
    })
  }
)

export const saveContract = createAsyncThunk(
  'registerSales/saveContract',
  async (variables: { contract: any }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const {
      newContractCustomers,
      newRelativeCustomers,
      interestedCustomerIDs,
    } = state.registerSales
    const { roomsLinkedToContract } = state.utilityRooms
    const { parkingPlacesLinkedToContract } = state.parkingPlaces
    const utilityRoomsList = [] as number[]
    const parkingPlacesList = [] as number[]

    roomsLinkedToContract.forEach((r) => {
      if (r.id) {
        utilityRoomsList.push(r.id)
      }
    })
    parkingPlacesLinkedToContract.forEach((r) => {
      if (r.id) {
        parkingPlacesList.push(r.id)
      }
    })

    const ids = [] as number[]
    const contractCustomersList = [] as any[]
    const contractCustomers = [...newContractCustomers, ...newRelativeCustomers]
    const { customerCode: contractCode } = variables.contract

    const newMarketingConsents = [] as Partial<IMarketingConsentInput>[]
    contractCustomers.forEach((customer: IContractCustomerInput) => {
      if (customer?.marketingConsents?.[0]) {
        const { id: _, ...consentsWithoutID } = customer?.marketingConsents?.[0]
        newMarketingConsents.push(consentsWithoutID)
      } else {
        newMarketingConsents.push(defaultMarketingConsents)
      }
    })

    newContractCustomers.forEach((customer) => {
      const { id, marketingConsents, contract, ...customerWithoutId } = customer
      customerWithoutId.uuid = customerWithoutId.parentUuid
      const {
        parentUuid,
        customerCode,
        ...withoutParentUuid
      } = customerWithoutId
      contractCustomersList.push({
        ...withoutParentUuid,
        customerCode: contractCode,
      })
      const index = interestedCustomerIDs.findIndex((i) => i === id)
      if (id > 0 && index > -1) {
        ids.push(id)
      }
    })
    newRelativeCustomers.forEach((customer) => {
      const { id, marketingConsents, contract, ...customerWithoutId } = customer
      customerWithoutId.uuid = customerWithoutId.parentUuid
      const {
        parentUuid,
        customerCode,
        ...withoutParentUuid
      } = customerWithoutId
      contractCustomersList.push({
        ...withoutParentUuid,
        customerCode: contractCode,
      })
      const index = interestedCustomerIDs.findIndex((i) => i === id)
      if (id > 0 && index > -1) {
        ids.push(id)
      }
    })

    return RegisterSalesService.saveContractWithCustomers({
      utilityRoomsList,
      parkingPlacesList,
      customersList: ids,
      contractCustomersList,
      contract: variables.contract,
      marketingConsents: newMarketingConsents,
    })
  }
)

export const fetchInterestedCustomerData = createAsyncThunk(
  'registerSales/fetchInterestedCustomerData',
  async (customer: IContractCustomerInput, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const { selectedInterestedCustomer } = state.interestedCustomers
    const {
      newContractCustomers,
      newRelativeCustomers,
      interestedCustomerIDs,
    } = state.registerSales
    const { roles } = state.globalUser
    const isDNKCustomer = hasRoles(DNKRoles, roles)

    if (selectedInterestedCustomer) {
      const isRelative = customer.parentUuid !== customer.uuid
      const newCustomers = isRelative
        ? newRelativeCustomers
        : newContractCustomers
      const index = newCustomers.findIndex((c) => c?.uuid === customer.uuid)

      if (selectedInterestedCustomer) {
        const idExists = newContractCustomers
          .filter((c) => c?.id > 0)
          .find((c) => c?.id === selectedInterestedCustomer.id)
        const c = selectedInterestedCustomer
        if (idExists) {
          alert('Wybrany klient zainteresowany został już dodany do umowy')
          return
        }
        if (!interestedCustomerIDs.includes(c.id)) {
          thunkAPI.dispatch(addInterestedCustomerID(c.id))
        }

        const newCustomer = {
          id: c?.id,
          uuid: customer.uuid,
          parentUuid: customer.parentUuid,
          IDNumberAndSeries: c?.IDNumberAndSeries ?? '',
          isDNKCustomer,
          businessAddressIsForeign: c?.businessAddressIsForeign ?? false,
          businessAddressPostalCode: c?.businessAddressPostalCode ?? '',
          businessAddressStreet: c?.businessAddressStreet ?? '',
          businessAddressNumber: c?.businessAddressNumber ?? '',
          businessAddressVoivodeship: c?.businessAddressVoivodeship ?? '',
          citizenship: c?.citizenship ?? '',
          correspondenceCity: c?.correspondenceCity ?? '',
          businessAddressBusinessName: c?.businessAddressBusinessName ?? '',
          businessAddressCity: c?.businessAddressCity ?? '',
          businessAddressCountry: c?.businessAddressCity ?? '',
          businessAddressName: c?.businessAddressName ?? '',
          businessAddressSurname: c?.businessAddressSurname ?? '',
          correspondenceBusinessName: c?.correspondenceBusinessName ?? '',
          correspondenceCountry: c?.correspondenceCountry ?? '',
          correspondenceIsForeign: c?.correspondenceIsForeign ?? false,
          correspondenceName: c?.correspondenceName ?? '',
          correspondencePostalCode: c?.correspondencePostalCode ?? '',
          correspondenceStreet: c?.correspondenceStreet ?? '',
          correspondenceNumber: c?.correspondenceNumber ?? '',
          correspondenceSurname: c?.correspondenceSurname ?? '',
          correspondenceVoivodeship: c?.correspondenceVoivodeship ?? '',
          customerCode: '',
          customerType: c?.customerType ?? '',
          email: c?.email ?? '',
          firstName: c?.firstName ?? '',
          isAgentOfCustomerSpouse: customer.isAgentOfCustomerSpouse ?? false,
          isAgentOfMainCustomer: customer.isAgentOfMainCustomer ?? false,
          isBusiness: Boolean(c?.isBusiness) ?? false,
          isSpouseOfMainCustomer: customer.isSpouseOfMainCustomer ?? false,
          isToughCustomer: c?.isToughCustomer ?? false,
          lastName: c?.lastName ?? '',
          maidenName: c?.maidenName ?? '',
          maritalStatus: customer.maritalStatus ?? '',
          parentsNames: c?.parentsNames ?? '',
          passportNumberAndSeries: c?.passportNumberAndSeries ?? '',
          pesel: c?.pesel ?? '',
          phone1: c?.phone1 ?? '',
          phone2: c?.phone2 ?? '',
          placeShares: customer.placeShares ?? '',
          regon: c?.regon ?? '',
          residenceBusinessName: c?.residenceBusinessName ?? '',
          residenceCity: c?.residenceCity ?? '',
          residenceCountry: c?.residenceCountry ?? '',
          residenceIsForeign: c?.residenceIsForeign ?? false,
          residenceName: c?.residenceName ?? '',
          residencePostalCode: c?.residencePostalCode ?? '',
          residenceStreet: c?.residenceStreet ?? '',
          residenceNumber: c?.residenceNumber ?? '',
          residenceSurname: c?.residenceSurname ?? '',
          residenceVoivodeship: c?.residenceVoivodeship ?? '',
          secondName: c?.secondName ?? '',
          sex: c?.sex ?? '',
          taxNumber: c?.taxNumber ?? '',
          isBlueList: c?.isBlueList ?? false,
          businessType: c?.businessType ?? '',
          purchasePurpose: c?.purchasePurpose ?? '',
          businessName: '',
          sourceInfo: c?.sourceInfo ?? '',
          isBroker: c?.isBroker ?? false,
          brokerName: c?.brokerName ?? '',
          isCommissionDivision: c?.isCommissionDivision ?? false,
          commissionDivision: c?.commissionDivision ?? '',
          customerID: c?.id ?? undefined,
          marketingConsents: c?.marketingConsents,
          isIKCustomer: Boolean(c?.contacts?.length),
          isAfterMigration: c?.isAfterMigration ?? true,
        } as IContractCustomerInput

        if (index !== -1) {
          thunkAPI.dispatch(
            updateCustomerData({ isRelative, newCustomer, index })
          )
        }
      }
    }
  }
)

export const fetchInterestedCustomerDataForCompanies = createAsyncThunk(
  'registerSales/fetchInterestedCustomerDataForCompanies',
  async (customer: IContractCustomerCompanyInput, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const { selectedInterestedCustomer } = state.interestedCustomers
    const {
      newRelativeCustomerCompanies,
      newContractCustomerCompanies,
      interestedCustomerIDs,
    } = state.registerSales
    const { roles } = state.globalUser
    const isDNKCustomer = hasRoles(DNKRoles, roles)

    if (selectedInterestedCustomer) {
      const isRelative = customer.parentUuid !== customer.uuid
      const newCustomerCompanies = isRelative
        ? newRelativeCustomerCompanies
        : newContractCustomerCompanies
      const index = newCustomerCompanies.findIndex(
        (c) => c?.uuid === customer.uuid
      )

      if (selectedInterestedCustomer) {
        const idExists = newContractCustomerCompanies
          .filter((c) => c?.id > 0)
          .find((c) => c?.id === selectedInterestedCustomer.id)
        const c = selectedInterestedCustomer
        if (idExists) {
          alert('Wybrany klient zainteresowany został już dodany do umowy')
          return
        }
        if (!interestedCustomerIDs.includes(c.id)) {
          thunkAPI.dispatch(addInterestedCustomerID(c.id))
        }

        const newCustomerCompany = {
          id: c?.id,
          uuid: customer.uuid,
          isDNKCustomer,
          customerType: 'Podmiot gospodarczy',
          parentUuid: customer.parentUuid,
          businessName: c?.businessName ?? '',
          krsString: '',
          taxNumber: c?.taxNumber ?? '',
          phone1: c?.phone1 ?? '',
          phone2: c?.phone2 ?? '0',
          representativePhone: customer?.representativePhone ?? '',
          businessActivityRegister: 0,
          regon: c?.regon ?? '',
          email: c?.email ?? '',
          representativeEmail: customer?.representativePhone ?? '',
          businessType: c?.businessType ?? '',
          lastName: c?.lastName ?? '',
          firstName: c?.firstName ?? '',
          pesel: c?.pesel ?? '',
          residenceName: c?.residenceName ?? '',
          residenceSurname: c?.residenceSurname ?? '',
          residenceBusinessName: c?.residenceBusinessName ?? '',
          residenceCountry: c?.residenceCountry ?? '',
          residenceCity: c?.residenceCity ?? '',
          residenceStreet: c?.residenceStreet ?? '',
          residenceNumber: c?.residenceNumber ?? '',
          residencePostalCode: c?.residencePostalCode ?? '',
          residenceVoivodeship: c?.residenceVoivodeship ?? '',
          residenceIsForeign: c?.residenceIsForeign ?? false,
          correspondenceName: c?.correspondenceName ?? '',
          correspondenceSurname: c?.correspondenceSurname ?? '',
          correspondenceBusinessName: c?.correspondenceBusinessName ?? '',
          correspondenceCountry: c?.correspondenceCountry ?? '',
          correspondenceCity: c?.correspondenceCity ?? '',
          correspondenceStreet: c?.correspondenceStreet ?? '',
          correspondenceNumber: c?.correspondenceNumber ?? '',
          correspondencePostalCode: c?.correspondencePostalCode ?? '',
          correspondenceVoivodeship: c?.correspondenceVoivodeship ?? '',
          correspondenceIsForeign: c?.correspondenceIsForeign ?? false,
          businessAddressName: c?.businessAddressName ?? '',
          businessAddressSurname: c?.businessAddressSurname ?? '',
          businessAddressBusinessName: c?.businessAddressBusinessName ?? '',
          businessAddressCountry: c?.businessAddressCountry ?? '',
          businessAddressCity: c?.businessAddressCity ?? '',
          businessAddressStreet: c?.businessAddressStreet ?? '',
          businessAddressNumber: c?.businessAddressNumber ?? '',
          businessAddressPostalCode: c?.businessAddressPostalCode ?? '',
          businessAddressVoivodeship: c?.businessAddressVoivodeship ?? '',
          businessAddressIsForeign: c?.businessAddressIsForeign ?? false,
          isInfoDutyFulfilled: false,
          infoDutyForm: '',
          isAgreementPhone: false,
          isAgreementPhoneCancelled: false,
          isAgreementEmail: false,
          isAgreementEmailCancelled: false,
          placeShares: customer.placeShares ?? '',
          customerInvestmentCode: '',
          sourceInfo: c?.sourceInfo,
          isCommissionDivision: c?.isCommissionDivision ?? false,
          commissionDivision: c?.commissionDivision ?? '',
          isBroker: c?.isBroker ?? false,
          brokerName: c?.brokerName ?? '',
          purchasePurpose: c?.purchasePurpose ?? '',
          isBusinessActivity: false,
          customerID: c?.id ?? undefined,
          agreementPhoneDate: undefined,
          marketingConsents: c?.marketingConsents ?? undefined,
          isAgentOfMainCustomer: customer.isAgentOfMainCustomer,
          isRepresentativePerson: customer.isRepresentativePerson,
          isAfterMigration: Boolean(c?.isAfterMigration),
        } as IContractCustomerCompanyInput

        if (index !== -1) {
          thunkAPI.dispatch(
            updateCustomerCompanyData({ isRelative, newCustomerCompany, index })
          )
        }
      }
    }
  }
)

const registerSales = createSlice({
  name: 'registerSales',
  initialState,
  reducers: {
    updateContractDealDate(state, action: PayloadAction<string | null>): void {
      state.contractDealDateFromDucks = action.payload
    },
    clearRegisterSales(state, _: PayloadAction<void>): void {
      state.newContractCustomers = []
      state.newContractCustomerCompanies = []
      state.interestedCustomerIDs = []
      state.newRelativeCustomers = []
      state.newRelativeCustomerCompanies = []
      state.selectedInterestedCustomerID = 0
      state.registerProcessIsBusiness = false
    },
    clearInterestedCustomers(state, _: PayloadAction<void>): void {
      state.interestedCustomers = []
    },
    toggleIsBusinessRegisterProcess(
      state,
      action: PayloadAction<boolean>
    ): void {
      state.registerProcessIsBusiness = action.payload
    },
    addEmptyContractCustomer(state, _: PayloadAction<void>): void {
      const uuid = uuidv4()
      state.newContractCustomers.push({
        ...defaultContractCustomer,
        uuid: uuid,
        parentUuid: uuid,
      })
    },
    addEmptyContractCustomerCompany(state, _: PayloadAction<void>): void {
      const uuid = uuidv4()
      state.newContractCustomerCompanies.push({
        ...defaultContractCustomerCompany,
        uuid: uuid,
        parentUuid: uuid,
        customerType: 'Podmiot gospodarczy',
      })
    },
    setSelectedInterestedCustomerID(
      state,
      action: PayloadAction<number>
    ): void {
      state.selectedInterestedCustomerID = action.payload
    },
    addInterestedCustomerID(state, action: PayloadAction<number>): void {
      state.interestedCustomerIDs = [
        ...state.interestedCustomerIDs,
        action.payload,
      ]
    },
    editContractCustomer(
      state,
      action: PayloadAction<IContractCustomerInput>
    ): void {
      const customer = action.payload
      if (
        !customer.isSpouseOfMainCustomer &&
        !customer.isAgentOfCustomerSpouse &&
        !customer.isAgentOfMainCustomer
      ) {
        const index = state.newContractCustomers.findIndex(
          (c) => c?.uuid === customer.uuid
        )
        state.newContractCustomers[index] = customer
      } else {
        const index = state.newRelativeCustomers.findIndex(
          (c) => c?.uuid === customer.uuid
        )
        state.newRelativeCustomers[index] = customer
      }
    },
    editContractCustomerCompany(
      state,
      action: PayloadAction<IContractCustomerCompanyInput>
    ): void {
      const customer = action.payload
      if (!customer.isAgentOfMainCustomer && !customer.isRepresentativePerson) {
        const index = state.newContractCustomerCompanies.findIndex(
          (c) => c?.uuid === customer.uuid
        )
        state.newContractCustomerCompanies[index] = customer
      } else {
        const index = state.newRelativeCustomerCompanies.findIndex(
          (c) => c?.uuid === customer.uuid
        )
        state.newRelativeCustomerCompanies[index] = customer
      }
    },
    addNewRelativeCustomer(
      state,
      action: PayloadAction<AddNewRelativeCustomerProps>
    ): void {
      const {
        parentUuid,
        isAgentOfCustomerSpouse,
        isAgentOfMainCustomer,
        isSpouseOfMainCustomer,
        maritalStatus,
        placeShares,
        customerParentID,
      } = action.payload
      const relativeCustomers = state.newRelativeCustomers

      const oldSpouse = relativeCustomers?.find(
        (c) => parentUuid === c?.parentUuid && c?.isSpouseOfMainCustomer
      )
      const oldAgent = relativeCustomers.find(
        (c) => parentUuid === c?.parentUuid && c?.isAgentOfMainCustomer
      )
      const oldSpouseAgent = relativeCustomers.find(
        (c) => parentUuid === c?.parentUuid && c?.isAgentOfCustomerSpouse
      )
      if (
        (oldSpouse && isSpouseOfMainCustomer) ||
        (oldAgent && isAgentOfMainCustomer) ||
        (oldSpouseAgent && isAgentOfCustomerSpouse)
      ) {
        return
      }

      const randomRelativeCustomerId = -Math.floor(1 + Math.random() * 1000) // TODO generate safe number for relative customer
      state.newRelativeCustomers.push({
        ...defaultContractCustomer,
        id: randomRelativeCustomerId,
        uuid: uuidv4(),
        parentUuid,
        isSpouseOfMainCustomer,
        isAgentOfCustomerSpouse,
        isAgentOfMainCustomer,
        maritalStatus: maritalStatus || '',
        placeShares,
        customerParentID: customerParentID ?? null,
      })
    },
    addNewRelativeCustomerCompany(
      state,
      action: PayloadAction<AddNewRelativeCustomerCompanyProps>
    ): void {
      const {
        parentUuid,
        isAgentOfMainCustomer,
        isRepresentativePerson,
      } = action.payload
      const relativeCustomerCompanies = state.newRelativeCustomerCompanies

      const oldAgent = relativeCustomerCompanies.find(
        (c) => parentUuid === c?.parentUuid && c?.isAgentOfMainCustomer
      )

      if (oldAgent && isAgentOfMainCustomer) {
        return
      }

      const randomRelativeCustomerCompanyId = -Math.floor(
        1 + Math.random() * 1000
      ) // TODO generate safe number for relative customer
      state.newRelativeCustomerCompanies.push({
        ...defaultContractCustomerCompany,
        id: randomRelativeCustomerCompanyId,
        uuid: uuidv4(),
        parentUuid,
        isAgentOfMainCustomer,
        isRepresentativePerson,
        customerType: 'Podmiot gospodarczy',
      })
    },
    removeSpouse(state, action: PayloadAction<string>): void {
      const parentUuid = action.payload
      const spouseIndex = state.newRelativeCustomers?.findIndex(
        (customer) =>
          customer?.parentUuid === parentUuid &&
          customer?.isSpouseOfMainCustomer
      )
      if (spouseIndex !== -1) {
        state?.newRelativeCustomers?.splice(spouseIndex, 1)
      }
    },
    removeContractCustomer(state, action: PayloadAction<string>): void {
      const uuid = action.payload
      const removeIndex = state.newContractCustomers.findIndex(
        (customer) => customer.uuid === uuid
      )
      if (removeIndex !== -1) {
        state.newContractCustomers.splice(removeIndex, 1)
      }
    },
    removeContractCustomerCompany(state, action: PayloadAction<string>): void {
      const uuid = action.payload
      const removeIndex = state.newContractCustomerCompanies.findIndex(
        (contractCustomer) => contractCustomer.uuid === uuid
      )
      if (removeIndex !== -1) {
        state.newContractCustomerCompanies.splice(removeIndex, 1)
      }
    },
    removeRelativeCustomer(state, action: PayloadAction<string>): void {
      const uuid = action.payload
      const removeIndex = state.newRelativeCustomers.findIndex(
        (customer) => customer.uuid === uuid
      )
      const removeCustomerIndex = state.newContractCustomers.findIndex(
        (customer) => customer.uuid === uuid
      )
      if (removeIndex !== -1) {
        state.newRelativeCustomers.splice(removeIndex, 1)
      } else if (removeCustomerIndex !== -1) {
        state.newContractCustomers.splice(removeIndex, 1)
        const clearedRelativeCustomers = state.newRelativeCustomers.filter(
          (customer) => customer.parentUuid !== uuid
        )
        state.newRelativeCustomers = clearedRelativeCustomers
      }
    },
    removeRelativeCustomerCompany(state, action: PayloadAction<string>): void {
      const uuid = action.payload
      const removeIndex = state.newRelativeCustomerCompanies.findIndex(
        (contractCustomerCompany) => contractCustomerCompany.uuid === uuid
      )
      const removeCustomerIndex = state.newContractCustomerCompanies.findIndex(
        (customer) => customer.uuid === uuid
      )
      if (removeIndex !== -1) {
        const newRelativeCustomers = state.newRelativeCustomerCompanies.filter(
          (customer) => customer.uuid !== uuid
        )
        state.newRelativeCustomerCompanies = newRelativeCustomers
      } else if (removeCustomerIndex !== -1) {
        state.newContractCustomerCompanies.splice(removeIndex, 1)
        const clearedRelativeCustomerCompanies = state.newRelativeCustomerCompanies.filter(
          (customer) => customer.parentUuid !== uuid
        )
        state.newRelativeCustomerCompanies = clearedRelativeCustomerCompanies
      }
    },
    clearNewRelatives(state): void {
      state.newRelativeCustomers = []
      state.newRelativeCustomerCompanies = []
    },
    updateCustomerData(
      state,
      action: PayloadAction<{
        isRelative: boolean
        newCustomer: IContractCustomerInput
        index: number
      }>
    ): void {
      if (action.payload.isRelative) {
        state.newRelativeCustomers[action.payload.index] =
          action.payload.newCustomer
      } else {
        state.newContractCustomers[action.payload.index] =
          action.payload.newCustomer
      }
    },
    updateCustomerCompanyData(
      state,
      action: PayloadAction<{
        isRelative: boolean
        newCustomerCompany: IContractCustomerCompanyInput
        index: number
      }>
    ): void {
      if (action.payload.isRelative) {
        state.newRelativeCustomerCompanies[action.payload.index] =
          action.payload.newCustomerCompany
      } else {
        state.newContractCustomerCompanies[action.payload.index] =
          action.payload.newCustomerCompany
      }
    },
  },
  extraReducers: {
    [fetchInterestedCustomersByContractID.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.interestedCustomers = action.payload.customersByContractID
    },
    [fetchInterestedCustomersByContractID.rejected.toString()]: (
      state,
      _
    ): void => {
      state.interestedCustomers = []
    },
    [saveContract.fulfilled.toString()]: (state): void => {
      state.isSaveContractPending = false
    },
    [saveContract.pending.toString()]: (state): void => {
      state.isSaveContractPending = true
    },
    [saveContract.rejected.toString()]: (state): void => {
      state.isSaveContractPending = false
    },
    [saveCompanyContract.fulfilled.toString()]: (state): void => {
      state.isSaveCompanyContractPending = false
    },
    [saveCompanyContract.pending.toString()]: (state): void => {
      state.isSaveCompanyContractPending = true
    },
    [saveCompanyContract.rejected.toString()]: (state): void => {
      state.isSaveCompanyContractPending = false
    },
  },
})

export const {
  clearRegisterSales,
  addEmptyContractCustomer,
  addEmptyContractCustomerCompany,
  editContractCustomer,
  editContractCustomerCompany,
  addInterestedCustomerID,
  addNewRelativeCustomer,
  addNewRelativeCustomerCompany,
  setSelectedInterestedCustomerID,
  removeSpouse,
  removeContractCustomer,
  clearNewRelatives,
  removeRelativeCustomer,
  removeRelativeCustomerCompany,
  toggleIsBusinessRegisterProcess,
  updateCustomerData,
  updateCustomerCompanyData,
  clearInterestedCustomers,
  updateContractDealDate,
} = registerSales.actions
export default registerSales.reducer
