import { gql } from 'graphql-request'

import GraphqlService from './graphqlService'

import { INotesByContractIDParameters } from '../ducks/reminder'

export interface IContactInCustomersReminder {
  id: number
  date: string
  name: string
  type: string
  comment: string
  traderInitials: string
  nextContactName: string
  nextContactComment: string
  nextContactDate: string | null
}

export interface ICustomersReminder {
  id: number
  firstName: string
  lastName: string
  email: string
  phone1: string
  phone2: string
  contacts: IContactInCustomersReminder[]
}

export interface IPaginatedRemindersParameters {
  perPage: number
  page: number
}

export const getCustomersRemindersPaginated = (
  parameters: IPaginatedRemindersParameters
): Promise<any> => {
  const PAGINATED_FETCH_CUSTOMERS_REMINDERS = gql`
    query($perPage: Float, $page: Float) {
      paginateCustomersForReminder(perPage: $perPage, page: $page) {
        total
        customers {
          id
          firstName
          lastName
          email
          phone1
          phone2
          contacts {
            id
            date
            name
            type
            comment
            resignationReason
            traderInitials
            nextContactName
            nextContactComment
            nextContactDate
            nextContactType
            isSigned
            isResignation
          }
        }
      }
    }
  `

  return GraphqlService.send(PAGINATED_FETCH_CUSTOMERS_REMINDERS, parameters)
}

export const getCustomersByDailyCustomerReminder = (): Promise<any> => {
  const FETCH_CUSTOMERS_BY_DAILY_REMINDERS = gql`
    query {
      customersByDailyCustomerReminder {
        id
        firstName
        lastName
        email
        phone1
        phone2
        contacts {
          id
          date
          name
          type
          comment
          traderInitials
          nextContactName
          nextContactComment
          nextContactDate
          nextContactType
        }
      }
    }
  `

  return GraphqlService.send(FETCH_CUSTOMERS_BY_DAILY_REMINDERS, {})
}

export interface IUserNote {
  id: number
  content: string
  publicationDate: string
  reminderDate: string | null
  myTasks: boolean
  customerID: number | null
  contractID: number | null
  userID: number | null
}

export const getNotesByCustomerID = (customerID: number): Promise<any> => {
  const FETCH_NOTES_BY_CUSTOMER_ID = gql`
    query getNotes($customerID: Float!) {
      notesByCustomerID(customerID: $customerID) {
        id
        content
        contractID
        customerID
        isAfterMigration
        myTasks
        publicationDate
        reminderDate
        traderInitials
        userID
      }
    }
  `

  return GraphqlService.send(FETCH_NOTES_BY_CUSTOMER_ID, { customerID })
}

const fetchNotesByContractID = (
  parametes: INotesByContractIDParameters
): Promise<any> => {
  const FETCH_NOTES = gql`
    query getNotes($contractID: Float!, $isDARRole: Boolean) {
      notesByContractID(contractID: $contractID, isDARRole: $isDARRole) {
        id
        content
        publicationDate
        reminderDate
        myTasks
        customerID
        userID
        contractID
      }
    }
  `
  return GraphqlService.send(FETCH_NOTES, parametes)
}

export const getDARNotesByUserID = (userID: string): Promise<any> => {
  const FETCH_DAR_NOTES_BY_USER_ID = gql`
    query getNotes($userID: String!) {
      DARNotesByUserID(userID: $userID) {
        id
        content
        publicationDate
        reminderDate
        myTasks
        customerID
        isAfterMigration
        userID
        contractID
      }
    }
  `

  return GraphqlService.send(FETCH_DAR_NOTES_BY_USER_ID, { userID })
}

export const getNotesByUserID = (userID: string): Promise<any> => {
  const FETCH_NOTES_BY_USER_ID = gql`
    query getNotes($userID: String!) {
      notesByUserID(userID: $userID) {
        id
        content
        publicationDate
        reminderDate
        myTasks
        customerID
        isAfterMigration
        userID
        contractID
      }
    }
  `

  return GraphqlService.send(FETCH_NOTES_BY_USER_ID, { userID })
}

const getReminderPDFFile = (userToken: string): Promise<any> => {
  const FETCH_REMINDER_PDF = gql`
    query reminder($userToken: String!) {
      reminder(userToken: $userToken) {
        content
      }
    }
  `

  return GraphqlService.send(FETCH_REMINDER_PDF, { userToken })
}

export interface IAddNote {
  content: string
  publicationDate: string
  reminderDate: string | null
  myTasks: boolean
  userID: string
  customerID: number | null
  contractID: number | null
}

export interface IContractAddNote {
  content: string
  publicationDate: string
  reminderDate: string | null
  myTasks: boolean
  userID: string
  contractID: number | null
}

export interface IAddNoteForOtherUser extends IAddNote {
  traderInitials: string
}

export const addNote = (note: IAddNote): Promise<any> => {
  const ADD_NOTE = gql`
    mutation sNote($note: NoteInput!) {
      saveNote(note: $note) {
        id
        content
        publicationDate
        reminderDate
        myTasks
        userID
        customerID
        contractID
      }
    }
  `

  return GraphqlService.send(ADD_NOTE, { note })
}

export const addNoteForOtherUser = (note: IAddNoteForOtherUser): any => {
  const ADD_NOTE_FOR_OTHER_USER = gql`
    mutation sNote($note: NoteInput!) {
      saveNote(note: $note) {
        id
        content
        publicationDate
        reminderDate
        myTasks
        userID
        customerID
        traderInitials
        contractID
      }
    }
  `

  return GraphqlService.send(ADD_NOTE_FOR_OTHER_USER, { note })
}

export interface INote extends IAddNote {
  id: number
}

export interface IUpdateNote {
  noteID: number
  note: IAddNote | IContractAddNote
}

export const updateNote = (parameters: IUpdateNote): Promise<any> => {
  const UPDATE_NOTE = gql`
    mutation updateNote($note: NoteInput!, $noteID: Float!) {
      updateNote(note: $note, noteID: $noteID) {
        id
      }
    }
  `

  return GraphqlService.send(UPDATE_NOTE, parameters)
}

export const deleteNote = (noteID: number): Promise<any> => {
  const DELETE_NOTE = gql`
    mutation deleteNode($noteID: Float!) {
      deleteNote(noteID: $noteID)
    }
  `

  return GraphqlService.send(DELETE_NOTE, { noteID })
}

export const getUserAlertsByDate = (date: string): Promise<any> => {
  const FETCH_USER_ALERTS_BY_DATE = gql`
    query getAlerts($date: String!) {
      alertsByDateAndUser(date: $date) {
        id
        comment
        traderInitials
        date
        alertType
        customer {
          lastContactDate
          firstName
          lastName
          phone1
          email
        }
        unassignedCustomer {
          firstName
          lastName
          phone
          email
          creationDate
          customer {
            lastContactDate
          }
        }
      }
    }
  `

  return GraphqlService.send(FETCH_USER_ALERTS_BY_DATE, { date })
}

export interface IUserAlert {
  id: number
  comment: string
  traderInitials: string
  date: string
  customer?: {
    firstName: string
    lastName: string
    email: string
    phone1: string
    lastContactDate?: string
  }
  unassignedCustomer?: {
    firstName: string
    lastName: string
    email: string
    phone: string
    customer?: {
      lastContactDate?: string
    }
  }
  alertType: string
}

export const getMessageAlertsAndDelete = (): Promise<any> => {
  const MUTATION = gql`
    mutation getMessageAlerts {
      getMessageAlertsAndDelete {
        id
        email
      }
    }
  `

  return GraphqlService.send(MUTATION, {})
}

const mutations = {
  getCustomersByDailyCustomerReminder,
  getCustomersRemindersPaginated,
  getNotesByCustomerID,
  getDARNotesByUserID,
  getNotesByUserID,
  getReminderPDFFile,
  addNote,
  addNoteForOtherUser,
  updateNote,
  deleteNote,
  fetchNotesByContractID,
  getUserAlertsByDate,
  getMessageAlertsAndDelete,
}

export default mutations
