import React, { FunctionComponent, useCallback, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { IInterestedCustomer } from '../../graphql/customers'
import { errorToastNotify, successToastNotify } from '../commons/Toast/Toast'
import SimpleInputList from '../commons/Inputs/SimpleInputs/SimpleInputList'

import { getInterestedCustomerByID } from '../../ducks/interestedCustomers'

import InfoDutyForms from '../../mockups/InfoDutyForms'
import { useMarketingConsentsReducer } from '../SalesRegister/CustomerMarketingConsentsContext'
import SimpleInputCheckbox3 from '../commons/Inputs/SimpleInputs/SimpleInputCheckbox3'
import SimpleInputDate3 from '../commons/Inputs/SimpleInputs/SimpleInputDate3'
import { updateMarketingConsent } from '../../ducks/marketingConsents'
import { AppDispatch } from '../../ducks'

interface IInterestedCustomerMarketingConsentsProps {
  customer: IInterestedCustomer
}

export const InterestedCustomerMarketingConsents: FunctionComponent<IInterestedCustomerMarketingConsentsProps> = ({
  customer,
}) => {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const [isActiveEditMode, setIsActiveEditMode] = useState(false)

  const customerHasMarketingConsent = customer.marketingConsents.length
  const customersFirstMarketingConsent = customerHasMarketingConsent
    ? customer.marketingConsents[0]
    : undefined

  const { state, updateContextFromInput } = useMarketingConsentsReducer(
    customersFirstMarketingConsent
  )

  const {
    isInfoDutyFulfilled,
    infoDutyForm,
    infoDutyFormDate,
    agreementEmailCancellationDate,
    agreementEmailDate,
    agreementPhoneCancellationDate,
    agreementPhoneDate,
    isAgreementEmail,
    isAgreementEmailCancelled,
    isAgreementPhone,
    isAgreementPhoneCancelled,
    isMSWiAAgreement,
  } = state.data

  const onSubmitForm = useCallback(
    (e: any): void => {
      e.preventDefault()
      const { id: marketingConsentID, ...marketingConsents } = state.data

      dispatch(
        updateMarketingConsent({
          marketingConsentID,
          marketingConsent: {
            ...marketingConsents,
            customerID: customer.id,
          } as any, // TODO Fix marketingConsents interfaces
        })
      ).then((res: any) => {
        if (res.error) {
          errorToastNotify(String(t('toast:editDataError')))
        } else {
          dispatch(getInterestedCustomerByID(customer.id))
          successToastNotify(String(t('toast:editData')))
          setIsActiveEditMode(false)
        }
      })
    },
    [state.data, customer.id, dispatch, t]
  )

  return (
    <div className='d-flex align-items-start justify-content-center row'>
      <Col lg={11} style={{ marginLeft: '4%', marginRight: '4%' }}>
        <Form onSubmit={onSubmitForm} className='w-100'>
          <Row className='d-flex justify-content-center w-100'>
            <SimpleInputCheckbox3
              name='isInfoDutyFulfilled'
              value={isInfoDutyFulfilled}
              disabled={!isActiveEditMode}
              onChange={updateContextFromInput}
              label={String(t('place:labels.information-duty'))}
            />
            {isInfoDutyFulfilled && (
              <>
                <SimpleInputDate3
                  name='infoDutyFormDate'
                  defaultValue={infoDutyFormDate || ''}
                  additionalOnChange={updateContextFromInput}
                  disabled={!isActiveEditMode}
                  label={String(t('place:labels.information-duty-date'))}
                />
                <SimpleInputList
                  name='infoDutyForm'
                  options={InfoDutyForms}
                  defaultValue={infoDutyForm}
                  additionalOnChange={updateContextFromInput}
                  disabled={!isActiveEditMode}
                  label={String(t('place:labels.information-duty-form'))}
                />
              </>
            )}
          </Row>
          <Row className='d-flex justify-content-center w-100'>
            <SimpleInputCheckbox3
              name='isAgreementPhone'
              value={isAgreementPhone}
              onChange={updateContextFromInput}
              disabled={!isActiveEditMode}
              label={String(t('place:labels.agreement-phone'))}
            />
            {isAgreementPhone && (
              <>
                <SimpleInputDate3
                  name='agreementPhoneDate'
                  disabled={!isActiveEditMode}
                  defaultValue={agreementPhoneDate || ''}
                  additionalOnChange={updateContextFromInput}
                  label='Data zgody (tel.)'
                />
                <SimpleInputCheckbox3
                  name='isAgreementPhoneCancelled'
                  value={isAgreementPhoneCancelled}
                  disabled={!isActiveEditMode}
                  onChange={updateContextFromInput}
                  label={String(t('place:labels.cancelled-agreement-phone'))}
                />
                {isAgreementPhoneCancelled ? (
                  <SimpleInputDate3
                    name='agreementPhoneCancellationDate'
                    defaultValue={agreementPhoneCancellationDate || ''}
                    additionalOnChange={updateContextFromInput}
                    disabled={!isActiveEditMode}
                    label='Data wycofania'
                  />
                ) : null}
              </>
            )}
          </Row>
          <Row className='d-flex justify-content-center w-100'>
            <SimpleInputCheckbox3
              name='isAgreementEmail'
              value={isAgreementEmail}
              onChange={updateContextFromInput}
              label={String(t('place:labels.agreement-email'))}
              disabled={!isActiveEditMode}
            />
            {isAgreementEmail && (
              <>
                <SimpleInputDate3
                  name='agreementEmailDate'
                  defaultValue={agreementEmailDate || ''}
                  additionalOnChange={updateContextFromInput}
                  disabled={!isActiveEditMode}
                  label='Data zgody (email.)'
                />
                <SimpleInputCheckbox3
                  name='isAgreementEmailCancelled'
                  value={isAgreementEmailCancelled}
                  onChange={updateContextFromInput}
                  disabled={!isActiveEditMode}
                  label={String(t('place:labels.cancelled-agreement-email'))}
                />
                {isAgreementEmailCancelled ? (
                  <SimpleInputDate3
                    name='agreementEmailCancellationDate'
                    defaultValue={agreementEmailCancellationDate || ''}
                    additionalOnChange={updateContextFromInput}
                    label='Data wycofania'
                    disabled={!isActiveEditMode}
                  />
                ) : null}
              </>
            )}
          </Row>
          <Row className='d-flex justify-content-center w-100'>
            <SimpleInputCheckbox3
              name='isMSWiAAgreement'
              value={isMSWiAAgreement}
              onChange={updateContextFromInput}
              label='Zgoda MSWiA'
              disabled={!isActiveEditMode}
            />
          </Row>
          <Row className='d-flex justify-content-center'>
            {!isActiveEditMode && (
              <Button onClick={(): void => setIsActiveEditMode(true)}>
                {t('commons:actions.edit')}
              </Button>
            )}
            {isActiveEditMode && (
              <Button type='submit'>{t('commons:actions.update-data')}</Button>
            )}
          </Row>
        </Form>
      </Col>
    </div>
  )
}

export default InterestedCustomerMarketingConsents
