import React, { FunctionComponent, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import MainButton from '../../commons/Inputs/MainButton'

interface ITradersModalProps {
  cancelAction: () => void
  submitAction?: (traderInitials: string) => void
  show: boolean
  traders: { id: string; initials: string }[]
  selectedTrader?: string
}

const TradersModal: FunctionComponent<ITradersModalProps> = (props) => {
  const { t } = useTranslation()
  const { cancelAction, submitAction, traders, show, selectedTrader } = props
  const [selectedUser, setSelectedUser] = useState<string | undefined>(
    undefined
  )

  return (
    <Modal show={show} onHide={cancelAction}>
      <Modal.Header closeButton>
        <Modal.Title>{t('commons:actions:select-trader')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {traders.map((trader, index) => (
          <div
            key={index}
            onClick={() =>
              selectedTrader !== trader.initials &&
              setSelectedUser(trader.initials)
            }
            style={{
              backgroundColor:
                selectedTrader === trader.initials
                  ? '#d4d4d4'
                  : selectedUser === trader.initials
                  ? '#E3F2FD'
                  : 'white',
              cursor: 'pointer',
              width: 'auto',
              border: '1px solid grey',
              marginBottom: '8px',
              padding: '6px',
              minHeight: ' 40px',
            }}
          >
            {trader.initials}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={cancelAction}>
          {t('commons:actions:return')}
        </Button>
        {submitAction && (
          <MainButton
            onClick={() =>
              selectedUser ? submitAction(String(selectedUser)) : {}
            }
            disabled={!selectedUser}
          >
            {t('commons:actions:confirm')}
          </MainButton>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default TradersModal
