import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import ParkingPlaceService from '../services/parkingPlaceService'
import { RootState } from './index'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'
import { IGarageGroundPlanFile } from '../services/garagesService'

import { IInvestmentNames } from '../hooks/useGetInvestmentNames'

export interface IParkingPlace {
  id: number
  name: string
  type: string
  comment?: string | undefined
  isExternal: boolean
  garageID?: number | null
  placeID?: number | null
  contractID?: number | null
  isTypeService: boolean
  building?: {
    id: number
    name: string
  }
  garage?: {
    id: number
    name: string
  }
  utilityRoom?: {
    number?: string
    id?: number
  }
}

export interface IParkingPlaceInput {
  name: string
  type: string
  comment?: string | undefined
  isExternal: boolean
  garageID?: number | null
  placeID?: number | null
  contractID?: number | null
  isTypeService: boolean
  buildingID?: number | null
  isAfterMigration?: boolean
  building?: {
    id: number
    name: string
  }
  garage?: {
    id: number
    name: string
    groundPlans: IGarageGroundPlanFile[]
  }
}

interface IParkingPlacesStore {
  areParkingPlacesOnContractLoading: boolean
  areParkingPlacesToPlaceLoading: boolean
  parkingPlaces: IParkingPlace[]
  allParkingPlaces: IParkingPlace[]
  parkingPlacesLinkedToPlace: IParkingPlace[]
  parkingPlacesLinkedToContract: IParkingPlace[]
  unassignedParkingPlaces: IParkingPlace[]
}

const initialState: IParkingPlacesStore = {
  areParkingPlacesOnContractLoading: false,
  areParkingPlacesToPlaceLoading: false,
  parkingPlaces: [],
  allParkingPlaces: [],
  parkingPlacesLinkedToPlace: [],
  parkingPlacesLinkedToContract: [],
  unassignedParkingPlaces: [],
}

export const getUnassignedParkingPlacesMinusAdded = createSelector(
  (state: RootState) => state.parkingPlaces,
  (parkingPlaces) => {
    return parkingPlaces.unassignedParkingPlaces.filter(
      (unassigned) => !unassigned.contractID && !unassigned?.utilityRoom?.id
    )
  }
)

export const getAllParkingPlaces = createAsyncThunk(
  'parkingPlaces/getAllParkingPlaces',
  async () => {
    return ParkingPlaceService.getAllParkingPlaces()
  }
)

export const getContractConnectedToParkingPlace = createAsyncThunk(
  'parkingPlaces/getContractConnectedToParkingPlace',
  async (contractID: number) => {
    return ParkingPlaceService.getContractConnectedToParkingPlace(contractID)
  }
)

export const getParkingPlacesByInvestmentNames = createAsyncThunk(
  'parkingPlaces/getParkingPlacesByInvestmentNames',
  async ({ investmentNames, stagesIDs }: IInvestmentNames) => {
    return ParkingPlaceService.getParkingPlacesByInvestmentNames({
      investmentNames,
      stagesIDs,
    })
  }
)

export const getParkingPlacesByInvestmentStageId = createAsyncThunk(
  'parkingPlaces/getParkingPlacesByInvestmentStageId',
  async (stageID: number) => {
    return ParkingPlaceService.getParkingPlacesByInvestmentStageId(stageID)
  }
)

export const addNewParkingPlace = createAsyncThunk(
  'parkingPlaces/addNewParkingPlace',
  async (variables: { parkingPlace: IParkingPlaceInput }) => {
    return ParkingPlaceService.addNewParkingPlace(variables)
  }
)

export const updateParkingPlace = createAsyncThunk(
  'parkingPlaces/updateParkingPlace',
  async (variables: {
    parkingPlace: IParkingPlaceInput
    parkingPlaceID: number
  }) => {
    return ParkingPlaceService.updateParkingPlace(variables)
  }
)

export const removeParkingPlace = createAsyncThunk(
  'parkingPlaces/removeParkingPlace',
  async (parkingPlaceID: number) => {
    return ParkingPlaceService.removeParkingPlace(parkingPlaceID)
  }
)

export const getParkingPlacesLinkedToContract = createAsyncThunk(
  'parkingPlaces/getParkingPlacesLinkedToContract',
  async (variables: { contractID: number }) => {
    return {
      parkingPlaces: (await ParkingPlaceService.getParkingPlacesByContract({
        contractID: variables.contractID,
      })) as IParkingPlace[],
    }
  }
)

export const getParkingPlacesLinkedToPlace = createAsyncThunk(
  'parkingPlaces/getParkingPlacesLinkedToPlace',
  async (variables: { placeID: number }) => {
    return {
      parkingPlaces: await ParkingPlaceService.getParkingPlacesByPlace({
        placeID: variables.placeID,
      }),
    }
  }
)

export const getUnassignedParkingPlaces = createAsyncThunk(
  'parkingPlaces/getUnassignedParkingPlaces',
  async (variables: IInvestmentNames) => {
    return {
      parkingPlaces: (await ParkingPlaceService.getParkingPlacesByInvestmentNames(
        {
          investmentNames: variables.investmentNames,
          stagesIDs: variables.stagesIDs,
        }
      )) as IParkingPlace[],
    }
  }
)

const parkingPlacesSlice = createSlice({
  name: 'parkingPlaces',
  initialState,
  reducers: {
    addParkingPlaceToContract(
      state,
      action: PayloadAction<IParkingPlace>
    ): void {
      state.parkingPlacesLinkedToContract.push(action.payload)
    },
    removeParkingPlaceFromContract(state, action: PayloadAction<number>): void {
      const id = action.payload
      const foundIndex = state.parkingPlacesLinkedToContract.findIndex(
        (room) => room.id === id
      )
      if (foundIndex !== -1) {
        state.parkingPlacesLinkedToContract.splice(foundIndex, 1)
      }
    },
    clearLinkedParkingPlaces(state): void {
      state.parkingPlacesLinkedToContract = []
      state.parkingPlacesLinkedToPlace = []
    },
  },
  extraReducers: {
    [getAllParkingPlaces.fulfilled.toString()]: (state, action): void => {
      state.allParkingPlaces = action.payload.parkingPlaces
      state.areParkingPlacesOnContractLoading = false
      state.areParkingPlacesToPlaceLoading = false
    },
    [getAllParkingPlaces.pending.toString()]: (state): void => {
      state.areParkingPlacesOnContractLoading = true
      state.areParkingPlacesToPlaceLoading = true
    },
    [getAllParkingPlaces.rejected.toString()]: (state): void => {
      state.areParkingPlacesOnContractLoading = false
      state.areParkingPlacesToPlaceLoading = false
    },
    [getParkingPlacesByInvestmentNames.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.parkingPlaces =
        action.payload.parkingPlacesByInvestmentsNamesThroughGarage
      state.areParkingPlacesOnContractLoading = false
      state.areParkingPlacesToPlaceLoading = false
    },
    [getParkingPlacesByInvestmentNames.pending.toString()]: (state): void => {
      state.areParkingPlacesOnContractLoading = true
      state.areParkingPlacesToPlaceLoading = true
    },
    [getParkingPlacesByInvestmentNames.rejected.toString()]: (state): void => {
      state.parkingPlaces = []
      state.areParkingPlacesOnContractLoading = false
      state.areParkingPlacesToPlaceLoading = false
    },
    [getParkingPlacesLinkedToContract.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.parkingPlacesLinkedToContract = action.payload.parkingPlaces
        .parkingPlacesByContract as IParkingPlace[]
      state.areParkingPlacesOnContractLoading = false
    },
    [getParkingPlacesLinkedToContract.rejected.toString()]: (
      state,
      _
    ): void => {
      state.areParkingPlacesOnContractLoading = true
    },
    [getParkingPlacesLinkedToContract.rejected.toString()]: (
      state,
      _
    ): void => {
      state.parkingPlacesLinkedToContract = []
      state.areParkingPlacesOnContractLoading = false
    },
    [getParkingPlacesLinkedToPlace.fulfilled.toString()]: (
      state,
      action
    ): void => {
      const pp = action.payload.parkingPlaces
        .parkingPlacesByPlace as IParkingPlace[]
      state.parkingPlacesLinkedToPlace = pp
      state.areParkingPlacesToPlaceLoading = false
      //if parkingPlacesLinkedToPlace is not empty there should not be any parkingPlaces linked to Contract! only 'either - or'
      if (pp.length > 0) {
        state.parkingPlacesLinkedToContract = []
      }
    },
    [getParkingPlacesLinkedToPlace.pending.toString()]: (state, _): void => {
      state.areParkingPlacesToPlaceLoading = true
    },
    [getParkingPlacesLinkedToPlace.rejected.toString()]: (state, _): void => {
      state.parkingPlacesLinkedToPlace = []
      state.areParkingPlacesToPlaceLoading = false
    },
    [getUnassignedParkingPlaces.fulfilled.toString()]: (
      state,
      action
    ): void => {
      const possibleParkingPlaces =
        action.payload.parkingPlaces
          .parkingPlacesByInvestmentsNamesThroughGarage ?? []
      const rawParkingPlaces = possibleParkingPlaces.filter(
        (room: IParkingPlace) => room.contractID == null
      ) as IParkingPlace[]

      const filteredParkingPlaces = rawParkingPlaces.filter(
        (room: IParkingPlace) => room.placeID == null
      ) // Business logic - unassigned places dont' have a place
      state.unassignedParkingPlaces = filteredParkingPlaces
      state.areParkingPlacesOnContractLoading = false
      state.areParkingPlacesToPlaceLoading = false
    },
    [getUnassignedParkingPlaces.pending.toString()]: (state): void => {
      state.areParkingPlacesOnContractLoading = true
      state.areParkingPlacesToPlaceLoading = true
    },

    [getUnassignedParkingPlaces.rejected.toString()]: (state): void => {
      state.areParkingPlacesOnContractLoading = false
      state.areParkingPlacesToPlaceLoading = false
    },
    [getParkingPlacesByInvestmentStageId.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.parkingPlaces = action.payload.parkingPlaceByStages
      state.areParkingPlacesOnContractLoading = false
      state.areParkingPlacesToPlaceLoading = false
    },
    [getParkingPlacesByInvestmentStageId.pending.toString()]: (state): void => {
      state.areParkingPlacesOnContractLoading = true
      state.areParkingPlacesToPlaceLoading = true
    },
    [getParkingPlacesByInvestmentStageId.rejected.toString()]: (
      state
    ): void => {
      state.parkingPlaces = []
      state.areParkingPlacesOnContractLoading = false
      state.areParkingPlacesToPlaceLoading = false
    },
    [addNewParkingPlace.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:addParkingPlace')))
    },
    [addNewParkingPlace.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:addParkingPlaceError')))
    },
    [removeParkingPlace.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:deleteParkingPlace')))
    },
    [removeParkingPlace.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:deleteParkingPlaceError')))
    },
    [updateParkingPlace.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:editParkingPlace')))
    },
    [updateParkingPlace.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:editParkingPlaceError')))
    },
  },
})

export const {
  addParkingPlaceToContract,
  removeParkingPlaceFromContract,
  clearLinkedParkingPlaces,
} = parkingPlacesSlice.actions

export default parkingPlacesSlice.reducer
