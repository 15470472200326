import { IListItem } from '../interfaces/commons'

export const resignationReasonsIDName: IListItem[] = [
  { id: 0, name: ' ' },
  { id: 1, name: 'Brak zdolności kredytowej' },
  { id: 2, name: 'Brak finansowania własnego' },
  { id: 3, name: 'Lepiej dopasowana oferta do potrzeb Klienta' },
  { id: 4, name: 'Niższa cena' },
  { id: 5, name: 'Nieakceptowalny poziom cenowy' },
  { id: 6, name: 'Niesatysfakcjonujący upust' },
  { id: 7, name: 'Wzrost cen w trakcie rozmów' },
  { id: 8, name: 'Zmiana sytuacji życiowej klienta' },
  { id: 9, name: 'Brak możliwości wprowadzenia zmian' },
  { id: 10, name: 'Brak możliwości połączenia' },
  { id: 11, name: 'Koszt zmian aranżacyjnych' },
  { id: 12, name: 'Brak wykończenia pod klucz' },
  { id: 13, name: 'Brak lokalu spełniającego wymagania Klienta' },
  { id: 14, name: 'Za szybkie terminy płatności rat' },
  { id: 15, name: 'Za późny termin odbioru lokalu' },
  { id: 16, name: 'Za późny termin umowy ostatecznej' },
  { id: 17, name: 'Miejsca postojowe' },
  { id: 18, name: 'Zapisy umowne' },
  { id: 19, name: 'Uciążliwość prac budowlanych w sąsiedztwie' },
  { id: 20, name: 'Opinie o inwestycji lub inwestorze' },
]
