import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AppLoader from '../Loader/Loader'
import { useTableContext } from './TableContext'
import {
  setStopMaintainingRowClick,
  setStraightAfterFilter,
} from '../../commons/Table2/paginationSlice'

import '../Table/Table.scss'

import { RootState } from '../../../ducks'
import { setMainTableRowIndex } from '../../../ducks/persistSettings'
import { useEffect } from 'react'

export type TableBodyProps = {
  elementOnExpand?: JSX.Element
  expandOnRowClick?: boolean
  noSelectRows?: boolean
  onRowClick?: (arg: any) => void
  onSelectRow?: (arg: any) => void
  onTableClick?: any
  timeout?: number
  orderRows?: boolean
  isLoading?: boolean
  setChosenRowIndex?: (arg: number) => void
  isForcedFullHeight?: boolean
  getCellStyle?: (row: any, cell: any) => {}
  getRowStyle?: (row: any, nextRow?: any) => {}
  getActionCell?: (row: any) => {}
  defaultRowSelectedId?: number
}

const getCustomTableHeight = (
  numberOfItems: number,
  pageSize: number,
  totalRows: number,
  isForcedFullHeight: boolean
): string => {
  const numberOfRows = totalRows || numberOfItems
  if (!isForcedFullHeight && numberOfRows < pageSize && numberOfRows < 10) {
    return 'auto'
  }
  const customHeight = isForcedFullHeight
    ? '620px'
    : pageSize === 5
    ? '390px'
    : '490px'
  return customHeight
}

const TableBody: React.FC<TableBodyProps> = ({
  noSelectRows = false,
  elementOnExpand,
  expandOnRowClick,
  onRowClick = (): void => {},
  onSelectRow,
  onTableClick = (): void => {},
  timeout,
  orderRows,
  isLoading,
  setChosenRowIndex = (): void => {},
  isForcedFullHeight = false,
  getCellStyle = (): void => {},
  getRowStyle = (): void => {},
  getActionCell,
  defaultRowSelectedId,
}) => {
  const {
    headerGroups,
    getTableProps,
    data,
    page,
    prepareRow,
    getTableBodyProps,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageSize, selectedRowIds },
    customLoading,
    totalRows,
    isMainTable,
  } = useTableContext()
  const dispatch = useDispatch()

  const { isMaintainRowClickStopped } = useSelector(
    (state: RootState) => state.pagination
  )
  const { mainTableSelectedRowIndex } = useSelector(
    (state: RootState) => state.persistSettings
  )

  const selectRow = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      row: any,
      index: number,
      isActionCell?: boolean
    ): void => {
      if (noSelectRows) {
        return
      }
      if (!isActionCell || !row.isSelected) {
        if (onSelectRow) {
          onSelectRow({ ...row })
          row.isSelected && row.toggleRowSelected()
        } else {
          onRowClick({ ...row.original })
          isMaintainRowClickStopped &&
            dispatch(setStopMaintainingRowClick(false))
          setChosenRowIndex(index)
          if (isMainTable) {
            mainTableSelectedRowIndex === index
              ? dispatch(setMainTableRowIndex(null))
              : dispatch(setMainTableRowIndex(index))
          }
          dispatch(setStraightAfterFilter(false))
          if (selectedFlatRows.length > 1) {
            toggleAllRowsSelected(false)
          }
          !noSelectRows &&
            page.map((row) => row.isSelected && row.toggleRowSelected())
          !noSelectRows && !row.isSelected && row.toggleRowSelected()
        }
      }
    },
    [
      isMainTable,
      noSelectRows,
      toggleAllRowsSelected,
      page,
      onRowClick,
      selectedFlatRows.length,
      dispatch,
      setChosenRowIndex,
      isMaintainRowClickStopped,
      mainTableSelectedRowIndex,
    ]
  )

  const { mainTableCurrentPage } = useSelector(
    (state: RootState) => state.pagination
  )
  const sizeOfPage = pageSize ?? totalRows
  const customHeight = React.useMemo(
    () =>
      getCustomTableHeight(
        data.length,
        sizeOfPage,
        totalRows,
        isForcedFullHeight
      ),
    [data.length, isForcedFullHeight, sizeOfPage, totalRows]
  )

  // if (isLoading && !customLoading) {
  //   throw new Error(
  //     'Add customLoading prop to Table component if you want to use isLoading prop'
  //   )
  // }

  useEffect(() => {
    if (customLoading && isLoading === false && data?.length > 0) {
      const selectedRow = document.getElementById('scrollIntoRow')
      selectedRow && selectedRow.scrollIntoView()
    }
  }, [customLoading, isLoading, data])

  useEffect(() => {
    if (defaultRowSelectedId && page.length) {
      const selectedRow = page.filter(
        (row: any) => row.original.id == defaultRowSelectedId
      )?.[0]
      toggleAllRowsSelected(false)
      selectedRow && selectedRow.toggleRowSelected()
    }
  }, [defaultRowSelectedId, page])

  const isScrollIntoRow = (index: number) => {
    const selectedRowIndex = Number(mainTableSelectedRowIndex)
    return selectedRowIndex > 1 && selectedRowIndex - 2 === index
  }

  return (
    <div
      {...getTableProps()}
      className='table sticky'
      style={{ backgroundColor: '#f2f2f2', height: customHeight }}
    >
      <div className='header'>
        {headerGroups.map((headerGroup, index) => (
          <div
            {...headerGroup.getHeaderGroupProps()}
            className='tr'
            key={'headerGroup' + index}
          >
            {headerGroup.headers.map((column: any, subIndex) => (
              <div
                {...column.getHeaderProps(
                  column.getSortByToggleProps({ title: column.fullName })
                )}
                className='th'
                key={'header' + subIndex}
              >
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
      </div>
      {(customLoading ? isLoading : !data?.length) ? (
        <div className='d-flex justify-content-center w-100'>
          {!isLoading && data?.length === 0 ? (
            <div style={{ padding: 30 }}>Brak danych do wyświetlenia</div>
          ) : (
            <AppLoader timeout={timeout} />
          )}
        </div>
      ) : (
        <div {...getTableBodyProps()} className='body' onClick={onTableClick}>
          {page.map((row, index: number) => {
            prepareRow(row)
            return (
              <div
                key={`row-with-expaneded${index}`}
                id={isScrollIntoRow(index) ? 'scrollIntoRow' : undefined}
              >
                <div {...row.getRowProps()} className='tr'>
                  {row.cells.map((cell, subIndex) => {
                    const style = {
                      ...cell.getCellProps().style,
                      ...getCellStyle(row, cell),
                      ...getRowStyle(row, page[index + 1]),
                    }
                    return (
                      <div
                        {...cell.getCellProps()}
                        style={style}
                        className={`td ${row.isSelected ? 'rowSelected' : ''}`}
                        onClick={(e): void =>
                          selectRow(e, row, index, cell.column.id === 'actions')
                        }
                        key={'row' + index + 'cell' + subIndex}
                      >
                        {orderRows && cell.column.Header === 'L.p.' ? (
                          <div className='tr'>
                            {(mainTableCurrentPage - 1) * sizeOfPage +
                              index +
                              1}
                          </div>
                        ) : getActionCell && cell.column.id === 'actions' ? (
                          getActionCell(row)
                        ) : (
                          cell.render('Cell')
                        )}
                      </div>
                    )
                  })}
                </div>
                {expandOnRowClick && row.isSelected ? (
                  <div {...row.getRowProps()} className='tr'>
                    {elementOnExpand}
                  </div>
                ) : null}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
export default TableBody
