import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchNotesByCustomerID } from '../../../ducks/reminder'
import { AppDispatch, RootState } from '../../../ducks'
import { NotesTableComponent } from './NotesTableComponent'
import { IInterestedCustomer } from '../../../graphql/customers'

import { InterestedCustomersTableInCustomerDataEditRoles } from '../../../ducks/globalUser'
import { hasRoles } from '../../../services/idp'

interface NotesTableForInterestedCustomers {
  customer: IInterestedCustomer
}

export const NotesTableForInterestedCustomers: React.FC<NotesTableForInterestedCustomers> = ({
  customer,
}) => {
  const dispatch: AppDispatch = useDispatch()
  const { roles } = useSelector((state: RootState) => state.globalUser)
  const { notesByCustomerID } = useSelector(
    (state: RootState) => state.reminder
  )

  const customerID = customer?.id || 0
  const canEdit = hasRoles(
    InterestedCustomersTableInCustomerDataEditRoles,
    roles
  )

  const refetchAction = React.useCallback((): void => {
    dispatch(fetchNotesByCustomerID(customerID))
  }, [customerID, dispatch])

  return (
    <NotesTableComponent
      canEdit={canEdit}
      notes={notesByCustomerID}
      fetchAction={refetchAction}
      customer={customer}
    />
  )
}
