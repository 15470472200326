import React, { FunctionComponent } from 'react'
import './Reports.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import { fetchDKOExecutionStatusReport } from '../../ducks/reports'
import { ReportWithList } from './ReportWithList'

const DKOExecutionStatusReport: FunctionComponent = () => {
  const { isLoading } = useSelector(
    (state: RootState) => state.combinedReports.DKOExecutionStatusReport
  )

  return (
    <ReportWithList
      isLoading={isLoading}
      reportAPIName='DKOExecutionStatusReport'
      reportName='Status-wykonania.xlsx'
      reduxAction={fetchDKOExecutionStatusReport}
    />
  )
}

export default DKOExecutionStatusReport
