import { gql } from 'graphql-request'
import { IParkingPlace } from '../graphql/parkingPlaces'
import { IUtilityRoom } from '../graphql/utilityRooms'
import { IArrangement } from './arrangementsService'
import { IGarageGroundPlanInput } from './garagesService'
import GraphqlService from './graphqlService'
import { GenericPaginationProps } from './interestedCustomerService'

export interface IPlaceGroundPlan {
  id: number
  name: string
  length: number
  category: string
  created: string
  description: string
  content: string
  placeID?: number
  isAfterMigration?: boolean
  migrationComment?: string
}

export interface IPlaceGroundPlanInput {
  name: string
  length: number
  category: string
  created: string
  description: string
  content: string
  placeID?: number
}

export interface IAdminPlace {
  id: number
  buildingID: number
  staircase: number
  floor: number
  flatNumber: string
  designedUsablePropertyArea: number
  designedInnerPropertyArea: number
  asBuildUsablePropertyArea: number
  asBuildInnerPropertyArea: number
  roomCount: number
  isTypeService: boolean
  isAfterMigration?: boolean
  isYslabAvailable: boolean | null
  contracts: {
    id: number
    contractNo: string
  }[]
  administrativeAddress?: string
  administrativeCode1: string
  administrativeCode2: string
  placeCode: string
  landStripCode: string
  landStripDescription: string
  parkingPlaces: IParkingPlace[]
  utilityRooms: IUtilityRoom[]
  landStrips?: {
    id: number
    name: string
  }
  building: {
    id: number
    isTypeService: boolean
    name: string
    nameIsNotVisible?: boolean
  }
  stage: {
    id: number
    name: string
    street: string
  }
  investment: {
    id: number
    name: string
  }
  activeContracts?: {
    id: number
    contractNo: string
  }[]
  cancelledContracts?: {
    id: number
    contractNo: string
  }[]
  archivedContracts?: {
    id: number
    contractNo: string
  }[]
  arrangement?: IArrangement
  groundPlan?: IPlaceGroundPlan
}

export interface IPlaceInput {
  buildingID: number
  staircase: number
  floor: number
  flatNumber: string
  designedInnerPropertyArea: number
  designedUsablePropertyArea: number
  asBuildInnerPropertyArea: number
  asBuildUsablePropertyArea: number
  roomCount: number
  isTypeService: boolean
  administrativeCode1: string
  placeCode: string
  landStripCode: string
}

export interface IGroundPlanPlaceInput {
  placeID: number
  name: string
  length: number
  category: string
  created: string
  description: string
  content: string
  isAfterMigration?: boolean
}

export interface PlacesPaginationProps extends GenericPaginationProps {
  investmentNames?: string[] | null
  stageID?: number
  keyword?: string
  showArchived: boolean
}

export interface PlacesByInvestmentsNamesForAdminPanel
  extends GenericPaginationProps {
  investmentsNames: string[] | null
  stageID: number | null
  keywords: string
}

/* for main view we NEED TO FETCH fields, as groundPlans for places and garages in buildings,
  the data for table, activeContracts[0].id, contractCustomers and contractCompanyCustomers */

export const getPlacesByInvestmentNameForMainTableActiveContracts = (
  props: PlacesPaginationProps
): Promise<any> => {
  const FETCH_PLACES_BY_INVESTMENT_NAME_FOR_MAIN_TABLE = gql`
    query(
      $investmentsNames: [String!]
      $page: Float
      $perPage: Float
      $stageID: Float
      $isTypeService: Boolean
      $keyword: String
      $sortField: String
      $sortOrder: Boolean
      $showArchived: Boolean
    ) {
      paginatedPlacesByInvestmentsNamesAndKeyWords(
        investmentsNames: $investmentsNames
        keyWords: $keyword
        page: $page
        perPage: $perPage
        stageID: $stageID
        isTypeService: $isTypeService
        sortOrder: $sortOrder
        sortField: $sortField
        showArchived: $showArchived
      ) {
        total
        places {
          id
          isTypeService
          isAfterMigration
          isYslabAvailable
          placeCode
          roomCount
          administrativeCode1
          administrativeCode2
          designedUsablePropertyArea
          designedInnerPropertyArea
          asBuildUsablePropertyArea
          asBuildInnerPropertyArea
          building {
            garages {
              id
              name
              groundPlans {
                id
                name
                migrationComment
              }
            }
          }
          groundPlan {
            id
            name
            migrationComment
          }
          investment {
            name
            isArchive
          }
          stage {
            id
            street
          }
          activeContracts {
            id
            isAfterMigration
            isArchived
            contractNo
            cancelledContract
            contractDealDate
            cancelledContract
            contractualAcceptanceDate
            contractualActDate
            acceptanceDate
            actDate
            valorization
            isBusinessPurchase
            isCredit
            isEmailInvoice
            parkingPlaces {
              name
              garage {
                id
                name
                groundPlans {
                  id
                  name
                  migrationComment
                }
              }
            }
            contractCustomers {
              isBusiness
              isSpouseOfMainCustomer
              isAgentOfCustomerSpouse
              isAgentOfMainCustomer
              isSpouseOfMainCustomer
              firstName
              lastName
              maritalStatus
              customerParentID
              id
              uuid
              traderInitials
            }
            contractCustomerCompanies {
              id
              businessName
              isAgentOfMainCustomer
              isRepresentativePerson
              traderInitials
            }
            utilityRooms {
              number
              designedInnerUtilRoomArea
              asBuildInnerUtilRoomArea
              asBuildUsableUtilRoomArea
              garage {
                id
                name
                groundPlans {
                  id
                  name
                  migrationComment
                }
              }
              parkingPlace {
                id
                name
                isExternal
                type
                garage {
                  name
                }
              }
            }
          }
          parkingPlaces {
            name
            garage {
              id
              name
              groundPlans {
                id
              }
            }
          }
          utilityRooms {
            number
            designedUsableUtilRoomArea
            designedInnerUtilRoomArea
            asBuildInnerUtilRoomArea
            asBuildUsableUtilRoomArea
            garage {
              id
              name
            }
            parkingPlace {
              id
              name
              isExternal
              type
              garage {
                name
              }
            }
          }
        }
      }
    }
  `

  const { investmentNames, page, perPage, keyword, showArchived } = props
  return GraphqlService.send(FETCH_PLACES_BY_INVESTMENT_NAME_FOR_MAIN_TABLE, {
    investmentsNames: investmentNames ?? undefined,
    page,
    perPage,
    isTypeService: props?.isTypeService ?? undefined,
    stageID: props?.stageID ?? null,
    keyword,
    sortField: props.sortBy?.id,
    sortOrder: props.sortBy?.desc,
    showArchived,
  })
}

export const getGroundPlanContent = (fileID: number): Promise<any> => {
  const GET_GROUND_PLAN_CONTENT = gql`
    query($fileID: Float!) {
      file(fileID: $fileID) {
        id
        name
        content
      }
    }
  `

  return GraphqlService.send(GET_GROUND_PLAN_CONTENT, { fileID })
}

const getPlacesByInvestmentsNamesForSelectList = (
  investmentsNames: string[]
): Promise<any> => {
  const FETCH_QUERY = gql`
    query($investmentsNames: [String!]!) {
      placesByInvestmentsNames(investmentsNames: $investmentsNames) {
        id
        placeCode
        isTypeService
        buildingID
        stage {
          id
        }
      }
    }
  `
  return GraphqlService.send(FETCH_QUERY, { investmentsNames })
}

export const getPlacesByInvestmentsNamesForAdminPanel = (
  investmentsNames: string[]
): Promise<any> => {
  const FETCH_ADMIN_PLACES_BY_INVESTMENTS_NAMES = gql`
    query($investmentsNames: [String!]!) {
      placesByInvestmentsNames(investmentsNames: $investmentsNames) {
        id
        buildingID
        staircase
        floor
        flatNumber
        designedUsablePropertyArea
        designedInnerPropertyArea
        asBuildUsablePropertyArea
        asBuildInnerPropertyArea
        isTypeService
        isYslabAvailable
        roomCount
        isTypeService
        contracts {
          id
          contractNo
        }
        administrativeCode1
        administrativeCode2
        placeCode
        landStripCode
        landStripDescription
        parkingPlaces {
          id
          name
          isTypeService
          type
          comment
          isExternal
          garageID
          placeID
          garage {
            id
            isTypeService
            name
            groundPlans {
              id
            }
          }
          building {
            id
            isTypeService
            name
          }
          utilityRoom {
            id
          }
        }
        utilityRooms {
          id
          number
          isTypeService
          designedInnerUtilRoomArea
          asBuildUsableUtilRoomArea
          asBuildInnerUtilRoomArea
          comment
          garageID
          placeID
          garage {
            id
            isTypeService
            name
          }
          building {
            id
            isTypeService
            name
          }
          parkingPlace {
            id
            name
            isExternal
            type
            garage {
              name
            }
          }
        }
        landStrips {
          id
          name
        }
        building {
          id
          isTypeService
          name
          nameIsNotVisible
        }
        stage {
          id
          name
          street
        }
        investment {
          id
          name
        }
        activeContracts {
          id
          contractNo
        }
        cancelledContracts {
          id
          contractNo
        }
        archivedContracts {
          id
          contractNo
        }
        arrangement {
          id
          placeID
          arrangementFiles {
            id
            name
            arrangementID
            creationDate
            trader
          }
          conductingPerson
          wallsAnnexSignatureDate
          wallsIsDeliveredToConstruction
          wallsDeliveredToConstructionDate
          sanitaryInstallationsAnnexSignatureDate
          sanitaryInstallationsIsDeliveredToConstruction
          sanitaryInstallationsDeliveredToConstructionDate
          electricInstallationsAnnexSignatureDate
          electricInstallationsIsDeliveredToConstruction
          electricInstallationsDeliveredToConstructionDate
          finishingWorksAnnexSignatureDate
          finishingWorksIsDeliveredToConstruction
          finishingWorksDeliveredToConstructionDate
        }
        groundPlan {
          id
          name
          length
          category
          created
          description
          placeID
          migrationComment
        }
      }
    }
  `

  return GraphqlService.send(FETCH_ADMIN_PLACES_BY_INVESTMENTS_NAMES, {
    investmentsNames,
  })
}

const getPaginatedPlacesByInvestmentsNamesForAdminPanel = (
  variables: PlacesByInvestmentsNamesForAdminPanel
): Promise<any> => {
  const FETCH_PLACES = gql`
    query(
      $investmentsNames: [String!]
      $page: Float
      $perPage: Float
      $stageID: Float
      $isTypeService: Boolean
      $keywords: String
      $sortOrder: Boolean
      $sortField: String
    ) {
      paginatedPlacesByInvestmentsNamesAndKeyWords(
        investmentsNames: $investmentsNames
        keyWords: $keywords
        page: $page
        perPage: $perPage
        stageID: $stageID
        isTypeService: $isTypeService
        sortField: $sortField
        sortOrder: $sortOrder
      ) {
        total
        places {
          id
          buildingID
          flatNumber
          placeCode
          administrativeCode1
          administrativeCode2
          administrativeAddress
          designedUsablePropertyArea
          designedInnerPropertyArea
          asBuildUsablePropertyArea
          asBuildInnerPropertyArea
          designedUsablePropertyArea
          designedInnerPropertyArea
          asBuildUsablePropertyArea
          asBuildInnerPropertyArea
          roomCount
          staircase
          floor
          isTypeService
          isAfterMigration
          isYslabAvailable
          stage {
            street
          }
          activeContracts {
            id
            contractNo
          }
          contracts {
            id
            contractNo
          }
          parkingPlaces {
            id
            name
            isTypeService
            type
            comment
            isExternal
            garageID
            placeID
            garage {
              id
              isTypeService
              name
              groundPlans {
                id
              }
            }
            building {
              nameIsNotVisible
              id
              isTypeService
              name
            }
            utilityRoom {
              id
            }
          }
          utilityRooms {
            id
            number
            isTypeService
            designedInnerUtilRoomArea
            asBuildUsableUtilRoomArea
            asBuildInnerUtilRoomArea
            comment
            garageID
            placeID
            garage {
              id
              isTypeService
              name
              groundPlans {
                id
              }
            }
            building {
              id
              isTypeService
              name
              nameIsNotVisible
            }
            parkingPlace {
              id
              name
              isExternal
              type
              garage {
                name
              }
            }
          }
          building {
            name
            nameIsNotVisible
          }
          parkingPlaces {
            name
          }
          investment {
            name
          }
          groundPlan {
            id
            name
            length
            category
            created
            description
            placeID
            isAfterMigration
          }
        }
      }
    }
  `

  variables.stageID = variables.stageID === -1 ? null : variables.stageID
  return GraphqlService.send(FETCH_PLACES, {
    ...variables,
    investmentsNames: variables.investmentsNames ?? undefined,
    sortField: variables.sortBy?.id,
    sortOrder: variables.sortBy?.desc,
  })
}

export const getPlacesByInvestmentStageId = (stageID: number): Promise<any> => {
  const FETCH_PLACES_BY_INVESTMENTS_STAGE_ID_QUERY = gql`
    query($stagesIDs: [Float!]!) {
      placesByStages(stagesIDs: $stagesIDs) {
        id
        buildingID
        staircase
        floor
        flatNumber
        designedUsablePropertyArea
        designedInnerPropertyArea
        asBuildUsablePropertyArea
        asBuildInnerPropertyArea
        roomCount
        isTypeService
        contracts {
          id
          contractNo
        }
        administrativeCode1
        administrativeCode2
        placeCode
        landStripCode
        landStripDescription
        parkingPlaces {
          id
          name
          isTypeService
          type
          comment
          isExternal
          garageID
          placeID
          garage {
            id
            isTypeService
            name
            groundPlans {
              id
            }
          }
          building {
            id
            isTypeService
            name
          }
          utilityRoom {
            id
          }
        }
        utilityRooms {
          id
          number
          designedInnerUtilRoomArea
          asBuildUsableUtilRoomArea
          asBuildInnerUtilRoomArea
          comment
          garageID
          placeID
          garage {
            id
            isTypeService
            name
            groundPlans {
              id
            }
          }
          parkingPlace {
            id
            name
            isExternal
            type
            garage {
              name
            }
          }
          building {
            id
            isTypeService
            name
          }
        }
        landStrips {
          id
          name
        }
        building {
          id
          isTypeService
          name
        }
        stage {
          id
          name
          street
        }
        investment {
          id
          name
        }
        activeContracts {
          id
          contractNo
        }
        cancelledContracts {
          id
          contractNo
        }
        archivedContracts {
          id
          contractNo
        }
        arrangement {
          id
          placeID
          arrangementFiles {
            id
            name
            content
            arrangementID
            creationDate
            trader
          }
          conductingPerson
          wallsAnnexSignatureDate
          wallsIsDeliveredToConstruction
          wallsDeliveredToConstructionDate
          sanitaryInstallationsAnnexSignatureDate
          sanitaryInstallationsIsDeliveredToConstruction
          sanitaryInstallationsDeliveredToConstructionDate
          electricInstallationsAnnexSignatureDate
          electricInstallationsIsDeliveredToConstruction
          electricInstallationsDeliveredToConstructionDate
          finishingWorksAnnexSignatureDate
          finishingWorksIsDeliveredToConstruction
          finishingWorksDeliveredToConstructionDate
        }
        groundPlan {
          id
          name
          length
          category
          created
          description
          content
          placeID
        }
      }
    }
  `

  return GraphqlService.send(FETCH_PLACES_BY_INVESTMENTS_STAGE_ID_QUERY, {
    stagesIDs: [stageID],
  })
}

export const addPlace = (place: IPlaceInput): Promise<any> => {
  const ADD_PLACE = gql`
    mutation ap($place: PlaceInput!) {
      savePlace(place: $place) {
        id
      }
    }
  `

  return GraphqlService.send(ADD_PLACE, { place })
}

export const updatePlace = ({
  placeID,
  place,
}: {
  placeID: number
  place: IPlaceInput
}): Promise<any> => {
  const UPDATE_PLACE = gql`
    mutation sp($place: PlaceInput!, $placeID: Float!) {
      updatePlace(place: $place, placeID: $placeID) {
        id
      }
    }
  `

  return GraphqlService.send(UPDATE_PLACE, { placeID, place })
}

export const deletePlace = (placeID: number): Promise<any> => {
  const DELETE_PLACE = gql`
    mutation deletePlace($placeID: Float!) {
      deletePlace(placeID: $placeID)
    }
  `

  return GraphqlService.send(DELETE_PLACE, { placeID })
}

export const saveGroundPlanIntoPlace = (
  file: IGroundPlanPlaceInput
): Promise<any> => {
  const SAVE_GROUND_PLAN_PLACE = gql`
    mutation saveFile($file: FileInput!) {
      saveFile(file: $file) {
        id
        name
        length
        category
        content
        created
        description
      }
    }
  `

  return GraphqlService.send(SAVE_GROUND_PLAN_PLACE, { file })
}

export interface IUpdateGroundPlan {
  fileID: number
  file: IGroundPlanPlaceInput | IGarageGroundPlanInput
}

export const editGroundPlane = (
  paramaters: IUpdateGroundPlan
): Promise<any> => {
  const UPDATE_GROUND_PLAN = gql`
    mutation updateFile($file: FileInput!, $fileID: Float!) {
      updateFile(file: $file, fileID: $fileID) {
        id
        name
        length
        category
        content
        created
        description
      }
    }
  `

  return GraphqlService.send(UPDATE_GROUND_PLAN, paramaters)
}

const deleteGroundPlan = (fileID: number): Promise<any> => {
  const DELETE_GROUND_PLAN = gql`
    mutation deleteFile($fileID: Float!) {
      deleteFile(fileID: $fileID)
    }
  `

  return GraphqlService.send(DELETE_GROUND_PLAN, { fileID })
}

const fetchPlaceByPlaceIDForArragements = (placeID: number): Promise<any> => {
  const FETCH_PLACE = gql`
    query($placeID: Float!) {
      place(placeID: $placeID) {
        arrangement {
          arrangementFiles {
            id
            name
            arrangementID
            creationDate
            trader
          }
          id
          placeID
          conductingPerson
          wallsAnnexSignatureDate
          wallsIsDeliveredToConstruction
          wallsDeliveredToConstructionDate
          sanitaryInstallationsAnnexSignatureDate
          sanitaryInstallationsIsDeliveredToConstruction
          sanitaryInstallationsDeliveredToConstructionDate
          electricInstallationsAnnexSignatureDate
          electricInstallationsIsDeliveredToConstruction
          electricInstallationsDeliveredToConstructionDate
          finishingWorksAnnexSignatureDate
          finishingWorksIsDeliveredToConstruction
          finishingWorksDeliveredToConstructionDate
          isAfterMigration
        }
      }
    }
  `
  return GraphqlService.send(FETCH_PLACE, { placeID })
}

const fetchPlaceByPlaceID = (placeID: number): Promise<any> => {
  const FETCH_PLACE_BY_PLACE_ID = gql`
    query($placeID: Float!) {
      place(placeID: $placeID) {
        id
        placeCode
        investment {
          name
          isArchive
        }
        stage {
          id
          street
        }
        activeContracts {
          id
          isAfterMigration
          customerCode
          placeID
          id
          comments
          contractNo
          customerCode
          isDeveloperContract
          developerContractDate
          street
          backyardTerrace
          roofTerrace
          garden
          repNumber
          otherContract
          lawContract
          deluxeContract
          valorization
          cancelledContract
          cancelledContractDate
          isEmailInvoice
          isBusinessPurchase
          isCredit
          creditType
          creditSecurity
          lendingBank
          amountOfCredit1
          amountOfCredit2
          creditDealNo
          creditDealDate
          contractDealDate
          contractualAcceptanceDate
          contractualActDate
          acceptanceDate
          actDate
          notarialOffice
          isPledge
          pledge
          isWarranty
          warranty
          numberOfOwners
          files {
            name
            migrationComment
            isAfterMigration
            id
          }
          contractCustomers {
            id
            traderInitials
            isFromMigration
            uuid
            customerParentID
            purchasePurpose
            customerCode
            customerType
            customerID
            lastName
            firstName
            secondName
            maidenName
            parentsNames
            pesel
            taxNumber
            sex
            customerID
            phone1
            phone2
            email
            email2
            passportNumberAndSeries
            IDNumberAndSeries
            citizenship
            isBusiness
            businessName
            regon
            businessAddressName
            businessAddressSurname
            businessAddressBusinessName
            businessAddressCountry
            businessAddressCity
            businessAddressStreet
            businessAddressNumber
            businessAddressNumber
            businessAddressPostalCode
            businessAddressVoivodeship
            businessAddressIsForeign
            businessAddressFlatNumber
            residenceName
            residenceSurname
            residenceBusinessName
            residenceCountry
            residenceCity
            residenceStreet
            residenceNumber
            residenceFlatNumber
            residencePostalCode
            residenceVoivodeship
            residenceIsForeign
            correspondenceName
            correspondenceSurname
            correspondenceBusinessName
            correspondenceFlatNumber
            correspondenceCountry
            correspondenceCity
            correspondenceStreet
            correspondenceNumber
            correspondencePostalCode
            correspondenceVoivodeship
            correspondenceIsForeign
            maritalStatus
            contractID
            customerInvestmentCode
            sourceInfo
            isBroker
            brokerName
            isCommissionDivision
            commissionDivision
            isAfterMigration
            isToughCustomer
            isSpouseOfMainCustomer
            isAgentOfMainCustomer
            isAgentOfCustomerSpouse
            isBlueList
            purchasePurpose
            businessType
            phone3
            placeShares
            agentFiles {
              id
              name
              created
              contractCustomerID
              migrationComment
            }
            marketingConsents {
              customerID
              id
              isInfoDutyFulfilled
              infoDutyForm
              infoDutyFormDate
              isAgreementPhone
              agreementPhoneDate
              isAgreementPhoneCancelled
              agreementPhoneCancellationDate
              isAgreementEmail
              agreementEmailDate
              isAgreementEmailCancelled
              agreementEmailCancellationDate
              isMSWiAAgreement
              isAfterMigration
            }
          }
          contractCustomerCompanies {
            id
            uuid
            traderInitials
            isFromMigration
            isAfterMigration
            companyParentID
            businessName
            companyForm
            sourceInfo
            pesel
            krsString
            taxNumber
            phone1
            phone2
            representativePhone
            businessActivityRegister
            regon
            email
            email2
            representativeEmail
            businessType
            placeShares
            residenceName
            residenceSurname
            residenceBusinessName
            customerType
            purchasePurpose
            residenceCountry
            residenceCity
            residenceStreet
            residenceNumber
            residencePostalCode
            residenceVoivodeship
            residenceFlatNumber
            customerID
            residenceIsForeign
            correspondenceName
            correspondenceSurname
            correspondenceBusinessName
            correspondenceFlatNumber
            correspondenceCountry
            correspondenceCity
            correspondenceStreet
            correspondenceNumber
            correspondencePostalCode
            correspondenceVoivodeship
            correspondenceIsForeign
            businessAddressName
            businessAddressSurname
            businessAddressBusinessName
            businessAddressCountry
            businessAddressFlatNumber
            businessAddressCity
            businessAddressStreet
            businessAddressNumber
            businessAddressNumber
            businessAddressPostalCode
            businessAddressVoivodeship
            businessAddressIsForeign
            isInfoDutyFulfilled
            infoDutyForm
            isAgreementPhone
            agreementPhoneDate
            isAgreementPhoneCancelled
            agreementPhoneCancellationDate
            isAgreementEmail
            agreementEmailDate
            isAgreementEmailCancelled
            agreementEmailCancellationDate
            contractID
            customerInvestmentCode
            firstName
            lastName
            secondName
            phone3
            pesel
            isBroker
            brokerName
            customerCode
            isAgentOfMainCustomer
            isRepresentativePerson
            passportNumberAndSeries
            IDNumberAndSeries
            citizenship
            agentFiles {
              id
              name
              created
              contractCompanyID
              migrationComment
            }
            marketingConsents {
              customerID
              id
              isInfoDutyFulfilled
              infoDutyForm
              infoDutyFormDate
              isAgreementPhone
              agreementPhoneDate
              isAgreementPhoneCancelled
              agreementPhoneCancellationDate
              isAgreementEmail
              agreementEmailDate
              isAgreementEmailCancelled
              agreementEmailCancellationDate
              isMSWiAAgreement
              isAfterMigration
            }
          }
          parkingPlaces {
            id
            isTypeService
            name
            type
            isExternal
            utilityRoom {
              id
            }
          }
          utilityRooms {
            id
            number
            isTypeService
            designedInnerUtilRoomArea
            asBuildUsableUtilRoomArea
            asBuildInnerUtilRoomArea
            parkingPlace {
              id
              name
              isExternal
              type
              garage {
                name
              }
            }
          }
        }
        parkingPlaces {
          id
          name
          isTypeService
          type
          isExternal
          utilityRoom {
            id
          }
        }
        placeCode
        roomCount
        designedUsablePropertyArea
        designedInnerPropertyArea
        asBuildUsablePropertyArea
        asBuildInnerPropertyArea
        administrativeCode1
        administrativeCode2
        utilityRooms {
          id
          number
          isTypeService
          designedInnerUtilRoomArea
          asBuildUsableUtilRoomArea
          asBuildInnerUtilRoomArea
          parkingPlace {
            id
            name
            isExternal
            type
            garage {
              name
            }
          }
        }
      }
    }
  `
  return GraphqlService.send(FETCH_PLACE_BY_PLACE_ID, { placeID })
}
const fetchCancelledContractPlaceByPlaceID = (
  placeID: number
): Promise<any> => {
  const FETCH_PLACE_BY_PLACE_ID = gql`
    query($placeID: Float!) {
      place(placeID: $placeID) {
        id
        placeCode

        investment {
          name
          isArchive
        }
        parkingPlaces {
          id
          name
        }
        stage {
          id
          street
        }
        arrangement {
          arrangementFiles {
            id
            name
            content
            arrangementID
            creationDate
            trader
          }
          id
          placeID
          conductingPerson
          wallsAnnexSignatureDate
          wallsIsDeliveredToConstruction
          wallsDeliveredToConstructionDate
          sanitaryInstallationsAnnexSignatureDate
          sanitaryInstallationsIsDeliveredToConstruction
          sanitaryInstallationsDeliveredToConstructionDate
          electricInstallationsAnnexSignatureDate
          electricInstallationsIsDeliveredToConstruction
          electricInstallationsDeliveredToConstructionDate
          finishingWorksAnnexSignatureDate
          finishingWorksIsDeliveredToConstruction
          finishingWorksDeliveredToConstructionDate
        }
        cancelledContracts {
          id
          isAfterMigration
          customerCode
          placeID
          id
          comments
          contractNo
          customerCode
          isDeveloperContract
          developerContractDate
          street
          backyardTerrace
          roofTerrace
          garden
          repNumber
          otherContract
          lawContract
          deluxeContract
          valorization
          cancelledContract
          cancelledContractDate
          isEmailInvoice
          isBusinessPurchase
          isCredit
          creditType
          creditSecurity
          lendingBank
          amountOfCredit1
          amountOfCredit2
          creditDealNo
          creditDealDate
          contractDealDate
          contractualAcceptanceDate
          contractualActDate
          acceptanceDate
          actDate
          notarialOffice
          isPledge
          pledge
          isWarranty
          warranty
          numberOfOwners
          parkingPlaces {
            id
            name
          }
          utilityRooms {
            id
            number
            parkingPlace {
              id
              name
              isExternal
              type
              garage {
                name
              }
            }
          }
          contractCustomers {
            id
            uuid
            isFromMigration
            customerParentID
            traderInitials
            purchasePurpose
            customerCode
            customerType
            customerID
            lastName
            firstName
            secondName
            maidenName
            parentsNames
            pesel
            taxNumber
            sex
            customerID
            phone1
            phone2
            email
            email2
            passportNumberAndSeries
            IDNumberAndSeries
            citizenship
            isBusiness
            businessName
            regon
            businessAddressName
            businessAddressSurname
            businessAddressBusinessName
            businessAddressCountry
            businessAddressCity
            businessAddressStreet
            businessAddressNumber
            businessAddressNumber
            businessAddressPostalCode
            businessAddressVoivodeship
            businessAddressIsForeign
            businessAddressFlatNumber
            residenceName
            residenceSurname
            residenceBusinessName
            residenceCountry
            residenceCity
            residenceStreet
            residenceNumber
            residenceFlatNumber
            residencePostalCode
            residenceVoivodeship
            residenceIsForeign
            correspondenceName
            correspondenceSurname
            correspondenceBusinessName
            correspondenceFlatNumber
            correspondenceCountry
            correspondenceCity
            correspondenceStreet
            correspondenceNumber
            correspondencePostalCode
            correspondenceVoivodeship
            correspondenceIsForeign
            maritalStatus
            contractID
            customerInvestmentCode
            sourceInfo
            isBroker
            brokerName
            isCommissionDivision
            commissionDivision
            isAfterMigration
            isToughCustomer
            isSpouseOfMainCustomer
            isAgentOfMainCustomer
            isAgentOfCustomerSpouse
            isBlueList
            purchasePurpose
            businessType
            placeShares
            agentFiles {
              id
              name
              created
              contractCustomerID
            }
            marketingConsents {
              customerID
              id
              isInfoDutyFulfilled
              infoDutyForm
              infoDutyFormDate
              isAgreementPhone
              agreementPhoneDate
              isAgreementPhoneCancelled
              agreementPhoneCancellationDate
              isAgreementEmail
              agreementEmailDate
              isAgreementEmailCancelled
              agreementEmailCancellationDate
              isMSWiAAgreement
            }
          }
          contractCustomerCompanies {
            id
            uuid
            traderInitials
            isFromMigration
            businessName
            companyForm
            sourceInfo
            pesel
            krsString
            taxNumber
            phone1
            phone2
            representativePhone
            businessActivityRegister
            regon
            email
            email2
            representativeEmail
            businessType
            placeShares
            residenceName
            residenceSurname
            residenceBusinessName
            customerType
            purchasePurpose
            residenceCountry
            residenceCity
            residenceStreet
            residenceNumber
            residencePostalCode
            residenceVoivodeship
            residenceFlatNumber
            customerID
            residenceIsForeign
            correspondenceName
            correspondenceSurname
            correspondenceBusinessName
            correspondenceFlatNumber
            correspondenceCountry
            correspondenceCity
            correspondenceStreet
            correspondenceNumber
            correspondencePostalCode
            correspondenceVoivodeship
            correspondenceIsForeign
            businessAddressName
            businessAddressSurname
            businessAddressBusinessName
            businessAddressCountry
            businessAddressFlatNumber
            businessAddressCity
            businessAddressStreet
            businessAddressNumber
            businessAddressNumber
            businessAddressPostalCode
            businessAddressVoivodeship
            businessAddressIsForeign
            isInfoDutyFulfilled
            infoDutyForm
            isAgreementPhone
            agreementPhoneDate
            isAgreementPhoneCancelled
            agreementPhoneCancellationDate
            isAgreementEmail
            agreementEmailDate
            isAgreementEmailCancelled
            agreementEmailCancellationDate
            contractID
            customerInvestmentCode
            firstName
            lastName
            pesel
            isBroker
            brokerName
            customerCode
            isAgentOfMainCustomer
            isRepresentativePerson
            passportNumberAndSeries
            IDNumberAndSeries
            citizenship
            marketingConsents {
              customerID
              id
              isInfoDutyFulfilled
              infoDutyForm
              infoDutyFormDate
              isAgreementPhone
              agreementPhoneDate
              isAgreementPhoneCancelled
              agreementPhoneCancellationDate
              isAgreementEmail
              agreementEmailDate
              isAgreementEmailCancelled
              agreementEmailCancellationDate
              isMSWiAAgreement
            }
          }
          parkingPlaces {
            id
            isTypeService
            name
            type
            isExternal
            utilityRoom {
              id
            }
          }
          utilityRooms {
            id
            number
            isTypeService
            designedInnerUtilRoomArea
            asBuildUsableUtilRoomArea
            asBuildInnerUtilRoomArea
            parkingPlace {
              id
              name
              isExternal
              type
              garage {
                name
              }
            }
          }
        }
        placeCode
        roomCount
        designedUsablePropertyArea
        designedInnerPropertyArea
        asBuildUsablePropertyArea
        asBuildInnerPropertyArea
        administrativeCode1
        administrativeCode2
        utilityRooms {
          id
          number
          isTypeService
          designedInnerUtilRoomArea
          asBuildUsableUtilRoomArea
          asBuildInnerUtilRoomArea
          parkingPlace {
            id
            name
            isExternal
            type
            garage {
              name
            }
          }
        }
      }
    }
  `
  return GraphqlService.send(FETCH_PLACE_BY_PLACE_ID, { placeID })
}

const fetchSumsOfAreasInInvestments = ({
  investmentsNames,
  isTypeService,
  stageID,
  showArchived,
}: {
  investmentsNames?: string[]
  isTypeService?: boolean
  stageID?: number
  showArchived?: boolean
}): Promise<any> => {
  const FETCH_SUMS_BY_INVESTMENTS_NAMES = gql`
    query sumsOfAreasInInvestments(
      $investmentsNames: [String!]
      $isTypeService: Boolean
      $stageID: Float
      $showArchived: Boolean
    ) {
      sumsOfAreasInInvestments(
        investmentsNames: $investmentsNames
        isTypeService: $isTypeService
        stageID: $stageID
        showArchived: $showArchived
      ) {
        designedUsablePropertyAreas
        designedInnerPropertyAreas
        asBuildUsablePropertyAreas
        asBuildInnerPropertyAreas
        asBuildInnerUtilRoomAreas
        designedInnerUtilRoomAreas
        asBuildUsableUtilRoomAreas
        designedUsableUtilRoomAreas
      }
    }
  `
  const investments =
    investmentsNames && investmentsNames?.length > 1 ? null : investmentsNames
  const stage = showArchived ? null : stageID && stageID > 0 ? stageID : null

  //spagetti-workaround because of backend issue
  const variables =
    investments?.length === 1 && stageID
      ? {
          investmentsNames: investments,
          isTypeService,
          stageID: stage,
        }
      : investments && stageID
      ? {
          investmentsNames: investments,
          isTypeService,
          stageID: stage,
          showArchived,
        }
      : investmentsNames
      ? {
          investmentsNames: investments,
          isTypeService,
          showArchived,
        }
      : stage
      ? { isTypeService, stageID: stage, showArchived }
      : {
          isTypeService,
          showArchived,
        }
  return GraphqlService.send(FETCH_SUMS_BY_INVESTMENTS_NAMES, variables)
}

const updatePlaceRelatedToContract = (variables: any): Promise<any> => {
  const UPDATE_PLACE_RELATED_CONTRACT_DATA_QUERY = gql`
    mutation uprcd(
      $contract: ContractInput!
      $utilityRoomsList: [Float!]
      $parkingPlacesList: [Float!]
      $contractID: Float!
      $customersList: [Float!]
    ) {
      updateContract(
        contract: $contract
        utilityRoomsList: $utilityRoomsList
        contractID: $contractID
        parkingPlacesList: $parkingPlacesList
        customersList: $customersList
      ) {
        id
      }
    }
  `
  return GraphqlService.send(
    UPDATE_PLACE_RELATED_CONTRACT_DATA_QUERY,
    variables
  )
}

export const updateIsYslabAvailableInPlace = ({
  placeID,
  isYslabAvailable,
}: {
  placeID: number
  isYslabAvailable: boolean
}): Promise<any> => {
  const UPDATE_IS_YSLAB_AVAILABLE_IN_PLACE_QUERY = gql`
    mutation sp($isYslabAvailable: Boolean!, $placeID: Float!) {
      updateIsYslabAvailableInPlace(
        isYslabAvailable: $isYslabAvailable
        placeID: $placeID
      ) {
        id
      }
    }
  `

  return GraphqlService.send(UPDATE_IS_YSLAB_AVAILABLE_IN_PLACE_QUERY, {
    placeID,
    isYslabAvailable,
  })
}

const mutations = {
  fetchPlaceByPlaceIDForArragements,
  getPlacesByInvestmentsNamesForSelectList,
  fetchCancelledContractPlaceByPlaceID,
  getPlacesByInvestmentsNamesForAdminPanel,
  getPlacesByInvestmentStageId,
  getPlacesByInvestmentNameForMainTableActiveContracts,
  getGroundPlanContent,
  addPlace,
  updatePlace,
  deletePlace,
  saveGroundPlanIntoPlace,
  deleteGroundPlan,
  editGroundPlane,
  fetchPlaceByPlaceID,
  updatePlaceRelatedToContract,
  getPaginatedPlacesByInvestmentsNamesForAdminPanel,
  fetchSumsOfAreasInInvestments,
  updateIsYslabAvailableInPlace,
}

export default mutations
