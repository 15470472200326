import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'
import { IParkingPlace } from './../graphql/parkingPlaces'

export const getAllParkingPlaces = (): Promise<any> => {
  const FETCH_ALL_PARKING_PLACES_QUERY = gql`
    query {
      parkingPlaces {
        id
        isTypeService
        name
        type
        comment
        isExternal
        garageID
        placeID
        place {
          placeCode
          isTypeService
        }
        utilityRoom {
          id
          number
        }
      }
    }
  `

  return GraphqlService.send(FETCH_ALL_PARKING_PLACES_QUERY, {})
}

export const getContractConnectedToParkingPlace = (
  contractID: number
): Promise<any> => {
  const GET_CONTRACT_CONNECTED_TO_PARKING_PLACE = gql`
    query($contractID: Float!) {
      getContract(contractID: $contractID) {
        id
        isArchived
        cancelledContract
      }
    }
  `

  return GraphqlService.send(GET_CONTRACT_CONNECTED_TO_PARKING_PLACE, {
    contractID,
  })
}

export const getParkingPlacesByContract = ({
  contractID,
}: {
  contractID: number
}): Promise<any> => {
  const FETCH_PARKING_PLACES_BY_CONTRACT_ID_QUERY = gql`
    query($contractID: Float!) {
      parkingPlacesByContract(contractID: $contractID) {
        id
        name
        isTypeService
        type
        comment
        isExternal
        garageID
        placeID
        isAfterMigration
        place {
          placeCode
          isTypeService
          id
          building {
            name
            id
          }
          activeContracts {
            id
          }
        }
        contractID
        utilityRoom {
          id
          number
        }
        building {
          id
          isTypeService
          name
          stageID
        }
        garage {
          isTypeService
          name
        }
      }
    }
  `
  return GraphqlService.send(FETCH_PARKING_PLACES_BY_CONTRACT_ID_QUERY, {
    contractID,
  })
}

export const getParkingPlacesByPlace = ({
  placeID,
}: {
  placeID: number
}): Promise<any> => {
  const FETCH_PARKING_PLACES_BY_PLACE_ID_QUERY = gql`
    query($placeID: Float!) {
      parkingPlacesByPlace(placeID: $placeID) {
        id
        name
        isTypeService
        type
        comment
        isExternal
        garageID
        placeID
        isAfterMigration
        place {
          placeCode
          isTypeService
          id
          building {
            name
            id
          }
          activeContracts {
            id
          }
        }
        contractID
        utilityRoom {
          id
          number
        }
        building {
          id
          isTypeService
          name
          stageID
        }
        garage {
          isTypeService
          name
        }
      }
    }
  `
  return GraphqlService.send(FETCH_PARKING_PLACES_BY_PLACE_ID_QUERY, {
    placeID,
  })
}

export const getParkingPlacesByInvestmentNames = ({
  investmentNames,
  stagesIDs,
}: {
  investmentNames: string[]
  stagesIDs?: number[]
}): Promise<any> => {
  const FETCH_PARKING_PLACES_BY_INVESTMENTS_NAMES_QUERY = gql`
    query($investmentsNames: [String!]!, $stagesIDs: [Float!]) {
      parkingPlacesByInvestmentsNamesThroughGarage(
        investmentsNames: $investmentsNames
        stagesIDs: $stagesIDs
      ) {
        id
        name
        isTypeService
        type
        comment
        isExternal
        garageID
        placeID
        isAfterMigration
        place {
          placeCode
          isTypeService
          id
          building {
            name
            id
          }
          activeContracts {
            id
          }
        }
        contractID
        utilityRoom {
          id
          number
        }
        building {
          id
          isTypeService
          name
          stageID
        }
        garage {
          isTypeService
          name
        }
      }
    }
  `

  return GraphqlService.send(FETCH_PARKING_PLACES_BY_INVESTMENTS_NAMES_QUERY, {
    investmentsNames: investmentNames,
    stagesIDs,
  })
}

export const getParkingPlacesByInvestmentStageId = (
  stageID: number
): Promise<any> => {
  const FETCH_PARKING_PLACES_BY_INVESTMENTS_STAGE_ID_QUERY = gql`
    query($stagesIDs: [Float!]!) {
      parkingPlaceByStages(stagesIDs: $stagesIDs) {
        id
        isTypeService
        name
        type
        comment
        isExternal
        garageID
        placeID
        isAfterMigration
        place {
          placeCode
          isTypeService
          id
          building {
            name
            id
          }
        }
        contractID
        utilityRoom {
          id
          number
        }
        building {
          id
          isTypeService
          name
          stageID
        }
        garage {
          isTypeService
          name
        }
      }
    }
  `
  return GraphqlService.send(
    FETCH_PARKING_PLACES_BY_INVESTMENTS_STAGE_ID_QUERY,
    { stagesIDs: [stageID] }
  )
}

export const addNewParkingPlace = ({
  parkingPlace,
}: {
  parkingPlace: IParkingPlace
}): Promise<any> => {
  const ADD_PARKING_PLACE_QUERY = gql`
    mutation app($parkingPlace: ParkingPlaceInput!) {
      saveParkingPlace(parkingPlace: $parkingPlace) {
        id
      }
    }
  `

  return GraphqlService.send(ADD_PARKING_PLACE_QUERY, { parkingPlace })
}

export const updateParkingPlace = ({
  parkingPlace,
  parkingPlaceID,
}: {
  parkingPlace: IParkingPlace
  parkingPlaceID: number
}): Promise<any> => {
  const UPDATE_PARKING_PLACE_QUERY = gql`
    mutation app($parkingPlace: ParkingPlaceInput!, $parkingPlaceID: Float!) {
      updateParkingPlace(
        parkingPlace: $parkingPlace
        parkingPlaceID: $parkingPlaceID
      ) {
        id
      }
    }
  `

  return GraphqlService.send(UPDATE_PARKING_PLACE_QUERY, {
    parkingPlace,
    parkingPlaceID,
  })
}

export const removeParkingPlace = (parkingPlaceID: number): Promise<any> => {
  const DELETE_PARKING_PLACE_QUERY = gql`
    mutation deleteParkingPlace($parkingPlaceID: Float!) {
      deleteParkingPlace(parkingPlaceID: $parkingPlaceID)
    }
  `

  return GraphqlService.send(DELETE_PARKING_PLACE_QUERY, { parkingPlaceID })
}

const mutations = {
  getAllParkingPlaces,
  getContractConnectedToParkingPlace,
  getParkingPlacesByPlace,
  getParkingPlacesByContract,
  getParkingPlacesByInvestmentNames,
  getParkingPlacesByInvestmentStageId,
  addNewParkingPlace,
  updateParkingPlace,
  removeParkingPlace,
}

export default mutations
