import * as React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import { fetchDARDateAnnexesReport } from '../../ducks/reports'
import { ReportWithList } from './ReportWithList'

const DARDateAnnexesReport: React.FC = () => {
  const { isLoadingDARDateAnnexesReport } = useSelector(
    (state: RootState) => state.reports
  )

  return (
    <ReportWithList
      isLoading={isLoadingDARDateAnnexesReport}
      reportAPIName='DARDateAnnexesReport'
      reportName='Raport-aneksy.xlsx'
      reduxAction={fetchDARDateAnnexesReport}
      selectByActiveContractsIDs
    />
  )
}
export default DARDateAnnexesReport
