import React, { FC } from 'react'
import './InterestedCustomersReport.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppDispatch, RootState } from '../../../ducks'
import {
  downloadXlsxReport,
  getDSPCustomerSourceInfoReport,
} from '../../../ducks/reports'
import { RaportWithDatesState } from '../../Reports/RaportWithDates/actions'
import { Dates } from '../../Reports/RaportWithDates/Dates'
import { Investments } from '../../Reports/RaportWithDates/Investments'
import { RaportWithDates } from '../../Reports/RaportWithDates/RaportWithDates'
import { SourceInfo } from '../../Reports/RaportWithDates/SourceInfo'
import { Stages } from '../../Reports/RaportWithDates/Stages'

const InterestedCustomersReport: FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const history = useHistory()
  const isLoading = useSelector(
    (state: RootState) =>
      state.combinedReports.DSPCustomerSourceInfoReport.isLoading
  )

  const generateReport = React.useCallback(
    async (state: RaportWithDatesState) => {
      const stagesIDs = state.chosenStages.map((stage) => stage.id)
      const response = await dispatch(
        getDSPCustomerSourceInfoReport({
          fromDate: state.fromDate,
          toDate: state.toDate,
          sourceInfo: state.chosenSourceInfo,
          stagesIDs,
        })
      )
      const content = response.payload.DSPCustomerSourceInfoReport
      dispatch(
        downloadXlsxReport({
          content,
          filename: 'Zainteresowani - źródła informacji.xlsx',
        })
      ).then(() => {
        history.push('/')
      })
    },
    [dispatch, history]
  )

  return (
    <RaportWithDates isLoading={isLoading} generateReport={generateReport}>
      <Dates />
      <Investments />
      <Stages />
      <SourceInfo />
    </RaportWithDates>
  )
}

export default InterestedCustomersReport
