import moment from 'moment'
import 'moment/locale/pl'

import { IContact } from '../graphql/contacts'

import { getProperDateForTable } from '../utils/getProperDate'

export const columnsData = [
  {
    Header: 'Imię Klienta',
    accessor: 'firstName',
    width: '120',
  },
  {
    Header: 'Nazwisko Klienta',
    accessor: 'lastName',
    width: '150',
  },
  {
    id: 'phone1',
    Header: 'Telefon 1.',
    accessor: (a: { phone1: string }): string => {
      const splitNumber = a?.phone1?.match(/[\s\S]{1,3}/g) || []
      return splitNumber?.length === 3
        ? `${splitNumber?.[0] || ''}  ${splitNumber?.[1] || ''} ${
            splitNumber?.[2] || ''
          }`
        : a?.phone1
    },
    width: '120',
  },
  {
    id: 'phone2',
    Header: 'Telefon 2.',
    accessor: (a: { phone2: string }): string => {
      const splitNumber = a?.phone2?.match(/[\s\S]{1,3}/g)
      return splitNumber?.length === 3
        ? `${splitNumber?.[0] || ''}  ${splitNumber?.[1] || ''} ${
            splitNumber?.[2] || ''
          }`
        : a?.phone2
    },
    width: '120',
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: '160',
  },
  {
    Header: 'Email 2',
    accessor: 'email2',
    width: '160',
  },
  {
    id: 'lastContactDate',
    Header: 'L. dni',
    accessor: (a: {
      contacts: any[]
      isBroker: boolean
      lastContactDate: string
    }): string => {
      const today = moment()
      const firstContactDate = moment(a.contacts?.[0]?.date)
      const lastContactDate = moment(a.lastContactDate)
      const date = a?.isBroker ? firstContactDate : lastContactDate
      const difference =
        today.diff(date, 'days') >= 0 ? String(today.diff(date, 'days')) : '-'
      return difference
    },
    width: '40',
  },
  {
    Header: 'Klient IK',
    disableSortBy: true,
    width: '80',
    accessor: (a: { contracts: any[]; isIKCustomer: boolean }): string =>
      a.contracts?.length ? '☑' : a.isIKCustomer ? '☑' : '☐',
  },
  {
    id: 'state',
    Header: 'Stan',
    accessor: (a: { state: boolean }): string => {
      return !a.state ? 'PRZETER.' : 'OK'
    },
    width: '80',
  },
  {
    id: 'isBroker',
    Header: 'Pośrednik',
    width: '80',
    accessor: (a: { isBroker: boolean }): string => (a.isBroker ? '☑' : '☐'),
  },
  {
    Header: 'Nazwa pośrednika',
    accessor: 'brokerName',
    width: '180',
  },
  {
    Header: 'Data 1. kontaktu',
    disableSortBy: true,
    accessor: (a: { contacts: IContact[] }): string => {
      const date = a?.contacts?.[0]?.date ?? ''
      return getProperDateForTable(date)
    },
    width: '120',
  },
  {
    Header: 'Rodzaj 1. kontaktu',
    disableSortBy: true,
    accessor: 'contacts[0].type',
    width: '120',
  },
  {
    Header: 'Źródło informacji',
    accessor: 'sourceInfo',
    width: '180',
  },
  {
    Header: 'BL',
    disableSortBy: true,
    width: '50',
    accessor: (a: { isBlueList: boolean }): string =>
      a.isBlueList ? '☑' : '☐',
  },
  {
    Header: 'VIP',
    disableSortBy: true,
    width: '50',
    accessor: (a: { isVIP: boolean }): string => (a.isVIP ? '☑' : '☐'),
  },
  {
    Header: 'Handl.',
    accessor: 'traderInitials',
    width: '100',
  },
  {
    id: 'customerStatus',
    Header: 'Status',
    accessor: (a: { customerStatus: string }): string =>
      a.customerStatus === 'active'
        ? 'aktywny'
        : a.customerStatus === 'new'
        ? 'nowy'
        : a.customerStatus === 'resigned'
        ? 'zrezygnował'
        : a.customerStatus === 'finished'
        ? 'zakończony'
        : a.customerStatus === 'frozen'
        ? 'zamrożony'
        : a.customerStatus === 'success'
        ? 'sukces'
        : '',
    width: '100',
  },
]
