import React, { FunctionComponent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Button, Col, Modal, Row } from 'react-bootstrap'
import {
  ICustomersReminder,
  IUserAlert,
  IUserNote,
} from '../../services/reminderService'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import {
  fetchAndPrintReminderPDFFile,
  fetchReminderPDFFile,
} from '../../ducks/reminder'

import MyAlertsReminderBody from './MyAlertsReminderBody'

interface IReminderColProps {
  item: string | null
  label?: string
  no: number
}

export const ReminderCol: FunctionComponent<IReminderColProps> = (
  props: IReminderColProps
) => {
  const { item, label, no } = props
  return (
    <Col className='mb-3' id={`issue-${no}`} lg='auto' md='auto' sm={12}>
      {label && <span style={{ fontWeight: 'bold' }}>{label}: </span>}
      {item}
    </Col>
  )
}

interface IReminderHeaderProps {
  fullname: string
  email: string
  phone1: string
  phone2: string
}

export const ReminderHeader: FunctionComponent<IReminderHeaderProps> = (
  props: IReminderHeaderProps
) => {
  const { fullname, email, phone1, phone2 } = props
  return (
    <Col className='d-flex justify-content-center'>
      <h5 style={{ fontWeight: 'bold' }}>{`${fullname} (${email}${
        phone1 ? `, ${phone1}` : ''
      }${phone2 ? `, ${phone2}` : ''})`}</h5>
    </Col>
  )
}

interface IReminderSectionHeaderProps {
  text: string
}

export const ReminderSectionHeader: FunctionComponent<IReminderSectionHeaderProps> = (
  props: IReminderSectionHeaderProps
) => {
  const { text } = props
  return (
    <Col className='mb-3 d-flex justify-content-start p-0'>
      <Badge style={{ fontSize: '1.5em' }} variant='secondary'>
        {text}
      </Badge>
    </Col>
  )
}

export interface IMyTasksReminderBodyProps {
  body?: IUserNote[]
}

export const MyTasksReminderBody: FunctionComponent<IMyTasksReminderBodyProps> = (
  props: IMyTasksReminderBodyProps
) => {
  const { t } = useTranslation()
  const { body } = props

  return (
    <Col className='w-100'>
      <ReminderSectionHeader
        text={
          t('commons:menu:notes-today') + ' (' + `${body?.length || 0}` + ')'
        }
      />
      {body?.length ? (
        body.map((task, index) => (
          <Row key={index}>
            <Col>
              <ReminderCol item={'- ' + task.content} no={index} />
            </Col>
          </Row>
        ))
      ) : (
        <Row style={{ paddingBottom: '10px' }}>
          <Col>{t('commons:labels:noTasks')}</Col>
        </Row>
      )}
    </Col>
  )
}

export interface ISystemReminderBodyProps {
  body: { content: string; reminder: string }[]
}

export const SystemReminderBody: FunctionComponent<ISystemReminderBodyProps> = (
  props: ISystemReminderBodyProps
) => {
  const { t } = useTranslation()
  const { body } = props
  return (
    <Col className='w-100'>
      <ReminderSectionHeader text={t('commons:labels.system-reminders')} />
      {body.map((obj, index) => (
        <>
          {obj.reminder === '2020-10-10' && (
            // <Col >
            <ReminderCol
              label={t('commons:labels.reminder-content')}
              item={obj.content}
              no={index}
            />
            // </Col>
          )}
        </>
      ))}
    </Col>
  )
}

export interface ICustomersReminderBodyProps {
  body?: ICustomersReminder[]
}

export const CustomersReminderBody: FunctionComponent<ICustomersReminderBodyProps> = (
  props: ICustomersReminderBodyProps
) => {
  const { t } = useTranslation()
  const { body } = props

  return (
    <Col className='w-100'>
      <ReminderSectionHeader
        text={
          t('customers:labels.reminders-regarding-customers') +
          ' (' +
          `${body?.length || 0}` +
          ')'
        }
      />
      {body?.map((reminder: ICustomersReminder, index: number) => (
        <div key={index}>
          <Row>
            <Col className='d-flex justify-content-center mt-2 mb-3'>
              <ReminderHeader
                fullname={`${reminder.firstName} ${reminder.lastName}`}
                email={`${reminder.email}`}
                phone1={`${reminder.phone1}`}
                phone2={`${reminder.phone2}`}
              />
            </Col>
          </Row>
          {reminder.contacts.map((contactDate) => (
            <Row
              className='mb-2'
              key={contactDate.id}
              style={{ borderBottom: '1px solid grey' }}
            >
              <Col lg={6} md={12} sm={12}>
                <ReminderCol
                  label={t('customers:labels.previous-contact-date')}
                  item={contactDate.date}
                  no={index}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <ReminderCol
                  label={t('customers:labels.contact-date')}
                  item={contactDate.nextContactDate}
                  no={index}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <ReminderCol
                  label={t('customers:labels.previous-contact-content')}
                  item={contactDate.name}
                  no={index}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <ReminderCol
                  label={t('customers:labels.contact-content')}
                  item={contactDate.nextContactName}
                  no={index}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <ReminderCol
                  label={t('customers:labels.previous-comment')}
                  item={contactDate.comment}
                  no={index}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <ReminderCol
                  label={t('commons:labels.comment')}
                  item={contactDate.nextContactComment}
                  no={index}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <ReminderCol
                  label={t('customers:labels.previous-contact-kind')}
                  item={contactDate.type}
                  no={index}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <ReminderCol
                  label={t('customers:labels.contact-kind')}
                  item={''}
                  no={index}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <ReminderCol
                  label={t('commons:labels.trader-initials')}
                  item={contactDate.traderInitials}
                  no={index}
                />
              </Col>
            </Row>
          ))}
        </div>
      ))}
    </Col>
  )
}

export interface IReminderProps {
  customersBody?: ICustomersReminder[]
  buttonClick?: () => any
  myTasksBody?: IUserNote[]
  show: boolean
  title?: string
  subtitle?: string
  systemReminderBody?: { content: string; reminder: string }[]
  closeFunction?: () => void
  alerts?: IUserAlert[]
}

export const Reminder: FunctionComponent<IReminderProps> = (
  props: IReminderProps
) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    customersBody,
    buttonClick,
    myTasksBody,
    show,
    title = t('commons:labels.daily-reminder'),
    systemReminderBody,
    closeFunction,
    alerts,
  } = props
  const { token } = useSelector((state: RootState) => state.globalUser)

  const generateReminderPDF = useCallback(() => {
    if (token) {
      dispatch(fetchReminderPDFFile(token))
    }
  }, [dispatch, token])

  const printReminderPDF = useCallback(() => {
    if (token) {
      dispatch(fetchAndPrintReminderPDFFile(token))
    }
  }, [dispatch, token])

  return (
    <Modal
      dialogClassName='modal-50w'
      show={show}
      style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
    >
      <Modal.Header className='d-flex justify-content-center w-100'>
        <Modal.Title style={{ fontSize: 'small' }}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='main-bg-color'>
        <CustomersReminderBody body={customersBody} />
        {systemReminderBody?.length ? (
          <SystemReminderBody body={systemReminderBody} />
        ) : null}
        <MyTasksReminderBody body={myTasksBody} />
        {alerts?.length ? <MyAlertsReminderBody alerts={alerts} /> : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={generateReminderPDF}>
          {t('commons:actions.saveAsPDF')}
        </Button>
        <Button className='mr-auto' onClick={printReminderPDF}>
          {t('commons:actions.print')}
        </Button>
        <Button onClick={closeFunction}>{t('commons:actions.close')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Reminder
