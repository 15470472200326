import React, { FunctionComponent, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import MainButton from '../../commons/Inputs/MainButton'

import { useFormValidationNotEmpty } from '../../../hooks/useFormValidation'

interface IConfirmationModalProps {
  body: JSX.Element | string
  cancelAction: () => void
  externalValidated?: boolean
  submitAction?: (e: any) => void
  onHide: () => void
  show: boolean
  title: string
  submitText?: string
  onConfirmWithoutSubmit?: (e?: any) => void
  isUploading?: boolean
  submitWithoutReload?: boolean
}

export const ConfirmationModal: FunctionComponent<IConfirmationModalProps> = (
  props: IConfirmationModalProps
) => {
  const { t } = useTranslation()
  const {
    body,
    cancelAction,
    externalValidated,
    submitAction,
    onConfirmWithoutSubmit,
    onHide,
    show,
    title,
    submitText = t('commons:actions:confirm'),
    isUploading,
    submitWithoutReload,
  } = props
  const [validated] = useState(false)
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Form
          validated={
            externalValidated !== undefined ? externalValidated : validated
          }
          onSubmit={(e: any) => useFormValidationNotEmpty}
        >
          <Modal.Body>{body}</Modal.Body>
          {!submitWithoutReload && (
            <Modal.Footer>
              <Button variant='secondary' onClick={cancelAction}>
                {t('commons:actions:return')}
              </Button>
              {submitAction && (
                <MainButton isUploading={isUploading} onClick={submitAction}>
                  {submitText}
                </MainButton>
              )}
              {onConfirmWithoutSubmit && (
                <MainButton
                  isUploading={isUploading}
                  onClick={onConfirmWithoutSubmit}
                >
                  {submitText}
                </MainButton>
              )}
            </Modal.Footer>
          )}
        </Form>
        {submitWithoutReload && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button variant='secondary' onClick={cancelAction}>
              {t('commons:actions:return')}
            </Button>
            {submitAction && (
              <MainButton isUploading={isUploading} onClick={submitAction}>
                {submitText}
              </MainButton>
            )}
            {onConfirmWithoutSubmit && (
              <MainButton
                isUploading={isUploading}
                onClick={onConfirmWithoutSubmit}
              >
                {submitText}
              </MainButton>
            )}
          </div>
        )}
      </Modal>
    </>
  )
}

export default ConfirmationModal
