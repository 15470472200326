import * as React from 'react'
import { Button } from 'react-bootstrap'

import MainColForReportCheckbox from '../../commons/Containers/MainColForReportCheckbox'
import MainRow from '../../commons/Containers/MainRow'
import SimpleInputCheckbox3 from '../../commons/Inputs/SimpleInputs/SimpleInputCheckbox3'

import brokersNameIDName from '../../../mockups/brokersNameIDName.json'
import { useTranslation } from 'react-i18next'
import { useRaportsWithDatesContext } from './raportWithDatesContext'
import { updateBrokers } from './actions'
import { useCheckIfSmallResolution } from '../../../hooks/useCheckIfSmallResolution'
import { IListItem } from '../../../interfaces/commons'

export const Brokers = (): JSX.Element => {
  const { t } = useTranslation()
  const { state, dispatch } = useRaportsWithDatesContext()

  const brokersName = brokersNameIDName.map((broker) => broker.name)
  const selectAllBrokers = React.useCallback(() => {
    if (state.chosenBrokerNames.length) {
      return dispatch(updateBrokers([]))
    }
    dispatch(updateBrokers(brokersName))
  }, [brokersName, dispatch, state.chosenBrokerNames.length])

  const handleUpdateBrokers = React.useCallback(
    (traderInitial: string) => {
      const brokersIsSelected = state.chosenBrokerNames.includes(traderInitial)
      if (brokersIsSelected) {
        return dispatch(
          updateBrokers(
            state.chosenBrokerNames.filter((broker) => broker !== traderInitial)
          )
        )
      }
      dispatch(updateBrokers([...state.chosenBrokerNames, traderInitial]))
    },
    [dispatch, state.chosenBrokerNames]
  )

  const isOneColInRow = useCheckIfSmallResolution()

  return (
    <>
      <MainRow isRowContentCentered>
        <p className='icr-header mt-4'>{t('commons:labels.choose-brokers')}</p>
      </MainRow>
      <MainRow isRowContentCentered>
        <Button onClick={selectAllBrokers}>
          {t('commons:actions.select-deselect-all-brokers')}
        </Button>
      </MainRow>
      <MainRow isRowContentCentered>
        {brokersNameIDName?.map((broker: IListItem, index: number) => {
          const numberOfCols = brokersNameIDName.length - 1
          return broker.name.length === 1 ? null : (
            <MainColForReportCheckbox
              forLargeCheckbox
              numberOfCols={numberOfCols}
              isOneColInRow={isOneColInRow}
              key={broker.id + '.' + index}
            >
              <SimpleInputCheckbox3
                additionalOnChange={(): void =>
                  handleUpdateBrokers(broker?.name)
                }
                customLabelWidth={18}
                name={broker?.name}
                key={`${broker.id}. ${broker.name}-checkbox`}
                label={broker?.name}
                value={!!state.chosenBrokerNames.includes(broker?.name)}
                wordWrap
              />
            </MainColForReportCheckbox>
          )
        })}
      </MainRow>
    </>
  )
}
