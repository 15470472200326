import { gql } from 'graphql-request'
import { IGarage } from './garagesService'
import GraphqlService from './graphqlService'
import { IAdminPlace } from './placesService'

export interface IBuildingInput {
  name: string
  description: string
  stageID: number
  isTypeService: boolean
  nameIsNotVisible?: boolean
}

export interface IBuilding {
  id: number
  name: string
  nameIsNotVisible?: boolean
  description: string
  stageID: number
  garages: IGarage[]
  places: IAdminPlace[]
  isTypeService: boolean
  isAfterMigration?: boolean
}

export const getAllBuilidings = (): Promise<any> => {
  const FETCH_ALL_BUILDINGS = gql`
    query {
      buildings {
        id
        name
        isTypeService
        description
        stageID
        nameIsNotVisible
        garages {
          id
          isTypeService
          name
          description
          buildingID
          building {
            name
            isTypeService
            nameIsNotVisible
            stageID
          }
        }
        places {
          id
          isTypeService
        }
      }
    }
  `

  return GraphqlService.send(FETCH_ALL_BUILDINGS, {})
}

const getBuildingsByInvestmentsNames = (
  investmentsNames: string[],
  isTypeService?: boolean
): Promise<any> => {
  const FETCH_BUILDINGS_BY_INVESTMENTS_NAMES = gql`
    query($investmentsNames: [String!]!, $isTypeService: Boolean) {
      buildingsByInvestmentsNames(
        investmentsNames: $investmentsNames
        isTypeService: $isTypeService
      ) {
        id
        name
        description
        stageID
        isTypeService
        isAfterMigration
        nameIsNotVisible
        garages {
          buildingID
          id
          isTypeService
          name
          description
          isAfterMigration
          groundPlans {
            isAfterMigration
            id
            name
            length
            category
            created
            description
            migrationComment
            garageID
          }
        }
        places {
          id
          isTypeService
        }
      }
    }
  `

  return GraphqlService.send(FETCH_BUILDINGS_BY_INVESTMENTS_NAMES, {
    investmentsNames,
    isTypeService,
  })
}

export const addBuilding = (building: IBuildingInput): Promise<any> => {
  const ADD_BUILDING_QUERY = gql`
    mutation sc3($building: BuildingInput!) {
      saveBuilding(building: $building) {
        id
        name
        description
        stageID
        nameIsNotVisible
      }
    }
  `

  return GraphqlService.send(ADD_BUILDING_QUERY, { building })
}

export const deleteBuilding = (buildingID: number): Promise<any> => {
  const DELETE_BUILDING_QUERY = gql`
    mutation sc($buildingID: Float!) {
      deleteBuilding(buildingID: $buildingID)
    }
  `

  return GraphqlService.send(DELETE_BUILDING_QUERY, { buildingID })
}

export const updateBuilding = ({
  buildingID,
  building,
}: {
  buildingID: number
  building: IBuildingInput
}): Promise<any> => {
  const UPDATE_BUILDING_QUERY = gql`
    mutation sc4($buildingID: Float!, $building: BuildingInput!) {
      updateBuilding(buildingID: $buildingID, building: $building) {
        id
      }
    }
  `

  return GraphqlService.send(UPDATE_BUILDING_QUERY, { buildingID, building })
}

export default {
  getAllBuilidings,
  getBuildingsByInvestmentsNames,
  addBuilding,
  deleteBuilding,
  updateBuilding,
}
