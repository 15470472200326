import { ICustomerInput, IInterestedCustomer } from '../graphql/customers'
import { IContact } from '../graphql/contacts'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import InterestedCustomerService, {
  CustomerPaginationByKeyWordsResponse,
  CustomerPaginationByUnassignedCustomerEmail,
  IAddCustomerWithMarketingConsentsProposedInvestmentsAndContacts,
  IContactInput,
  INewCustomerVerificationQueryParameters,
  IUpdateContactInput,
  InterestedCustomerPaginationProps,
} from '../services/interestedCustomerService'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

export interface IInterestedCustomersState {
  blueListCustomers: IInterestedCustomer[]
  notBlueListCustomers: IInterestedCustomer[]
  interestedCustomers: IInterestedCustomer[]
  selectedInterestedCustomer: IInterestedCustomer | undefined | null
  lastFetchedCustomerID?: number
  contacts: IContact[]
  totalInterestedCustomers: number
  totalInterestedCustomersByKeyWords: number
  totalBlueListCustomers: number
  totalNotBlueListCustomers: number
  selectedInterestedCustomerID?: number
  isLoadingCustomerSelect: boolean
  isLoadingCustomers: boolean
  isSaveInterestedPending: boolean
  traderInitialsOfCustomer: string[]
}

const initialState: IInterestedCustomersState = {
  blueListCustomers: [] as IInterestedCustomer[],
  contacts: [] as IContact[],
  notBlueListCustomers: [] as IInterestedCustomer[],
  interestedCustomers: [] as IInterestedCustomer[],
  isLoadingCustomerSelect: false,
  isLoadingCustomers: false,
  selectedInterestedCustomerID: undefined,
  lastFetchedCustomerID: undefined,
  selectedInterestedCustomer: null,
  totalInterestedCustomers: 0,
  totalBlueListCustomers: 0,
  totalNotBlueListCustomers: 0,
  totalInterestedCustomersByKeyWords: 0,
  isSaveInterestedPending: false,
  traderInitialsOfCustomer: [],
}

export const fetchInterestedCustomersPagination = createAsyncThunk(
  'interestedCustomers/fetchInterestedCustomersPagination',
  async (props: InterestedCustomerPaginationProps) => {
    return InterestedCustomerService.fetchInterestedCustomersByKeywordPagination(
      props
    )
  }
)
export const fetchInterestedCustomersByUnassignedEmail = createAsyncThunk(
  'interestedCustomers/fetchInterestedCustomersByUnassignedEmail',
  async (props: InterestedCustomerPaginationProps) => {
    return InterestedCustomerService.fetchInterestedCustomersByUnassignedCustomerEmail(
      props
    )
  }
)

export const fetchBlueListCustomersPagination = createAsyncThunk(
  'interestedCustomers/fetchBlueListCustomersPagination',
  async (props: InterestedCustomerPaginationProps) => {
    return InterestedCustomerService.fetchInterestedCustomersByKeywordPagination(
      { ...props, isBlueList: true }
    )
  }
)

export const fetchNotBlueListCustomersPagination = createAsyncThunk(
  'interestedCustomers/fetchNotBlueListCustomersPagination',
  async (props: InterestedCustomerPaginationProps) => {
    return InterestedCustomerService.fetchInterestedCustomersPagination({
      ...props,
      isBlueList: false,
    })
  }
)

export const addNewInterestedCustomer = createAsyncThunk(
  'interestedCustomers/addNewInterestedCustomer',
  async (variables: { customer: IInterestedCustomer }) => {
    return InterestedCustomerService.addNewInterestedCustomer(variables)
  }
)

export const anonymizeInterestedCustomer = createAsyncThunk(
  'interestedCustomers/anonymizeInterestedCustomer',
  async (customerID?: number) => {
    if (customerID) {
      return InterestedCustomerService.anonymizeInterestedCustomer(customerID)
    }
  }
)

export const updateInterestedCustomer = createAsyncThunk(
  'interestedCustomers/updateInterestedCustomer',
  async (variables: {
    customer: Partial<ICustomerInput>
    customerID: number
  }) => {
    return InterestedCustomerService.updateInterestedCustomer(variables)
  }
)

export const deleteInterestedCustomer = createAsyncThunk(
  'interestedCustomers/deleteInterestedCustomer',
  async (customerID?: number) => {
    if (customerID) {
      return InterestedCustomerService.deleteInterestedCustomer(customerID)
    }
  }
)

export const fetchInterestedCustomersByContractID = createAsyncThunk(
  'interestedCustomers/fetchInterestedCustomersByContractID',
  async (contractID: number[]) =>
    InterestedCustomerService.fetchInterestedCustomersByContractID(contractID)
)

export const updateInterestedCustomerContact = createAsyncThunk(
  'interestedCustomers/updateInterestedCustomerContact',
  async (parameters: IUpdateContactInput) => {
    return InterestedCustomerService.updateInterestedCustomerContact(parameters)
  }
)

export const deleteInterestedCustomerContact = createAsyncThunk(
  'interestedCustomers/deleteInterestedCustomerContact',
  async (contactID: number) => {
    return InterestedCustomerService.deleteInterestedCustomerContact(contactID)
  }
)

export const addInterestedCustomerContact = createAsyncThunk(
  'interestedCustomers/deleteInterestedCustomerContact',
  async (contact: IContactInput) => {
    return InterestedCustomerService.addInterestedCustomerContact(contact)
  }
)

export const addCustomerWithMarketingConsentsProposedInvestmentsAndContacts = createAsyncThunk(
  'interestedCustomers/addCustomerWithMarketingConsentsProposedInvestmentsAndContacts',
  async (
    props: IAddCustomerWithMarketingConsentsProposedInvestmentsAndContacts
  ) => {
    return InterestedCustomerService.addCustomerWithMarketingConsentsProposedInvestmentsAndContacts(
      props
    )
  }
)

export const getInterestedCustomerByID = createAsyncThunk(
  'interestedCustomers/getInterestedCustomerByID',
  async (customerID: number) => {
    return {
      customer: await InterestedCustomerService.getInterestedCustomerByID(
        customerID
      ),
      lastFetchedCustomerID: customerID,
    }
  }
)

export const performNewCustomerVerification = createAsyncThunk(
  'interestedCustomers/newCustomerVerification',
  async (params: INewCustomerVerificationQueryParameters) => {
    return InterestedCustomerService.newCustomerVerification(params)
  }
)

export const getTraderInitialsOfCustomer = createAsyncThunk(
  'interestedCustomers/getTraderInitialsOfCustomer',
  async () => {
    return InterestedCustomerService.getTraderInitialsOfCustomer()
  }
)

export const getBlueListCustomerUpdateVariables = (
  customerID: number,
  customer: ICustomerInput,
  isBlue: boolean
): { customerID: number; customer: ICustomerInput } => {
  return {
    customerID: customerID,
    customer: {
      customerType: customer?.customerType ? customer.customerType : '',
      lastName: customer?.lastName ? customer.lastName : '',
      firstName: customer?.firstName ? customer.firstName : '',
      secondName: customer?.secondName ? customer.secondName : '',
      maidenName: customer?.maidenName ? customer.maidenName : '',
      parentsNames: customer?.parentsNames ? customer.parentsNames : '',
      pesel: customer?.pesel ? customer.pesel : '',
      taxNumber: customer?.taxNumber ?? '',
      sex: customer?.sex ? customer.sex : '',
      phone1: customer?.phone1 ? customer.phone1 : '',
      phone2: customer?.phone2 ? customer.phone2 : '',
      email: customer?.email ? customer.email : '',
      email2: customer?.email2 ? customer.email2 : '',
      billNumber: customer?.billNumber ? customer.billNumber : '',
      passportNumberAndSeries: customer?.passportNumberAndSeries
        ? customer.passportNumberAndSeries
        : '',
      IDNumberAndSeries: customer?.IDNumberAndSeries
        ? customer.IDNumberAndSeries
        : '',
      citizenship: customer?.citizenship ? customer.citizenship : '',
      isBusiness: Boolean(customer?.isBusiness) ?? false,
      businessName: customer?.businessName ? customer.businessName : '',
      regon: customer?.regon ? customer.regon : '',
      businessAddressName: customer?.businessAddressName
        ? customer.businessAddressName
        : '',
      businessAddressSurname: customer?.businessAddressSurname
        ? customer.businessAddressSurname
        : '',
      businessAddressBusinessName: customer?.businessAddressBusinessName
        ? customer.businessAddressBusinessName
        : '',
      businessAddressStreet: customer?.businessAddressStreet
        ? customer.businessAddressStreet
        : '',
      businessAddressNumber: customer?.businessAddressNumber
        ? customer.businessAddressNumber
        : '',
      businessAddressCountry: customer?.businessAddressCountry
        ? customer.businessAddressCountry
        : '',
      businessAddressCity: customer?.businessAddressCity
        ? customer.businessAddressCity
        : '',
      businessAddressPostalCode: customer?.businessAddressPostalCode
        ? customer.businessAddressPostalCode
        : '',
      businessAddressVoivodeship: customer?.businessAddressVoivodeship
        ? customer.businessAddressVoivodeship
        : '',
      businessAddressIsForeign: customer?.businessAddressIsForeign
        ? customer.businessAddressIsForeign
        : false,
      residenceName: customer?.residenceName ? customer.residenceName : '',
      residenceSurname: customer?.residenceSurname
        ? customer.residenceSurname
        : '',
      residenceBusinessName: customer?.residenceBusinessName
        ? customer.residenceBusinessName
        : '',
      residenceCountry: customer?.residenceCountry
        ? customer.residenceCountry
        : '',
      residenceCity: customer?.residenceCity ? customer.residenceCity : '',
      residenceStreet: customer?.residenceStreet
        ? customer.residenceStreet
        : '',
      residenceNumber: customer?.residenceNumber
        ? customer.residenceNumber
        : '',
      residencePostalCode: customer?.residencePostalCode
        ? customer.residencePostalCode
        : '',
      residenceVoivodeship: customer?.residenceVoivodeship
        ? customer.residenceVoivodeship
        : '',
      residenceIsForeign: customer?.residenceIsForeign
        ? customer.residenceIsForeign
        : false,
      correspondenceName: customer?.correspondenceName
        ? customer.correspondenceName
        : '',
      correspondenceSurname: customer?.correspondenceSurname
        ? customer.correspondenceSurname
        : '',
      correspondenceBusinessName: customer?.correspondenceBusinessName
        ? customer.correspondenceBusinessName
        : '',
      correspondenceCountry: customer?.correspondenceCountry
        ? customer.correspondenceCountry
        : '',
      correspondenceCity: customer?.correspondenceCity
        ? customer.correspondenceCity
        : '',
      correspondenceStreet: customer?.correspondenceStreet
        ? customer.correspondenceStreet
        : '',
      correspondenceNumber: customer?.correspondenceNumber
        ? customer.correspondenceNumber
        : '',
      correspondencePostalCode: customer?.correspondencePostalCode
        ? customer.correspondencePostalCode
        : '',
      correspondenceVoivodeship: customer?.correspondenceVoivodeship
        ? customer.correspondenceVoivodeship
        : '',
      correspondenceIsForeign: customer?.correspondenceIsForeign
        ? customer.correspondenceIsForeign
        : false,
      maritalStatus: customer?.maritalStatus ? customer.maritalStatus : '',
      purchasePurpose: customer?.purchasePurpose
        ? customer.purchasePurpose
        : '',
      businessType: customer?.businessType ? customer.businessType : '',
      placeShares: customer?.placeShares ?? '',
      sourceInfo: customer?.sourceInfo ? customer.sourceInfo : '',
      isBroker: customer?.isBroker ? customer.isBroker : false,
      brokerName: customer?.customerType ? customer.customerType : '',
      isIKCustomer: customer?.isIKCustomer ? customer.isIKCustomer : false,
      isToughCustomer: customer?.isToughCustomer
        ? customer.isToughCustomer
        : false,
      isVIP: customer?.isVIP ? customer.isVIP : false,
      isBlueList: isBlue,
      traderInitials: customer?.customerType ? customer.customerType : '',
      isCommissionDivision: customer?.isCommissionDivision
        ? customer.isCommissionDivision
        : false,
      commissionDivision: customer?.commissionDivision
        ? customer.commissionDivision
        : '',
      isAfterMigration: customer?.isAfterMigration ?? true,
    },
  }
}

const interestedCustomersSlice = createSlice({
  name: 'interestedCustomers',
  initialState,
  reducers: {
    setCurrentInterestedCustomer(
      state,
      action: PayloadAction<IInterestedCustomer | undefined | null>
    ): void {
      state.selectedInterestedCustomer = action.payload
    },
    setCurrentInterestedCustomerID(
      state,
      action: PayloadAction<number | undefined>
    ): void {
      state.selectedInterestedCustomerID = action.payload
    },
    clearInterestedCustomers(state, _: PayloadAction<void>): void {
      state.interestedCustomers = []
    },
  },
  extraReducers: {
    [fetchInterestedCustomersPagination.pending.toString()]: (
      state,
      _
    ): void => {
      state.interestedCustomers = []
      state.isLoadingCustomers = true
    },
    [fetchInterestedCustomersPagination.fulfilled.toString()]: (
      state,
      action: PayloadAction<CustomerPaginationByKeyWordsResponse>
    ): void => {
      if (action.payload && action.payload.paginateCustomersByKeyWords) {
        state.interestedCustomers =
          action.payload.paginateCustomersByKeyWords.customers
        state.totalInterestedCustomers =
          action.payload.paginateCustomersByKeyWords.total
        state.isLoadingCustomers = false
      }
    },
    [fetchInterestedCustomersPagination.rejected.toString()]: (
      state,
      _
    ): void => {
      state.interestedCustomers = []
      state.totalInterestedCustomers = 0
      state.isLoadingCustomers = false
    },
    [fetchInterestedCustomersByUnassignedEmail.pending.toString()]: (
      state,
      _
    ): void => {
      state.interestedCustomers = []
      state.isLoadingCustomers = true
    },
    [fetchInterestedCustomersByUnassignedEmail.fulfilled.toString()]: (
      state,
      action: PayloadAction<CustomerPaginationByUnassignedCustomerEmail>
    ): void => {
      if (
        action.payload &&
        action.payload.paginateCustomersByUnassignedCustomerEmail
      ) {
        state.interestedCustomers =
          action.payload.paginateCustomersByUnassignedCustomerEmail.customers
        state.totalInterestedCustomers =
          action.payload.paginateCustomersByUnassignedCustomerEmail.total
        state.isLoadingCustomers = false
      }
    },
    [fetchInterestedCustomersByUnassignedEmail.rejected.toString()]: (
      state,
      _
    ): void => {
      state.interestedCustomers = []
      state.totalInterestedCustomers = 0
      state.isLoadingCustomers = false
    },
    [fetchBlueListCustomersPagination.fulfilled.toString()]: (
      state,
      action: PayloadAction<CustomerPaginationByKeyWordsResponse>
    ): void => {
      if (action.payload && action.payload.paginateCustomersByKeyWords) {
        state.blueListCustomers =
          action.payload.paginateCustomersByKeyWords.customers
        state.totalBlueListCustomers =
          action.payload.paginateCustomersByKeyWords.total
      }
    },
    [fetchBlueListCustomersPagination.rejected.toString()]: (
      state,
      _
    ): void => {
      state.blueListCustomers = []
      state.totalBlueListCustomers = 0
    },
    [fetchNotBlueListCustomersPagination.fulfilled.toString()]: (
      state,
      action: PayloadAction<CustomerPaginationByKeyWordsResponse>
    ): void => {
      if (action.payload && action.payload.paginateCustomersByKeyWords) {
        state.notBlueListCustomers =
          action.payload.paginateCustomersByKeyWords.customers
        state.totalNotBlueListCustomers =
          action.payload.paginateCustomersByKeyWords.total
      }
    },
    [fetchNotBlueListCustomersPagination.rejected.toString()]: (
      state,
      _
    ): void => {
      state.notBlueListCustomers = []
      state.totalNotBlueListCustomers = 0
    },
    [getInterestedCustomerByID.fulfilled.toString()]: (state, action): void => {
      state.selectedInterestedCustomer = action.payload.customer.customer
      state.lastFetchedCustomerID = action.payload.lastFetchedCustomerID
      state.isLoadingCustomerSelect = false
    },
    [getInterestedCustomerByID.pending.toString()]: (state): void => {
      state.isLoadingCustomerSelect = true
    },
    [getInterestedCustomerByID.rejected.toString()]: (state): void => {
      state.isLoadingCustomerSelect = false
    },
    [anonymizeInterestedCustomer.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:anonymizeInterestedCustomer')))
    },
    [anonymizeInterestedCustomer.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:anonymizeInterestedCustomerError')))
    },
    [deleteInterestedCustomer.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:removeInterestedCustomer')))
    },
    [deleteInterestedCustomer.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:removeInterestedCustomerError')))
    },
    [updateInterestedCustomer.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:editPerson')))
    },
    [updateInterestedCustomer.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:editPersonError')))
    },
    [updateInterestedCustomerContact.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:updateEvent')))
    },
    [updateInterestedCustomerContact.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:updateEventError')))
    },
    [deleteInterestedCustomerContact.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:deleteEvent')))
    },
    [deleteInterestedCustomerContact.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:deleteEventError')))
    },
    [addInterestedCustomerContact.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:saveEvent')))
    },
    [addInterestedCustomerContact.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:saveEventError')))
    },
    [addCustomerWithMarketingConsentsProposedInvestmentsAndContacts.fulfilled.toString()]: (
      state
    ): void => {
      state.isSaveInterestedPending = false
      successToastNotify(String(i18n.t('toast:newInterested')))
    },

    [addCustomerWithMarketingConsentsProposedInvestmentsAndContacts.pending.toString()]: (
      state
    ): void => {
      state.isSaveInterestedPending = true
    },
    [addCustomerWithMarketingConsentsProposedInvestmentsAndContacts.rejected.toString()]: (
      state
    ): void => {
      state.isSaveInterestedPending = false
      errorToastNotify(String(i18n.t('toast:newInterestedError')))
    },
    [getTraderInitialsOfCustomer.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.traderInitialsOfCustomer = action.payload.traderInitialsOfCustomer
    },
    [getTraderInitialsOfCustomer.rejected.toString()]: (
      state,
      action
    ): void => {
      state.traderInitialsOfCustomer = []
    },
    [fetchInterestedCustomersByContractID.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.interestedCustomers = action.payload.customersByContractID
    },
    [fetchInterestedCustomersByContractID.rejected.toString()]: (
      state,
      _
    ): void => {
      errorToastNotify(
        String(i18n.t('toast:fetchInterestedCustomersByContractIDError'))
      )
      state.interestedCustomers = []
    },
  },
})

export const {
  setCurrentInterestedCustomer,
  setCurrentInterestedCustomerID,
  clearInterestedCustomers,
} = interestedCustomersSlice.actions
export default interestedCustomersSlice.reducer
