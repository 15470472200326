import { IPlaceContractsQuery } from './../graphql/currentPlaceContracts/currentPlaceContracts'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IInvestment, IStage } from '../graphql/investments'
import { IContract } from '../graphql/contracts/contracts'
import InvestmentService from '../services/investmentService'
import PlacesService, { PlacesPaginationProps } from '../services/placesService'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

interface IGlobalInvestment {
  investmentsList: IInvestment[]
  isLoadingInvestmentsOrStages: boolean
  chosenInvestment: IInvestment
  chosenStage: IStage
  amountOfPlacesContracts: number
  placesContracts: IContract[]
  chosenPlacesContract: IPlaceContractsQuery | null
  isArchiveMode: boolean
  isLoading: boolean
  isGroundPlanLoading: boolean
}

const initialState: IGlobalInvestment = {
  chosenInvestment: {
    id: -3,
    name: 'Wybierz inwestycję z listy',
    stages: [],
    isArchive: true,
  },
  investmentsList: [],
  isLoadingInvestmentsOrStages: false,
  placesContracts: [],
  amountOfPlacesContracts: 0,
  chosenStage: {
    id: -1,
    name: 'Wszystkie',
    street: '',
    investmentID: -1,
    buildings: [],
    isAfterMigration: true,
    isTypeService: false,
  },
  chosenPlacesContract: null,
  isArchiveMode: false,
  isLoading: false,
  isGroundPlanLoading: false,
}

export const fetchInvestmentsWithStagesAndBuildings = createAsyncThunk(
  'investments/fetchInvestmentsWithStagesAndBuildings',
  async () => {
    return InvestmentService.getInvestmentsWithStagesAndBuildings()
  }
)

export const fetchInvestmentsWithStagesOnly = createAsyncThunk(
  'investments/fetchInvestmentsWithStagesOnly',
  async () => {
    return InvestmentService.getInvestmentsWithStagesOnly()
  }
)

export const fetchPlacesContractsByInvestmentNameForMainTableActiveContracts = createAsyncThunk(
  'places/fetchPlacesContractsByInvestmentNameForMainTableActiveContracts',
  async (props: PlacesPaginationProps) => {
    return PlacesService.getPlacesByInvestmentNameForMainTableActiveContracts(
      props
    )
  }
)

export const fetchGroundPlanContent = createAsyncThunk(
  'investments/fetchGroundPlanContent',
  async (fileID: number) => {
    return PlacesService.getGroundPlanContent(fileID)
  }
)

export const addInvestment = createAsyncThunk(
  'investments/addInvestment',
  async (variables: { investment: { name: string } }, thunkAPI) => {
    const response = await InvestmentService.addInvestment(variables)
    await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly())
    return response
  }
)

export const updateInvestment = createAsyncThunk(
  'investments/updateInvestment',
  async (variables: { investment: { name: string } }, thunkAPI) => {
    const response = await InvestmentService.updateInvestment(variables)
    await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly())
    return response
  }
)

export const archiveInvestment = createAsyncThunk(
  'investments/archiveInvestment',
  async (variables: { investmentID: number }, thunkAPI) => {
    const response = await InvestmentService.archiveInvestment(variables)
    await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly())
    return response
  }
)
export const archiveInvestmentStage = createAsyncThunk(
  'investments/archiveInvestmentStage',
  async (variables: { stageID: number }, thunkAPI) => {
    const response = await InvestmentService.archiveInvestmentStage(variables)
    await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly())
    return response
  }
)

export const removeInvestment = createAsyncThunk(
  'investments/removeInvestment',
  async (variables: { investmentID: number }, thunkAPI) => {
    const response = await InvestmentService.removeInvestment(variables)
    await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly())
    return response
  }
)

export const addInvestmentStage = createAsyncThunk(
  'investments/addInvestmentStage',
  async (variables: { stage: Partial<IStage> }, thunkAPI) => {
    const response = await InvestmentService.addInvestmentStage(variables)
    await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly())
    return response
  }
)

export const updateInvestmentStage = createAsyncThunk(
  'investments/updateInvestmentStage',
  async (variables: { stage: Partial<IStage>; stageID: number }, thunkAPI) => {
    const response = await InvestmentService.updateInvestmentStage(variables)
    await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly())
    return response
  }
)

export const removeInvestmentStage = createAsyncThunk(
  'investments/removeInvestmentStage',
  async (variables: { stageID: number }, thunkAPI) => {
    const response = await InvestmentService.removeInvestmentStage(variables)
    await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly())
    return response
  }
)

const globalInvestmentSlice = createSlice({
  name: 'globalInvestment',
  initialState,
  reducers: {
    setGlobalChosenInvestment(state, action): void {
      if (state.chosenInvestment?.id !== action?.payload?.id) {
        state.chosenInvestment = action?.payload
        state.chosenStage = initialState?.chosenStage
      }
    },
    setInvestmentsList(state, action): void {
      state.investmentsList = action.payload
    },
    setChosenPlacesContract(state, action): void {
      state.chosenPlacesContract = action.payload
    },
    setGlobalChosenStage(state, action): void {
      state.chosenStage = action.payload
    },
    switchArchiveMode(state): void {
      if (state.isArchiveMode && state.chosenInvestment.isArchive) {
        const firstNotArchivedInvestment =
          state.investmentsList.find((investment) => !investment.isArchive) ||
          state.investmentsList[0]
        state.chosenInvestment = firstNotArchivedInvestment
        if (firstNotArchivedInvestment.stages.length) {
          state.chosenStage = firstNotArchivedInvestment.stages[0]
        }
      }
      state.isArchiveMode = !state.isArchiveMode
    },
    clearPlacesContracts(state): void {
      state.placesContracts = []
    },
  },
  extraReducers: {
    [fetchInvestmentsWithStagesAndBuildings.fulfilled.toString()]: (
      state,
      action
    ): void => {
      const investments = action.payload.investments
      const invSortedByName: IInvestment[] = investments
        .slice()
        .sort((a: IInvestment, b: IInvestment) => (a.name > b.name ? 1 : -1))
      invSortedByName.push({
        id: 0,
        name: 'Umowy anulowane',
        stages: [],
        isArchive: false,
      })
      invSortedByName.push({
        id: -1,
        name: 'Wszystkie',
        stages: [],
        isArchive: false,
      })
      const fetchedInvestmentListContainsPersistChosenInvestment = invSortedByName.find(
        (investment: IInvestment) =>
          investment?.id === state.chosenInvestment?.id &&
          investment.name === state.chosenInvestment?.name
      )
      const fetchedInvestmentListStagesContainsPersistChosenStage = fetchedInvestmentListContainsPersistChosenInvestment
        ? state.chosenStage.id === -1
          ? true
          : fetchedInvestmentListContainsPersistChosenInvestment.stages.find(
              (stage) =>
                stage.id === state.chosenStage.id &&
                stage.name === state.chosenStage.name
            )
        : false
      const fetchedInvestmentNotContainsPersistentChosenData =
        !fetchedInvestmentListContainsPersistChosenInvestment ||
        !fetchedInvestmentListStagesContainsPersistChosenStage
      if (fetchedInvestmentNotContainsPersistentChosenData) {
        state.chosenInvestment =
          invSortedByName.length > 0
            ? invSortedByName[0]
            : initialState.chosenInvestment
        state.chosenStage = initialState.chosenStage
      } else if (
        !fetchedInvestmentNotContainsPersistentChosenData &&
        fetchedInvestmentListContainsPersistChosenInvestment
      ) {
        state.chosenInvestment = fetchedInvestmentListContainsPersistChosenInvestment
      }
      state.investmentsList = invSortedByName
      state.isLoadingInvestmentsOrStages = false
    },
    [fetchInvestmentsWithStagesAndBuildings.pending.toString()]: (
      state
    ): void => {
      state.isLoadingInvestmentsOrStages = true
    },
    [fetchInvestmentsWithStagesAndBuildings.rejected.toString()]: (
      state
    ): void => {
      state.isLoadingInvestmentsOrStages = false
    },
    [fetchGroundPlanContent.fulfilled.toString()]: (state): void => {
      state.isGroundPlanLoading = false
    },
    [fetchGroundPlanContent.pending.toString()]: (state): void => {
      state.isGroundPlanLoading = true
    },
    [fetchGroundPlanContent.rejected.toString()]: (state): void => {
      state.isGroundPlanLoading = false
    },
    [fetchInvestmentsWithStagesOnly.fulfilled.toString()]: (
      state,
      action
    ): void => {
      const investments = action.payload.investments
      const invSortedByName: IInvestment[] = investments
        .slice()
        .sort((a: IInvestment, b: IInvestment) => (a.name > b.name ? 1 : -1))
      invSortedByName.push({
        id: 0,
        name: 'Umowy anulowane',
        stages: [],
        isArchive: false,
      })
      invSortedByName.push({
        id: -1,
        name: 'Wszystkie',
        stages: [],
        isArchive: false,
      })
      const fetchedInvestmentListContainsPersistChosenInvestment = invSortedByName.find(
        (investment: IInvestment) =>
          investment?.id === state.chosenInvestment?.id &&
          investment.name === state.chosenInvestment?.name
      )
      const fetchedInvestmentListStagesContainsPersistChosenStage = fetchedInvestmentListContainsPersistChosenInvestment
        ? state.chosenStage.id === -1
          ? true
          : fetchedInvestmentListContainsPersistChosenInvestment.stages.find(
              (stage) =>
                stage.id === state.chosenStage.id &&
                stage.name === state.chosenStage.name
            )
        : false
      const fetchedInvestmentNotContainsPersistentChosenData =
        !fetchedInvestmentListContainsPersistChosenInvestment ||
        !fetchedInvestmentListStagesContainsPersistChosenStage
      if (fetchedInvestmentNotContainsPersistentChosenData) {
        const filterInvestments = invSortedByName.filter(
          (investment: IInvestment) =>
            investment.isArchive === state.isArchiveMode
        )
        state.chosenInvestment =
          invSortedByName.length > 0
            ? filterInvestments[0]
            : initialState.chosenInvestment
        state.chosenStage = initialState.chosenStage
      } else if (
        !fetchedInvestmentNotContainsPersistentChosenData &&
        fetchedInvestmentListContainsPersistChosenInvestment
      ) {
        state.chosenInvestment = fetchedInvestmentListContainsPersistChosenInvestment
      }
      state.investmentsList = invSortedByName
      state.isLoadingInvestmentsOrStages = false
    },
    [fetchInvestmentsWithStagesOnly.pending.toString()]: (state): void => {
      state.isLoadingInvestmentsOrStages = true
    },
    [fetchInvestmentsWithStagesOnly.rejected.toString()]: (state): void => {
      state.isLoadingInvestmentsOrStages = false
    },
    [fetchPlacesContractsByInvestmentNameForMainTableActiveContracts.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.placesContracts =
        action.payload.paginatedPlacesByInvestmentsNamesAndKeyWords.places
      state.amountOfPlacesContracts =
        action.payload.paginatedPlacesByInvestmentsNamesAndKeyWords.total
      state.isLoading = false
    },
    [fetchPlacesContractsByInvestmentNameForMainTableActiveContracts.pending.toString()]: (
      state
    ): void => {
      state.isLoading = true
    },
    [fetchPlacesContractsByInvestmentNameForMainTableActiveContracts.rejected.toString()]: (
      state
    ): void => {
      state.isLoading = false
    },
    [addInvestment.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:addInvestment')))
      state.isLoadingInvestmentsOrStages = false
    },
    [addInvestment.pending.toString()]: (state): void => {
      state.isLoadingInvestmentsOrStages = true
    },
    [addInvestment.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:addInvestmentError')))
      state.isLoadingInvestmentsOrStages = false
    },
    [addInvestmentStage.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:addStage')))
      state.isLoadingInvestmentsOrStages = false
    },
    [addInvestmentStage.pending.toString()]: (state): void => {
      state.isLoadingInvestmentsOrStages = true
    },
    [addInvestmentStage.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:addStageError')))
      state.isLoadingInvestmentsOrStages = false
    },
    [removeInvestment.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:deleteInvestment')))
      state.isLoadingInvestmentsOrStages = false
    },
    [removeInvestment.pending.toString()]: (state): void => {
      state.isLoadingInvestmentsOrStages = true
    },
    [removeInvestment.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:deleteInvestmentError')))
      state.isLoadingInvestmentsOrStages = false
    },
    [removeInvestmentStage.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:deleteStage')))
      state.isLoadingInvestmentsOrStages = false
    },
    [removeInvestmentStage.pending.toString()]: (state): void => {
      state.isLoadingInvestmentsOrStages = true
    },
    [removeInvestmentStage.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:deleteStageError')))
      state.isLoadingInvestmentsOrStages = false
    },
    [updateInvestment.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:editInvestment')))
      state.isLoadingInvestmentsOrStages = false
    },
    [updateInvestment.pending.toString()]: (state): void => {
      state.isLoadingInvestmentsOrStages = true
    },
    [updateInvestment.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:editInvestmentError')))
      state.isLoadingInvestmentsOrStages = false
    },
    [archiveInvestment.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:archiveInvestment')))
      state.isLoadingInvestmentsOrStages = false
    },
    [archiveInvestment.pending.toString()]: (state): void => {
      state.isLoadingInvestmentsOrStages = true
    },
    [archiveInvestment.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:archiveInvestmentError')))
      state.isLoadingInvestmentsOrStages = false
    },
    [updateInvestmentStage.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:editStage')))
      state.isLoadingInvestmentsOrStages = false
    },
    [updateInvestmentStage.pending.toString()]: (state): void => {
      state.isLoadingInvestmentsOrStages = true
    },
    [updateInvestmentStage.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:editStageError')))
      state.isLoadingInvestmentsOrStages = false
    },
    [archiveInvestmentStage.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:archiveStage')))
      state.isLoadingInvestmentsOrStages = false
    },
    [archiveInvestmentStage.pending.toString()]: (state): void => {
      state.isLoadingInvestmentsOrStages = true
    },
    [archiveInvestmentStage.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:archiveStageError')))
      state.isLoadingInvestmentsOrStages = false
    },
  },
})

export const {
  clearPlacesContracts,
  setGlobalChosenInvestment,
  setInvestmentsList,
  setGlobalChosenStage,
  setChosenPlacesContract,
  switchArchiveMode,
} = globalInvestmentSlice.actions

export default globalInvestmentSlice.reducer
