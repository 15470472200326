export const departmentNamesMeta = [
  /*  { key : 'ADM', label : 'Administrator IT [ADM]' }, */
  { key: 'DAR', text: 'Dział Aranżacji [DAR]' },
  { key: 'DKO', text: 'Dział Kontroli i Serwisu [DKO]' },
  /*  { key : 'DPI', label : 'Dział Przygotowania Inwestycji [DPI]' }, */
  { key: 'DKS', text: 'Dział Księgowości [DKS]' },
  { key: 'DMA', text: 'Dział Marketingu [DMA] (podstawowy)' },
  /* { key : 'DMA-ADM', label : 'Dział Marketingu [DMA] (administrator)' }, */
  { key: 'DNI', text: 'Dział Nieruchomości [DNI]' },
  { key: 'DNK', text: 'Dział Nieruchomości Komercyjnych [DNK]' },
  { key: 'DPP', label: 'Dział Przygotowania Produkcji [DPP]' },
  { key: 'DSP', text: 'Dział Sprzedaży [DSP] (podstawowy)' },
  { key: 'DSP-ADM', text: 'Dział Sprzedaży [DSP ADM] (administrator)' },
  /*  { key : 'DYR', label : 'Dyrekcja [DYR]' }, */
  { key: 'SEK', text: 'Sekretariat [SEK]' },
  { key: 'SKA', label: 'Sekcja Kontroli i Analiz [SKA]' },
]

export const DSPandDSPADMandDNK = [
  { id: 1, label: 'OSOBY FIZYCZNE', buttonID: 'natural-people' },
  { id: 2, label: 'PODMIOTY GOSPODARCZE', buttonID: 'legal-people' },
]

export const DARreportsMeta = [
  { id: 1, label: 'ARANŻACJE', buttonID: 'arrangements-report' },
  { id: 2, label: 'ANEKSY DAT', buttonID: 'date-annexes-report' },
  { id: 3, label: 'LISTA', buttonID: 'list-report' },
]

export const DKOreportsMeta = [
  { id: 1, label: 'STATUSY WYKONANIA', buttonID: 'execution-statuses-report' },
  { id: 2, label: 'LISTA MIESZKAŃ', buttonID: 'places-list-report' },
  { id: 3, label: 'POWIERZCHNIE LOKALI', buttonID: 'places-area-report' },
]

export const DPPReportsMeta = [
  { id: 1, label: 'LISTA MIESZKAŃ', buttonID: 'places-list-report' },
  { id: 2, label: 'ZESTAWIENIE LOKALI', buttonID: 'places-tally-report' },
]

export const DKSReportsMeta = [
  {
    id: 1,
    label: 'Lokale na działalność',
    buttonID: 'dks-places-legal-person-report',
  },
]

export const DNIreportsMeta = [
  { id: 1, label: 'UDZIAŁY', buttonID: 'shares-report' },
  { id: 2, label: 'ODBIORY', buttonID: 'receptions-report' },
  { id: 3, label: 'AKTY', buttonID: 'acts-report' },
  { id: 4, label: 'DLA KANCELARII', buttonID: 'offices-report' },
  {
    id: 5,
    label: 'DLA ADMINISTRATORA',
    buttonID: 'building-administrators-report',
  },
]

export const DNKreportsMeta = [
  { id: 1, label: 'RAPORT DZIENNY', buttonID: 'daily-sales-department-report' },
  { id: 3, label: 'ZAWARTE UMOWY', buttonID: 'concluded-contracts-report' },
  { id: 4, label: 'BŁĘKITNA LISTA', buttonID: 'tough-sales-report' },
  { id: 6, label: 'UMOWY ANULOWANE', buttonID: 'cancelled-contracts-report' },
  {
    id: 2,
    label: 'ZAINTERESOWANI ŹRÓDŁA INFORMACJI',
    buttonID: 'interested-customers-report',
  },
  {
    id: 7,
    label: 'ZAINTERESOWANI OD POŚREDNIKÓW',
    buttonID: 'interested-customers-broker-reports',
  },
  {
    id: 8,
    label: 'ZAINTERESOWANI UMOWY OD POŚREDNIKÓW',
    buttonID: 'interested-customers-broker-contracts-reports',
  },
  {
    id: 9,
    label: 'ZAINTERESOWANI WG. HANDLOWCOW',
    buttonID: 'interested-customers-traders-report',
  },
  {
    id: 10,
    label: 'ZAINTERESOWANI DNK',
    buttonID: 'interested-customers-daily-report',
  },
  { id: 11, label: 'RAPORT ZBIORCZY', buttonID: 'summary-report-dsp' },
  {
    id: 12,
    label: 'ZAINTERESOWANI - REZYGNACJE',
    buttonID: 'customer-resignation-report',
  },
  { id: 2, label: 'LISTA MIESZKAŃ', buttonID: 'places-list-report' },
  {
    id: 13,
    label: 'ZAINTERESOWANI - ŹRÓDŁA UMOWY',
    buttonID: 'customer-source-contract-report',
  },
]

export const DSPandDSPADMreportsMeta = [
  { id: 1, label: 'RAPORT DZIENNY', buttonID: 'daily-sales-department-report' },
  { id: 2, label: 'ZAWARTE UMOWY', buttonID: 'concluded-contracts-report' },
  {
    id: 3,
    label: 'ZAINTERESOWANI ŹRÓDŁA INFORMACJI',
    buttonID: 'interested-customers-report',
  },
  {
    id: 4,
    label: 'ZAINTERESOWANI - ŹRÓDŁA UMOWY',
    buttonID: 'customer-source-contract-report',
  },
  {
    id: 5,
    label: 'ZAINTERESOWANI - REZYGNACJE',
    buttonID: 'customer-resignation-report',
  },
  {
    id: 6,
    label: 'ZAINTERESOWANI WG. HANDLOWCOW',
    buttonID: 'interested-customers-traders-report',
  },
  {
    id: 7,
    label: 'ZAINTERESOWANI OD POŚREDNIKÓW',
    buttonID: 'interested-customers-broker-reports',
  },
  {
    id: 8,
    label: 'ZAINTERESOWANI UMOWY OD POŚREDNIKÓW',
    buttonID: 'interested-customers-broker-contracts-reports',
  },
  {
    id: 9,
    label: 'ZAINTERESOWANI DSP',
    buttonID: 'interested-customers-daily-report',
  },
  { id: 10, label: 'UMOWY ANULOWANE', buttonID: 'cancelled-contracts-report' },
  { id: 11, label: 'RAPORT ZBIORCZY', buttonID: 'summary-report-dsp' },
  { id: 12, label: 'LISTA MIESZKAŃ', buttonID: 'places-list-report' },
  { id: 13, label: 'BŁĘKITNA LISTA', buttonID: 'tough-sales-report' },
  {
    id: 14,
    label: 'RAPORT LEADÓW I ZAINTERESOWANYCH',
    buttonID: 'lead-and-interested-report',
  },
]

export const DSPAdministrationMeta = [
  { id: 1, label: 'DODAJ INWESTYCJĘ', buttonID: 'add-investment-admin' },
  { id: 2, label: 'BUDYNKI', buttonID: 'buildings-admin' },
  { id: 3, label: 'HALE GARAŻOWE', buttonID: 'garages-admin' },
  { id: 4, label: 'LOKALE', buttonID: 'places-admin' },
  { id: 5, label: 'P. GOSPODARCZE', buttonID: 'utility-rooms-admin' },
  { id: 6, label: 'M. POSTOJOWE', buttonID: 'parking-places-admin' },
  { id: 7, label: 'BŁĘKITNA LISTA', buttonID: 'tough-customers-admin' },
  { id: 8, label: 'USTAWIENIA', buttonID: 'settings' },
]

export const DNKAdministrationMeta = [
  { id: 1, label: 'DODAJ INWESTYCJĘ', buttonID: 'add-investment-admin' },
  { id: 2, label: 'BUDYNKI', buttonID: 'service-buildings-admin' },
  { id: 3, label: 'HALE GARAŻOWE', buttonID: 'service-garages-admin' },
  { id: 4, label: 'LOKALE', buttonID: 'service-places-admin' },
  { id: 5, label: 'P. GOSPODARCZE', buttonID: 'service-utility-rooms-admin' },
  { id: 6, label: 'M. POSTOJOWE', buttonID: 'service-parking-places-admin' },
]

export const DNIAdministrationMeta = [
  { id: 1, label: 'UDZIAŁY', buttonID: 'shares-admin' },
  { id: 2, label: 'LOKALE', buttonID: 'places-admin' },
  {
    id: 5,
    label: 'ADMINISTRATORZY BUDYNKÓW',
    buttonID: 'building-administrators-admin',
  },
  { id: 4, label: 'KANCELARIE', buttonID: 'offices-admin' },
]

export const SKAReportsMeta = [
  { id: 1, label: 'UDZIAŁY', buttonID: 'shares-report' },
]
