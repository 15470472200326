import { difference } from 'lodash-es'
import React, { FunctionComponent, useCallback } from 'react'
import { Button, Dropdown, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Route as Router, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'

import ButtonWithTooltip from '../commons/Inputs/ButtonTooltip'

import { hasRoles } from '../../services/idp'

import { USER_ROLES } from '../../constants/user'

import {
  DARreportsMeta,
  DKOreportsMeta,
  DKSReportsMeta,
  DNIAdministrationMeta,
  DNIreportsMeta,
  DNKAdministrationMeta,
  DNKreportsMeta,
  DPPReportsMeta,
  DSPAdministrationMeta,
  DSPandDSPADMandDNK,
  DSPandDSPADMreportsMeta,
  SKAReportsMeta,
} from '../../mockups/tempHeaderMeta'
import { AdminITRoles, DMAAndDMAADMRoles } from '../../ducks/globalUser'
import { DropDownItem } from './DropDownItem'

export interface ILabelbuttonID {
  id?: number
  buttonID?: string
  href?: string
  label?: string
}

interface IHeaderMenuSpecificOptionProps {
  buttonID?: string
  disabled?: boolean
  meta: string | ILabelbuttonID[]
  nameOfHeaderOption: string
  placeActiveContract?: boolean
  selectedPlaceID?: number
}

export const HeaderMenuSpecificOption: FunctionComponent<IHeaderMenuSpecificOptionProps> = (
  props: IHeaderMenuSpecificOptionProps
) => {
  const history = useHistory()
  const { t } = useTranslation()

  const { chosenInvestment } = useSelector(
    (state: RootState) => state.globalInvestment
  )

  const {
    buttonID,
    disabled = false,
    meta,
    nameOfHeaderOption,
    placeActiveContract,
    selectedPlaceID,
  } = props

  const isChosenInvestment =
    chosenInvestment?.name !== 'Wszystkie' &&
    chosenInvestment?.name !== 'Umowy anulowane'
  const isButtonDisabledNotSelectedPlace =
    nameOfHeaderOption === 'Aranżacje' && !selectedPlaceID
  const isButtonDisabledNotSelectedInvestment =
    nameOfHeaderOption === 'Korespondencja Seryjna' && !isChosenInvestment
  const isButtonDisabled =
    isButtonDisabledNotSelectedPlace || isButtonDisabledNotSelectedInvestment
  const tooltipMessage = isButtonDisabledNotSelectedPlace
    ? t('commons:labels.choose-place')
    : t('commons:labels.choose-investment')
  const onLinkClick = useCallback(
    (href: string) => {
      history.push(href)
    },
    [history]
  )

  if (meta === 'button') {
    return (
      <ButtonWithTooltip
        message={tooltipMessage}
        shouldTooltipBeVisible={isButtonDisabled}
      >
        <Button
          disabled={isButtonDisabled}
          id={`${nameOfHeaderOption}-${meta}`}
          className='ik2-main-button-color'
          onClick={(): void =>
            onLinkClick(!isButtonDisabled && buttonID ? buttonID : '/')
          }
        >
          {nameOfHeaderOption}
        </Button>
      </ButtonWithTooltip>
    )
  } else {
    return (
      <>
        <Dropdown>
          {(placeActiveContract || !selectedPlaceID) &&
          nameOfHeaderOption.indexOf('Rejestracja') !== -1 ? (
            <ButtonWithTooltip
              isTooltipAlwaysOnTop
              message={t('commons:labels.choose-free-place-to-register')}
              shouldTooltipBeVisible={!selectedPlaceID || placeActiveContract}
            >
              <Dropdown.Toggle
                aria-expanded='false'
                id='dropdown-basic'
                style={{
                  background: '#06bff3',
                  color: '#e6e6e6',
                  opacity: '0.65',
                }}
              >
                {nameOfHeaderOption}
              </Dropdown.Toggle>
            </ButtonWithTooltip>
          ) : (
            <Dropdown.Toggle id='dropdown-basic' disabled={disabled}>
              {nameOfHeaderOption}
            </Dropdown.Toggle>
          )}
          {!disabled && (
            <Dropdown.Menu className='dw-100'>
              {Array.isArray(meta) &&
                meta.map((item: ILabelbuttonID) => (
                  <DropDownItem
                    {...item}
                    key={item.buttonID}
                    onLinkClick={onLinkClick}
                  />
                ))}
            </Dropdown.Menu>
          )}
        </Dropdown>
      </>
    )
  }
}

interface HeaderMenuSpecificOptionsProps {
  placeActiveContract?: boolean
  selectedPlaceID?: number
}

export const HeaderMenuSpecificOptions: FunctionComponent<HeaderMenuSpecificOptionsProps> = (
  props: HeaderMenuSpecificOptionsProps
) => {
  const history = useHistory()
  const { t } = useTranslation('headerMenu')
  const { placeActiveContract, selectedPlaceID } = props

  const onLinkClick = useCallback(
    (href: string) => {
      history.push(href)
    },
    [history]
  )

  const { roles } = useSelector((state: RootState) => state.globalUser)
  const ADMAdministrationViewRoles = difference(USER_ROLES, [
    'DAR',
    'DKO',
    'DPI',
    'DKS',
    'DMA',
    'DMA-ADM',
    'DNI',
    'DNK',
    'DPP',
    'DSP',
    'DSP-ADM',
    'DYR',
    'SEK',
    'SKA',
    'UMOWYAN',
    'UMOWYARCH',
  ])
  const DSPOrDNKAdministrationViewRoles = difference(USER_ROLES, [
    'ADM',
    'UMOWYAN',
    'UMOWYARCH',
    'DAR',
    'DKO',
    'DPI',
    'DKS',
    'DMA',
    'DMA-ADM',
    'DPP',
    'DSP',
    'DYR',
    'SEK',
    'SKA',
  ])
  const arrangementsViewRoles = difference(USER_ROLES, [
    'ADM',
    'DKO',
    'DPI',
    'DKS',
    'DMA',
    'DMA-ADM',
    'DNI',
    'DNK',
    'DPP',
    'DSP',
    'DSP-ADM',
    'DYR',
    'SEK',
    'SKA',
    'UMOWYAN',
    'UMOWYARCH',
  ])
  const interestedCustomersViewRoles = difference(USER_ROLES, [
    'ADM',
    'DAR',
    'DKO',
    'DPI',
    'DKS',
    'DMA',
    'DMA-ADM',
    'DNI',
    'DPP',
    'DYR',
    'SEK',
    'SKA',
    'UMOWYAN',
    'UMOWYARCH',
  ])
  const unassignedCustomersViewRoles = difference(USER_ROLES, [
    'ADM',
    'DAR',
    'DKO',
    'DPI',
    'DKS',
    'DMA',
    'DMA-ADM',
    'DNI',
    'DPP',
    'DYR',
    'SEK',
    'SKA',
    'UMOWYAN',
    'UMOWYARCH',
  ])
  const mailMergeViewRoles = difference(USER_ROLES, [
    'ADM',
    'DAR',
    'DPI',
    'DMA',
    'DMA-ADM',
    'DNI',
    'DNK',
    'DPP',
    'DSP',
    'DSP-ADM',
    'DYR',
    'SKA',
    'UMOWYAN',
    'UMOWYARCH',
  ])
  const reportsButtonViewRoles = difference(USER_ROLES, [
    'ADM',
    'AFIN',
    'DAR',
    'DKO',
    'DPI',
    'DKS',
    'DNI',
    'DPP',
    'DSP',
    'DSP-ADM',
    'DYR',
    'SEK',
    'SKA',
    'DNK',
    'UMOWYAN',
    'UMOWYARCH',
  ])
  const reportsListViewRoles = difference(USER_ROLES, [
    'ADM',
    'AFIN',
    'DPI',
    'DKS',
    'DMA',
    'DMA-ADM',
    'DNK',
    'DYR',
    'SEK',
    'SKA',
    'UMOWYAN',
    'UMOWYARCH',
  ])

  //TODO : poza zakresem podstawowym
  //const rentalRegistrationViewRoles = difference(USER_ROLES, ['ADM', 'DAR', 'DKO', 'DPI', 'DKS', 'DMA', 'DMA-ADM', 'DNI', 'DPP',
  //'DSP', 'DSP-ADM', 'DYR', 'SEK', 'SKA']);
  const salesRegistrationViewRoles = difference(USER_ROLES, [
    'ADM',
    'DAR',
    'DKO',
    'DPI',
    'DKS',
    'DMA',
    'DMA-ADM',
    'DNI',
    'DPP',
    'DYR',
    'SEK',
    'SKA',
    'UMOWYAN',
    'UMOWYARCH',
  ])
  const dksViewRoles = difference(USER_ROLES, [
    'ADM',
    'DAR',
    'DKO',
    'DPI',
    'DNK',
    'DMA',
    'DMA-ADM',
    'DNI',
    'DPP',
    'DSP',
    'DSP-ADM',
    'DYR',
    'SEK',
    'SKA',
    'UMOWYAN',
    'UMOWYARCH',
  ])
  const administrationMeta = (): string | ILabelbuttonID[] => {
    if (roles.includes('DNI')) {
      return DNIAdministrationMeta
    }
    if (roles.includes('DNK')) {
      return DNKAdministrationMeta
    }
    if (roles.includes('DSP-ADM')) {
      return DSPAdministrationMeta
    } else {
      return DNIAdministrationMeta
    }
  }
  const reportsMeta = (): string | ILabelbuttonID[] => {
    if (roles.includes('DAR')) {
      return DARreportsMeta
    } else if (roles.includes('SKA')) {
      return SKAReportsMeta
    } else if (roles.includes('DKO')) {
      return DKOreportsMeta
    } else if (roles.includes('DPP')) {
      return DPPReportsMeta
    } else if (roles.includes('DKS')) {
      return DKSReportsMeta
    } else if (roles.includes('DMA') || roles.includes('DMA-ADM')) {
      return '/'
    } else if (roles.includes('DNI')) {
      return DNIreportsMeta
    } else if (roles.includes('DNK')) {
      return DNKreportsMeta
    } else if (roles.includes('DSP') || roles.includes('DSP-ADM')) {
      return DSPandDSPADMreportsMeta
    } else {
      return '/'
    }
  }
  // TODO, poza zakresem podstawowym
  //const rentalRegistrationMeta = () : string | ILabelbuttonID[] => {
  //  return DSPandDSPADMandDNK;
  //};
  const salesRegistrationMeta = (): string | ILabelbuttonID[] => {
    return DSPandDSPADMandDNK
  }

  const { chosenPlacesContract } = useSelector(
    (state: RootState) => state.globalInvestment
  )
  const { mainTableCurrentPage } = useSelector(
    (state: RootState) => state.pagination
  )
  const { mainTableSelectedRowPage } = useSelector(
    (state: RootState) => state.persistSettings
  )
  const { numberOfNotTakenUnassignedCustomers } = useSelector(
    (state: RootState) => state.unassignedCustomers
  )
  const disableRegistrationButton =
    chosenPlacesContract?.investment?.isArchive ||
    mainTableCurrentPage !== mainTableSelectedRowPage

  return (
    <>
      {hasRoles(ADMAdministrationViewRoles, roles) && (
        <Button
          className='ik2-main-button-color'
          onClick={(): void => onLinkClick('/')}
        >
          {t('commons:menu.admin-it-panel')}
        </Button>
      )}
      {hasRoles(interestedCustomersViewRoles, roles) && (
        <Button
          className='ik2-main-button-color'
          onClick={(): void =>
            onLinkClick('/interested-customers-sales-department')
          }
        >
          {t('commons:menu.interested-customers')}
        </Button>
      )}
      {hasRoles(unassignedCustomersViewRoles, roles) && (
        <Button
          className='ik2-main-button-color'
          onClick={(): void => onLinkClick('/unassigned-customers')}
        >
          {`${t(
            'commons:menu.unassigned-customers'
          )} (${numberOfNotTakenUnassignedCustomers})`}
        </Button>
      )}
      {hasRoles(salesRegistrationViewRoles, roles) && (
        <HeaderMenuSpecificOption
          nameOfHeaderOption={t('commons:menu.sales-registration')}
          placeActiveContract={placeActiveContract}
          meta={
            placeActiveContract || !selectedPlaceID
              ? []
              : salesRegistrationMeta()
          }
          selectedPlaceID={selectedPlaceID}
          disabled={disableRegistrationButton}
        />
      )}
      {/* TODO : poza zakresem podstawowym
    {hasRoles(rentalRegistrationViewRoles, roles) && (
      <HeaderMenuSpecificOption nameOfHeaderOption={t('commons:menu.rental-registration')} meta={rentalRegistrationMeta(id)}
    ctrlID={id} selectedPlaceID={selectedPlaceID} />)} */}
      {hasRoles(arrangementsViewRoles, roles) && (
        <HeaderMenuSpecificOption
          nameOfHeaderOption={t('commons:menu.arrangements')}
          meta={'button'}
          buttonID={'arrangements'}
          selectedPlaceID={selectedPlaceID}
        />
      )}
      {hasRoles(mailMergeViewRoles, roles) && (
        <HeaderMenuSpecificOption
          nameOfHeaderOption={t('commons:menu.mail-merge-report')}
          meta={'button'}
          buttonID={'mail-merge-report'}
          selectedPlaceID={selectedPlaceID}
        />
      )}
      {hasRoles(reportsButtonViewRoles, roles) && (
        <HeaderMenuSpecificOption
          nameOfHeaderOption={t('commons:menu.reports')}
          meta={'button'}
          buttonID={String(reportsMeta())}
          selectedPlaceID={selectedPlaceID}
        />
      )}
      {(hasRoles(reportsListViewRoles, roles) ||
        hasRoles(dksViewRoles, roles)) && (
        <HeaderMenuSpecificOption
          nameOfHeaderOption={t('commons:menu.reports')}
          meta={reportsMeta()}
          selectedPlaceID={selectedPlaceID}
        />
      )}
      {hasRoles(DSPOrDNKAdministrationViewRoles, roles) && (
        <HeaderMenuSpecificOption
          nameOfHeaderOption={t('commons:menu.administration')}
          meta={administrationMeta()}
          selectedPlaceID={selectedPlaceID}
        />
      )}
    </>
  )
}

const HeaderMenu = (): JSX.Element | null => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation('headerMenu')

  const { chosenPlacesContract } = useSelector(
    (state: RootState) => state.globalInvestment
  )
  const { roles } = useSelector((state: RootState) => state.globalUser)
  const user = useSelector((state: RootState) => state.globalUser)

  const hasPlaceActiveContract = chosenPlacesContract?.activeContracts?.length

  const onLinkClick = useCallback(
    (href: string) => {
      history.push(href)
    },
    [history]
  )

  const generateHeader = (): JSX.Element | null => {
    const administationPathnames: string[] = [
      '/add-investment-admin',
      '/buildings-admin',
      '/garages-admin',
      '/places-admin',
      '/utility-rooms-admin',
      '/parking-places-admin',
      '/tough-customers-admin',
      '/settings',
    ]
    const isUserDSPADMinAdministration =
      user.roles.includes('DSP-ADM') &&
      administationPathnames?.includes(location.pathname)

    const hasDMAOrADMRoles =
      hasRoles(DMAAndDMAADMRoles, roles) || hasRoles(AdminITRoles, roles)
    const mainViewForDMAOrADM =
      location.pathname === '/main-table-view' && hasDMAOrADMRoles
    const mainViewForOtherRoles =
      (location.pathname === '/' ||
        location.pathname === '/cancelled-contracts') &&
      !hasDMAOrADMRoles

    if (
      mainViewForDMAOrADM ||
      mainViewForOtherRoles ||
      isUserDSPADMinAdministration
    ) {
      return (
        <Row className='d-flex justify-content-center'>
          <Button
            className='ik2-main-button-color'
            onClick={(): void => {
              onLinkClick(
                `${mainViewForDMAOrADM ? '/main-table-view' : ''}/tasks`
              )
            }}
          >
            {t('commons:menu.tasks')}
          </Button>
          <HeaderMenuSpecificOptions
            placeActiveContract={Boolean(hasPlaceActiveContract)}
            selectedPlaceID={chosenPlacesContract?.id}
          />
        </Row>
      )
    } else {
      return null
    }
  }

  return <Router>{generateHeader()}</Router>
}

export default HeaderMenu
