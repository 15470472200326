import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AppDispatch, RootState } from '../../ducks'
import { downloadXlsxReport, fetchDSPSummaryReport } from '../../ducks/reports'
import { RaportWithDatesState } from './RaportWithDates/actions'
import { Dates } from './RaportWithDates/Dates'
import { Investments } from './RaportWithDates/Investments'
import { RaportWithDates } from './RaportWithDates/RaportWithDates'
import { Stages } from './RaportWithDates/Stages'

const DSPSummaryReport: FC = () => {
  const isLoading = useSelector(
    (state: RootState) => state.combinedReports.DSPSummaryReport.isLoading
  )
  const dispatch: AppDispatch = useDispatch()
  const history = useHistory()

  const generateReport = React.useCallback(
    async (state: RaportWithDatesState) => {
      const investmentsIDs = state.chosenInvestments.map(
        (investment) => investment?.id
      )
      const stagesIDs = state.chosenStages.map((stage) => stage.id)

      const response = await dispatch(
        fetchDSPSummaryReport({
          stagesIDs,
          investmentsIDs,
          startDate: state.fromDate,
          endDate: state.toDate,
        })
      )
      const content = response.payload.DSPSummaryReport
      dispatch(
        downloadXlsxReport({
          content,
          filename: 'Raport zbiorczy DSP.xlsx',
        })
      ).then(() => {
        history.push('/')
      })
    },
    [dispatch, history]
  )

  return (
    <RaportWithDates isLoading={isLoading} generateReport={generateReport}>
      <Dates />
      <Investments />
      <Stages />
    </RaportWithDates>
  )
}

export default DSPSummaryReport
