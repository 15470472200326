import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppDispatch, RootState } from '../../ducks'
import React, { FC } from 'react'
import {
  downloadXlsxReport,
  fetchDSPInterestedCustomersDailyReport,
} from '../../ducks/reports'
import { RaportWithDatesState } from './RaportWithDates/actions'
import { Dates } from './RaportWithDates/Dates'
import { Investments } from './RaportWithDates/Investments'
import { RaportWithDates } from './RaportWithDates/RaportWithDates'
import { Stages } from './RaportWithDates/Stages'

const DSPInterestedCustomersDailyReport: FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const history = useHistory()
  const isLoading = useSelector(
    (state: RootState) =>
      state.combinedReports.DSPInterestedCustomersDailyReport.isLoading
  )

  const generateReport = React.useCallback(
    async (state: RaportWithDatesState) => {
      const investmentIDs = state.chosenInvestments.map(
        (investment) => investment?.id
      )
      const stagesIDs = state.chosenStages.map((stage) => stage.id)

      const response = await dispatch(
        fetchDSPInterestedCustomersDailyReport({
          startDate: state.fromDate,
          endDate: state.toDate,
          stagesIDs,
          investmentIDs,
        })
      )
      const content = response.payload.DSPInterestedPartiesReport
      dispatch(
        downloadXlsxReport({
          content,
          filename: 'Dzienny raport zainteresowanych.xlsx',
        })
      ).then(() => {
        history.push('/')
      })
    },
    [dispatch, history]
  )

  return (
    <RaportWithDates isLoading={isLoading} generateReport={generateReport}>
      <Dates />
      <Investments />
      <Stages />
    </RaportWithDates>
  )
}

export default DSPInterestedCustomersDailyReport
