import {
  OutputSelector,
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { difference } from 'lodash-es'
import { hasRoles } from '../services/idp'
import { USER_ROLES } from '../constants/user'
import UserService, { IUserInitialsObject } from '../services/usersService'
import { RootState } from './index'
import { useSelector } from 'react-redux'

//TODO AdminITRoles nie działa poprawnie, po naprawie dostosowanie pozostałych ról
export const AcceptanceDateEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNK',
  'DPP',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ActDateEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNK',
  'DPP',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const AddNewCustomerRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const AddNewRepresentativeRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const AdminITRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DNK',
  'DPP',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const AdministrationNumberEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DPP',
  'DSP',
  'DSP-ADM',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const AdressesEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const AdressesViewRoles = difference(USER_ROLES, [
  'DPI',
  'DPP',
  'UMOWYAN',
  'UMOWYARCH',
])
export const AgentEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const AgentViewRoles = difference(USER_ROLES, [
  'DPI',
  'DMA',
  'DMA-ADM',
  'DPP',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ArrangementsReminderEditRoles = difference(USER_ROLES, [
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DNK',
  'DPP',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ArrangementsReminderViewRoles = difference(USER_ROLES, [
  'ADM',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DNK',
  'DPP',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const BillNoViewRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DPP',
  'DSP',
  'DSP-ADM',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const BrokerEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const BrokerViewRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DMA',
  'DMA-ADM',
  'DPI',
  'DNI',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CommentEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CommentViewRoles = difference(USER_ROLES, [
  'DPI',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractFileEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractFileViewRoles = difference(USER_ROLES, [
  'DPI',
  'DMA',
  'DMA-ADM',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractUtilityRoomsAndParkingPlacesEditRoles = difference(
  USER_ROLES,
  [
    'ADM',
    'DAR',
    'DKO',
    'DPI',
    'DKS',
    'DMA',
    'DMA-ADM',
    'DNI',
    'DPP',
    'DYR',
    'SEK',
    'SKA',
    'UMOWYAN',
    'UMOWYARCH',
  ]
)
export const ContractUtilityRoomsAndParkingPlacesViewRoles = difference(
  USER_ROLES,
  ['SEK', 'UMOWYAN', 'UMOWYARCH']
)
export const CopyPasteRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DNI',
  'DPP',
  'DMA',
  'DMA-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const BusinessPurchaseEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const BusinessPurchaseViewRoles = difference(USER_ROLES, ['DPP', 'SEK'])
export const BusinessTypeAndNameEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const BusinessTypeAndNameViewRoles = difference(USER_ROLES, [
  'DPI',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const DeleteContractAndCustomerDataRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const EditContractAndCustomerDataRoles = difference(USER_ROLES, [
  'ADM',
  'DPI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const EditCustomerButtonViewRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const EditPlaceButtonViewRoles = difference(USER_ROLES, [
  'ADM',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DPP',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CancelContractRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CitizenshipEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CitizenshipViewRoles = difference(USER_ROLES, [
  'DKO',
  'DPI',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CommissionDivisionEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CommissionDivisionViewRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CompanyFormEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CompanyFormViewRoles = difference(USER_ROLES, [
  'DPI',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CompanyNameEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CompanyNameViewRoles = difference(USER_ROLES, [
  'DPI',
  'DPP',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractAnnexesDeleteRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractAnnexesViewRoles = difference(USER_ROLES, [
  'DPI',
  'DMA',
  'DMA-ADM',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractDealDateEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractDisclaimerDeleteRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DNK',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractDisclaimerViewRoles = difference(USER_ROLES, [
  'DKO',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DNK',
  'DPP',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractNoEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractProtocolsEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DPP',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractProtocolsDeleteRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractProtocolsViewRoles = difference(USER_ROLES, [
  'DPI',
  'DMA',
  'DMA-ADM',
  'DPP',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractualAcceptanceDateEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContractualActDateEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ContactDeleteRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DNK',
  'DPP',
  'DSP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const BankViewRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DPP',
  'DMA',
  'DMA-ADM',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CreditEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CreditBasicViewRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CreditDetailsViewRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CustomerCodeForCustomerDataEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CustomerCodeForCustomerDataViewRoles = difference(USER_ROLES, [
  'DPP',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CustomerCodeForPlaceDataEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CustomerCodeForPlaceDataViewRoles = difference(USER_ROLES, [
  '',
  'UMOWYAN',
  'UMOWYARCH',
])
export const LawAndDeluxeContractEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DNK',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const LawAndDeluxeContractViewRoles = difference(USER_ROLES, [
  'DKO',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DNK',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const DisclaimersEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DSP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const DisclaimersViewRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DSP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const DARRoles = difference(USER_ROLES, [
  'ADM',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DNK',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const DMAAndDMAADMRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DNI',
  'DPP',
  'DNK',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const DNIRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DNK',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DPP',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const DNKRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const DNKDSPRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const DPPRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DNK',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const DSPRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DNK',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const DSPAndDSPADMRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DNK',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const EmailInvoiceEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const EmailInvoiceViewRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CorrespondenceEventsDeleteRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const CorrespondenceEventsViewRoles = difference(USER_ROLES, [
  'DNI',
  'DPP',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const FirstLastNameEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const FirstLastNameViewRoles = difference(USER_ROLES, ['DPP', 'DPI'])
export const IDNumberAndSeriesEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const IDNumberAndSeriesViewRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DPP',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const InterestedCustomersTableInCustomerDataEditRoles = difference(
  USER_ROLES,
  [
    'ADM',
    'DAR',
    'DKO',
    'DPI',
    'DKS',
    'DMA',
    'DMA-ADM',
    'DNI',
    'DPP',
    'DYR',
    'SEK',
    'SKA',
    'UMOWYAN',
    'UMOWYARCH',
  ]
)
export const InterestedCustomersTableInCustomerDataViewRoles = difference(
  USER_ROLES,
  [
    'DAR',
    'DKO',
    'DPI',
    'DKS',
    'DNI',
    'DPP',
    'DYR',
    'SEK',
    'SKA',
    'UMOWYAN',
    'UMOWYARCH',
  ]
)
export const IsBusinessEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const IsBusinessViewRoles = difference(USER_ROLES, [
  'DPP',
  'UMOWYAN',
  'UMOWYARCH',
])
export const KRSEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const KRSViewRoles = difference(USER_ROLES, [
  'DAR',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DPP',
  'SEK',
])
export const MaidenNameEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DNK',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const MaidenNameViewRoles = difference(USER_ROLES, [
  'DPI',
  'DMA',
  'DMA-ADM',
  'DNK',
  'DPP',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const MarketingConsentsEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const MarketingConsentsViewRoles = difference(USER_ROLES, [
  'DKO',
  'DPI',
  'DNI',
  'DPP',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const MaritalStatusEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const MaritalStatusViewRoles = difference(USER_ROLES, [
  'DPI',
  'DPP',
  'UMOWYAN',
  'UMOWYARCH',
])
export const NoOfRoomsViewRoles = difference(USER_ROLES, [
  'DKS',
  'DNK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const NotarialOfficeEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DKS',
  'DNI',
  'DNK',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const NotarialOfficeViewRoles = difference(USER_ROLES, [
  'DKO',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DPP',
  'UMOWYAN',
  'UMOWYARCH',
])
export const OtherContractResolutionsEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const OtherContractResolutionsViewRoles = difference(USER_ROLES, [
  'DPI',
  'SEK',
])
export const PassportNumberAndSeriesEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const PassportNumberAndSeriesViewRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DPP',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ParentsNamesEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DNK',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ParentsNamesViewRoles = difference(USER_ROLES, [
  'DAR',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNK',
  'DPP',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const PeselEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const PeselViewRoles = difference(USER_ROLES, [
  'DPI',
  'DKO',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const PlaceAreasViewRoles = difference(USER_ROLES, [
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const PlaceSharesEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const PlaceSharesViewRoles = difference(USER_ROLES, ['DPI', 'DPP'])
export const PledgeWarrantyEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const PledgeWarrantyViewRoles = difference(USER_ROLES, [
  'DPI',
  'DMA',
  'DMA-ADM',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const PhonesAndEmailEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const PhonesAndEmailViewRoles = difference(USER_ROLES, [
  'DPI',
  'DPP',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const PriceListViewRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DNK',
  'DPP',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const PurchasePurposeEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const PurchasePurposeViewRoles = difference(USER_ROLES, [
  'DAR',
  'DPP',
  'DSP',
  'DSP-ADM',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ReceptionDateEditRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DMA',
  'DMA-ADM',
  'DPI',
  'DPP',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const RegonEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const RegonViewRoles = difference(USER_ROLES, [
  'DAR',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const RepresentativePersonEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const RepresentativePersonViewRoles = difference(USER_ROLES, [
  'DPI',
  'DPP',
  'UMOWYAN',
  'UMOWYARCH',
])
export const RepNoEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const SexEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const SexViewRoles = difference(USER_ROLES, [
  'DPI',
  'DPP',
  'UMOWYAN',
  'UMOWYARCH',
])
export const SourceInfoEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const SourceInfoViewRoles = difference(USER_ROLES, [
  'DKO',
  'DKS',
  'DNI',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const TaxNumberEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const TaxNumberViewRoles = difference(USER_ROLES, [
  'DAR',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const TerraceBackyardEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DNK',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const TerraceBackyardViewRoles = difference(USER_ROLES, [
  'DKS',
  'DNK',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const TraderInitialsEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const TraderInitialsViewRoles = difference(USER_ROLES, [
  'DPP',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ValorizationEditRoles = difference(USER_ROLES, [
  'ADM',
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DKS',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const ValorizationViewRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DMA',
  'DMA-ADM',
  'DPP',
  'SEK',
  'UMOWYAN',
  'UMOWYARCH',
])
export const WarrantyGuaranteeEditRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DPP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])
export const LawContractViewRoles = difference(USER_ROLES, [
  'DPS,',
  'DSP-ADM',
  'UMOWYAN',
  'UMOWYARCH',
])

export const DSPADMRoles = difference(USER_ROLES, [
  'DAR',
  'DKO',
  'DPI',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DNK',
  'DPP',
  'DSP',
  'DYR',
  'SEK',
  'SKA',
  'UMOWYAN',
  'UMOWYARCH',
])

export function serviceTypePredicate<T extends GenericServiceEntity>(
  entity: T,
  isDNK: boolean,
  isDSP: boolean
): boolean {
  if (!entity) {
    return false
  }

  if (entity.hasOwnProperty('isTypeService')) {
    return isDNK ? entity.isTypeService : isDSP ? !entity.isTypeService : true
  } else {
    return true
    // TODO uncomment this after all entities have isTypeService in database
    // throw new Error(`Unexpected error ${entity.toString()} has no isTypeService property`);
  }
}

export function dnkCustomerPredicate<T extends GenericCustomerEntity>(
  entity: T,
  isDNK: boolean,
  isDSP: boolean
): boolean {
  if (!entity) {
    return false
  }

  if (entity.hasOwnProperty('isDNKCustomer')) {
    return isDNK ? entity.isDNKCustomer : isDSP ? !entity.isDNKCustomer : true
  } else {
    return true
    // TODO uncomment this after all entities have isDNKCustomer in database
    // throw new Error(`Unexpected error ${entity.toString()} has no isTypeService property`);
  }
}

export function ServiceTypeFilter<T extends GenericServiceEntity>(
  entity: T
): boolean {
  const { roles } = useSelector((state: RootState) => state.globalUser)
  const isDNKCustomer = hasRoles(DNKRoles, roles || [])
  const isDSPCustomer = hasRoles(DSPRoles, roles || [])

  return serviceTypePredicate<T>(entity, isDNKCustomer, isDSPCustomer)
}

export function ServiceTypeFilterSelector<T extends GenericServiceEntity>(
  entity: T
): OutputSelector<RootState, boolean, (res: any) => boolean> {
  return createSelector(
    (state: RootState) => state.globalUser,
    (user: IGlobalUser) => {
      const isDNKCustomer = hasRoles(DNKRoles, user.roles || [])
      const isDSPCustomer = hasRoles(DSPRoles, user.roles || [])
      return serviceTypePredicate<T>(entity, isDNKCustomer, isDSPCustomer)
    }
  )
}

export interface GenericServiceEntity {
  isTypeService: boolean
}

export interface GenericCustomerEntity {
  isDNKCustomer: boolean
}

interface IUserProfile {
  firstName: string
  lastName: string
  username: string
  attributes: {
    initials: string
  }
}

interface IGlobalUser {
  roles: any[]
  loading: boolean
  error: string | null
  isUserInitialsListLoading: boolean
  userInitialsList: string[]
  userInitialsListWithIDs: { id: string; initials: string }[]
  profile: IUserProfile | undefined
  tokenParsed: any
  token: any
}

const initialState: IGlobalUser = {
  roles: [],
  loading: false,
  error: null,
  isUserInitialsListLoading: false,
  userInitialsList: [],
  userInitialsListWithIDs: [],
  profile: undefined,
  tokenParsed: undefined,
  token: undefined,
}

export const getTraderInitialsOfCustomer = createAsyncThunk(
  'globalUser/getTraderInitialsOfCustomer',
  async () => UserService.fetchTraderInitialsOfCustomer()
)

export const getUserInitialsList = createAsyncThunk(
  'globalUser/getUserInitialsList',
  async (variables?: string[]) => UserService.fetchUserInitialsList(variables)
)

export const getUserInitialsListWithIDs = createAsyncThunk(
  'globalUser/getUserInitialsListWithIDs',
  async (variables?: string[]) => UserService.fetchUserInitialsList(variables)
)

const globalUserSlice = createSlice({
  name: 'globalUser',
  initialState,
  reducers: {
    setTokenParsed(state, action): void {
      if (action.payload) {
        state.tokenParsed = action.payload
        state.roles = action.payload.realm_access.roles
      }
    },
    setToken(state, action): void {
      if (action.payload) {
        state.token = action.payload
      }
    },
    setProfile(state, action: PayloadAction<IUserProfile>): void {
      if (action.payload) {
        state.profile = action.payload
      }
    },
  },
  extraReducers: {
    [getUserInitialsList.fulfilled.toString()]: (state, action): void => {
      if (state.roles && state.roles.length > 0) {
        const actualUserInitials = JSON.stringify(
          state?.profile?.attributes?.initials ?? ''
        )
          .split('"')
          .join('')
          .replace('[', '')
          .replace(']', '')
        const { users } = action.payload
        const filteredUserNames: string[] = users
          .map((user: IUserInitialsObject) => user.initials)
          .filter((user: string) => user !== actualUserInitials)
        state.userInitialsList = [actualUserInitials, ...filteredUserNames]
        state.isUserInitialsListLoading = false
      }
    },
    [getUserInitialsList.pending.toString()]: (state): void => {
      state.isUserInitialsListLoading = true
    },
    [getUserInitialsList.rejected.toString()]: (state): void => {
      state.isUserInitialsListLoading = false
    },
    [getUserInitialsListWithIDs.fulfilled.toString()]: (
      state,
      action
    ): void => {
      if (state.roles && state.roles.length > 0) {
        const { users } = action.payload
        state.userInitialsListWithIDs = users
      }
    },
  },
})

export const hasUserAdminITRole = () =>
  createSelector(
    (state: IGlobalUser) => state.roles,
    (roles) => hasRoles(roles, AdminITRoles)
  )

export const hasUserEditCustomerAndContractDataRole = () =>
  createSelector(
    (state: IGlobalUser) => state.roles,
    (roles) => hasRoles(roles, EditContractAndCustomerDataRoles)
  )

export const { setProfile, setToken, setTokenParsed } = globalUserSlice.actions

export default globalUserSlice.reducer
