import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../ducks'
import {
  clearPlacesContracts,
  fetchInvestmentsWithStagesOnly,
  fetchPlacesContractsByInvestmentNameForMainTableActiveContracts,
  setChosenPlacesContract,
} from '../../ducks/globalInvestment'
import {
  checkReminderTraderInitials,
  fetchCustomersByDailyReminders,
  fetchDARNotesByUserID,
  fetchNotesByUserID,
  fetchUserAlertsByDate,
} from '../../ducks/reminder'
import { IContract } from '../../graphql/contracts/contracts'
import { IPlaceContractsQuery } from '../../graphql/currentPlaceContracts/currentPlaceContracts'
import { ICustomersReminder } from '../../services/reminderService'
import { getMainTableColumns } from '../App/mainTableColumns'
import Reminder from '../Reminder/Reminder'
import moment from 'moment'
import { DATE_FORMAT_YYYYMMDD } from '../../constants/dates'
import Table from '../commons/Table2/Table'
import { useTranslation } from 'react-i18next'
import { hasRoles } from '../../services/idp'
import { difference } from 'lodash-es'
import { USER_ROLES } from '../../constants/user'
import { Col, Row } from 'react-bootstrap'
import ToggleSumBar from '../commons/Table/ToggleSumBar'
import GroundPlanButton from '../commons/Table2/GroundPlanButton'
import {
  AdminITRoles,
  CorrespondenceEventsViewRoles,
  DARRoles,
  DMAAndDMAADMRoles,
  DNKRoles,
  DSPRoles,
  FirstLastNameViewRoles,
  GenericServiceEntity,
  //PriceListViewRoles,
  serviceTypePredicate,
} from '../../ducks/globalUser'
import { useKeycloak } from '@react-keycloak/web'
import { PlacesPaginationProps } from '../../services/placesService'
import { push } from 'connected-react-router'
import {
  refetchPagination,
  setPlacesByInvestmentNamesQueryForMainTable,
} from '../commons/Table2/paginationSlice'
import {
  setMainTableRowIndex,
  setMainTableRowPage,
} from '../../ducks/persistSettings'
import { StorageKeys } from '../../hooks/useBrowserStorage'
import { useMainTableHiddenColumns } from '../../hooks/useMainTableHiddenColumns'
import { getRowStyle } from './functions'

const customerAccessRoles = difference(USER_ROLES, ['DPP'])
const toggleSumBarAccessRoles = difference(USER_ROLES, ['DAR'])
const sensitiveColums = ['firstName1', 'firstName2', 'lastName1', 'lastName2']

interface IMainTable {
  isAlreadyHidden?: boolean
}

const MainTable: React.FC<IMainTable> = ({ isAlreadyHidden }) => {
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const { keycloak } = useKeycloak()
  const hiddenColumns = useMainTableHiddenColumns(isAlreadyHidden)
  const { isLoadingContractOnPlace } = useSelector(
    (state: RootState) => state.places
  )

  const {
    chosenInvestment,
    chosenPlacesContract,
    chosenStage,
    placesContracts,
    amountOfPlacesContracts,
    isArchiveMode,
    isLoading,
  } = useSelector((state: RootState) => state.globalInvestment)
  const { profile, roles, tokenParsed } = useSelector(
    (state: RootState) => state.globalUser
  )
  const { mainTableHiddenColumns } = useSelector(
    (state: RootState) => state.persistSettings
  )
  const checkReminders = useSelector(checkReminderTraderInitials)
  const {
    todayNotes,
    areNotesDownloaded,
    alerts,
    areAlertsDownloaded,
  } = useSelector((state: RootState) => state.reminder)
  const filteredNotes = todayNotes.filter((note) => note.reminderDate)
  const {
    isMaintainRowClickStopped,
    placesByInvestmentNamesQuery,
  } = useSelector((state: RootState) => state.pagination)

  // const copyPriceList = React.useCallback(() : void => {
  //   const el = document.createElement('textarea');
  //   const copyText = `file:///I:/${chosenInvestment?.name ?? ''}/SPRZEDAZ/Cenniki/CEN${chosenInvestment?.name ?? ''}_${chosenStage?.name ?? ''}.xlsx`;
  //   el.value = copyText;
  //   el.setAttribute('readonly', '');
  //   document.body.appendChild(el);
  //   el.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(el);
  // }, [chosenInvestment, chosenStage]);

  const [isReminderModalVisible, setIsReminderModalVisible] = useState(false)
  const [customersReminder, setCustomersReminder] = useState<
    null | ICustomersReminder[]
  >(null)
  const { mainTableCurrentPage } = useSelector(
    (state: RootState) => state.pagination
  )
  const { mainTableSelectedRowPage } = useSelector(
    (state: RootState) => state.persistSettings
  )
  const today = moment().format(DATE_FORMAT_YYYYMMDD)
  const hasPlaceActiveContract = chosenPlacesContract?.activeContracts?.length
  const isNoContract = Boolean(!hasPlaceActiveContract)
  const isDNKCustomer = hasRoles(DNKRoles, roles || [])
  const isDSPCustomer = hasRoles(DSPRoles, roles || [])
  const mainView =
    hasRoles(AdminITRoles, roles) || hasRoles(DMAAndDMAADMRoles, roles)
      ? '/main-table-view'
      : ''
  const isDARRole = hasRoles(DARRoles, roles)
  const disableActionButton = mainTableCurrentPage !== mainTableSelectedRowPage

  const serviceTypeFilter = useCallback(
    (entity: GenericServiceEntity) => {
      return serviceTypePredicate<GenericServiceEntity>(
        entity,
        isDNKCustomer,
        isDSPCustomer
      )
    },
    [isDNKCustomer, isDSPCustomer]
  )

  const getActiveContract = useCallback((activeContracts: IContract[]):
    | IContract
    | undefined => {
    return activeContracts?.[0]
  }, [])
  const mainTableColumns = useMemo(
    () =>
      getMainTableColumns({
        getActiveContract,
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAlreadyHidden, getActiveContract]
  )
  const closeReminderModal = useCallback(
    () => setIsReminderModalVisible(false),
    [setIsReminderModalVisible]
  )

  const onSelectPlaceContract = useCallback(
    (row: IPlaceContractsQuery) => {
      const sameAsPrevious = row.id === chosenPlacesContract?.id
      if (sameAsPrevious && !isMaintainRowClickStopped) {
        dispatch(setMainTableRowIndex(null))
        dispatch(setMainTableRowPage(null))
        return dispatch(setChosenPlacesContract(null))
      }
      const newChosenPlaceContract = placesContracts.find(
        (placeContract: IContract) => placeContract.id === row.id
      )
      dispatch(setChosenPlacesContract(newChosenPlaceContract))
      dispatch(setMainTableRowPage(mainTableCurrentPage))
    },
    [
      chosenPlacesContract,
      dispatch,
      mainTableCurrentPage,
      placesContracts,
      isMaintainRowClickStopped,
    ]
  )

  const getSortedPlacesContracts = useMemo(() => {
    // This will be probably deleted
    return placesContracts.filter(serviceTypeFilter)
  }, [placesContracts, serviceTypeFilter])

  useEffect(() => {
    if (isMaintainRowClickStopped) {
      dispatch(setMainTableRowIndex(null))
      dispatch(setMainTableRowPage(null))
    }
  }, [dispatch, isMaintainRowClickStopped])

  useEffect(() => {
    dispatch(clearPlacesContracts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenInvestment?.id, chosenStage?.id])

  useEffect(() => {
    const validReminders = checkReminders()
    setCustomersReminder(validReminders)
  }, [checkReminders])

  const [lastTimeLoggedIn, setLastTimeLoggedIn] = useState(
    localStorage.getItem('lastTimeLoggedIn')
  )
  useEffect(() => {
    if (
      lastTimeLoggedIn !== today &&
      keycloak?.authenticated &&
      tokenParsed?.sub
    ) {
      dispatch(fetchUserAlertsByDate(today))
      dispatch(fetchCustomersByDailyReminders()).then(() => {
        isDARRole
          ? dispatch(fetchDARNotesByUserID(tokenParsed?.sub || ''))
          : dispatch(fetchNotesByUserID(tokenParsed?.sub || ''))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTimeLoggedIn, tokenParsed?.sub])

  useEffect(() => {
    dispatch(fetchInvestmentsWithStagesOnly())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const todayReminders =
      Boolean(customersReminder?.length) ||
      Boolean(todayNotes.length) ||
      Boolean(alerts.length)
    const canRemindersBeCheck =
      lastTimeLoggedIn !== today &&
      profile &&
      areNotesDownloaded &&
      areAlertsDownloaded &&
      todayReminders !== null
    if (canRemindersBeCheck) {
      if (todayReminders) {
        setIsReminderModalVisible(true)
      }
      setLastTimeLoggedIn(today)
      localStorage.setItem('lastTimeLoggedIn', today)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areNotesDownloaded, areAlertsDownloaded])

  const getParametersForPlacesQuery = useCallback((): Partial<
    PlacesPaginationProps
  > => {
    return {
      investmentNames:
        chosenInvestment?.name !== 'Wszystkie'
          ? [chosenInvestment?.name]
          : null,
      stageID: chosenStage?.id === -1 ? null : chosenStage?.id,
      keyword: placesByInvestmentNamesQuery ?? '',
      showArchived: isArchiveMode,
    } as Partial<PlacesPaginationProps>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenInvestment, chosenStage?.id, placesByInvestmentNamesQuery])

  const initialHiddenData = Object.entries(mainTableHiddenColumns)
    .filter((entry) => entry[1] === false)
    .map((item) => item[0])

  const getRedirectPath = useCallback(
    (viewName: string) => {
      const redirectPath = isNoContract
        ? '/'
        : mainView
        ? `${mainView}/valid-contract/${viewName}`
        : `/valid-contract/${viewName}`
      return redirectPath
    },
    [isNoContract, mainView]
  )

  useEffect(() => {
    const isSelecetedCanceledContracts = chosenInvestment?.id === 0
    isSelecetedCanceledContracts && dispatch(push('/cancelled-contracts'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <>
      <Reminder
        show={isReminderModalVisible}
        customersBody={customersReminder || []}
        closeFunction={closeReminderModal}
        myTasksBody={filteredNotes}
        alerts={alerts}
      />
      <div className='px-1'>
        <Table
          columns={mainTableColumns}
          customLoading
          data={getSortedPlacesContracts}
          isMainTable
          isSelectAllCheckbox
          manualSortBy={true}
          totalRows={amountOfPlacesContracts}
          hidenCols={hiddenColumns}
        >
          <Table.Header align='between'>
            <Table.Filters />
            {hasRoles(FirstLastNameViewRoles, roles) && (
              <Table.SensitiveInfoButton
                initialHiddenData={initialHiddenData}
                sensitiveColumns={sensitiveColums}
              />
            )}
            <Table.TableSearchWithPagination
              isLoading={isLoading || isLoadingContractOnPlace}
              query={placesByInvestmentNamesQuery}
              setQuery={setPlacesByInvestmentNamesQueryForMainTable}
              refreshPage={refetchPagination}
            />
          </Table.Header>
          <Table.TableBody
            onRowClick={onSelectPlaceContract}
            timeout={9999999999}
            orderRows
            isLoading={isLoading || isLoadingContractOnPlace}
            getRowStyle={getRowStyle}
          />
          <Row className={'d-flex align-items-center justify-content-between'}>
            {hasRoles(toggleSumBarAccessRoles, roles) && (
              <Col
                className='d-flex justify-content-start'
                lg={8}
                md={12}
                sm={12}
              >
                <ToggleSumBar />
              </Col>
            )}
            <Table.PaginationWithFetch
              storageType='localStorage'
              tableKey={StorageKeys.MainTable}
              genericFetch={
                fetchPlacesContractsByInvestmentNameForMainTableActiveContracts
              }
              additionalParameters={getParametersForPlacesQuery()}
            />
          </Row>
          <Table.Footer>
            <Table.RedirectButton
              path={getRedirectPath('place')}
              disabled={
                isNoContract || disableActionButton || isMaintainRowClickStopped
              }
            >
              {t('commons:menu.place')}
            </Table.RedirectButton>
            {hasRoles(customerAccessRoles, roles) ? (
              <Table.RedirectButton
                path={getRedirectPath('customer')}
                disabled={isNoContract || disableActionButton}
              >
                {t('commons:menu.customer')}
              </Table.RedirectButton>
            ) : null}
            {hasRoles(CorrespondenceEventsViewRoles, roles) ? (
              <Table.RedirectButton
                path={getRedirectPath('contract')}
                disabled={isNoContract || disableActionButton}
              >
                {t('commons:menu.contract')}
              </Table.RedirectButton>
            ) : null}
            <GroundPlanButton />
            {/* {hasRoles(PriceListViewRoles, roles) && (
              <ButtonWithTooltip
                message={t('commons:titles.copy-and-paste-link-below-to-new-tab')}
                shouldTooltipBeVisible={!isAllStages}
              >
                <Button className="ik2-main-button-color" onClick={copyPriceList} disabled={isAllStages}>
                  {t('commons:menu.price-list')}
                </Button>
              </ButtonWithTooltip>
            )} */}
          </Table.Footer>
        </Table>
      </div>
    </>
  )
}
export default MainTable
