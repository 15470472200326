import * as React from 'react'

interface IProposedInvestment {
  name: string
  investmentID?: number | null
  placeID?: number | null
  stageID?: number | null
}

interface IProposedItem {
  name: string
  id: number
}

const deafultOption = {
  id: -1,
  name: '',
}

export const useProposedInvestment = (): any => {
  const [proposedInvestments, setProposedInvestments] = React.useState<
    IProposedInvestment[]
  >([])
  const [investment, setInvestment] = React.useState<IProposedItem>(
    deafultOption
  )
  const [stage, setStage] = React.useState<IProposedItem>(deafultOption)
  const [place, setPlace] = React.useState<IProposedItem>(deafultOption)

  const onChangeInvestmentValue = React.useCallback(
    (selectedInvestment: IProposedItem): void => {
      setInvestment(selectedInvestment)
      setStage(deafultOption)
      setPlace(deafultOption)
    },
    []
  )
  const onChangeStageValue = React.useCallback(
    (selectedStage: IProposedItem): void => {
      setStage(selectedStage)
      setPlace(deafultOption)
    },
    []
  )
  const onChangePlacesValue = React.useCallback(
    (selectedPlace: IProposedItem): void => {
      setPlace(selectedPlace)
    },
    []
  )

  const isAlreadyProposed = React.useCallback(
    (name: string) => {
      const matchedProposedItems = proposedInvestments.filter(
        (proposed) => proposed.name === name
      )
      return !!matchedProposedItems.length
    },
    [proposedInvestments]
  )

  const addProposedInvestment = React.useCallback(() => {
    const name = `${investment?.name || ''} - ${stage?.name || ''}${
      place ? ' - ' + place.name : ''
    }`
    if (isAlreadyProposed(name)) {
      return
    }
    const newProposedItem = {
      investmentID: investment?.id,
      stageID: stage?.id,
      placeID: place?.id !== -1 ? place?.id : null,
      name,
    }
    setProposedInvestments((prev) => [...prev, newProposedItem])
  }, [investment, isAlreadyProposed, place, stage])

  const removeProposedItem = React.useCallback(
    (index: number) => {
      const proposedCopy = [...proposedInvestments]
      proposedCopy.splice(index, 1)
      setProposedInvestments(proposedCopy)
    },
    [proposedInvestments]
  )

  return {
    state: {
      proposedInvestments,
      stage,
      investment,
      place,
    },
    setters: {
      removeProposedItem,
      onChangeStageValue,
      onChangePlacesValue,
      onChangeInvestmentValue,
      addProposedInvestment,
    },
  }
}
