import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Row } from 'react-bootstrap'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter, push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState, history } from '../../ducks'
import DKOAreasReport from '../Reports/DKOAreasReports'
import DKOExecutionStatusReport from '../Reports/DKOExecutionStatusReport'
import DKOSerialCorrespondenceReport from '../Reports/DKOSerialCorrespondenceReport'
import DNIActsReport from '../Reports/DNIActsReport'
import DARArrangementReport from '../Reports/DARArrangementReport'
import DNIPickupScheduleReport from '../Reports/DNIPickupScheduleReport'
import DNIShareTableReport from '../Reports/DNIShareTableReport'
import DSPCancelledContractsReport from '../Reports/DSPCancelledContractsReport'
import Header from '../Header/Header'
import NotarialOfficeReport from '../Reports/NotarialOfficeReport'
import Search from '../Search/Search'
import UserPanel from '../UserPanel/UserPanel'
import { IInvestment, IStage } from '../../graphql/investments'
import { infoToastNotify } from '../commons/Toast/Toast'
import {
  setChosenPlacesContract,
  setGlobalChosenInvestment,
  setGlobalChosenStage,
} from '../../ducks/globalInvestment'
import {
  AdminITRoles,
  CorrespondenceEventsViewRoles,
  DARRoles,
  DMAAndDMAADMRoles,
  DNKRoles,
  DPPRoles,
  DSPAndDSPADMRoles,
  DSPRoles,
  getUserInitialsList,
  hasUserEditCustomerAndContractDataRole,
} from '../../ducks/globalUser'
import CustomerByTradersReport from '../Reports/CustomerByTradersReport'
import DSPCustomersByBrokersReport from '../Reports/DSPCustomersByBrokersReport'
import CustomerSourceContractReport from '../Reports/CustomerSourceContractReport'
import DSPCustomerResignationReport from '../Reports/DSPCustomerResignationReport'
import DSPPlacesListReport from '../Reports/DSPPlacesListReport'
import DSPSummaryReport from '../Reports/DSPSummaryReport'
import BuildingAdministratorsReport from '../Reports/BuildingAdministratorsReport'
import MainTable from '../MainTable/MainTable'
import TableUnassignedCustomers from '../UnassignedCustomers/TableUnassignedCustomers'
import HeaderMenu from '../Header/HeaderMenu'
import DSPInterestedCustomersDailyReport from '../Reports/DSPInterestedCustomersDailyReport'
import DSPCustomerConcludedContractsReport from '../Reports/DSPCustomerConcludedContractsReport'
import DSPDailyInterestedPartiesReport from '../Reports/DSPDailyInterestedPartiesReport'
import DKSBusinessPlacesReport from '../Reports/DKSBusinessPlacesReport'
import DARDateAnnexesReport from '../Reports/DARDateAnnexesReport'
import DARArrangementListReport from '../Reports/DARArrangementListReport'
import DPPPlacesTallyReport from '../Reports/DPPPlacesTallyReport'
import { hasRoles } from '../../services/idp'
import Loader from '../commons/Loader/Loader'
import { SuspendedRoute } from './SuspendedRoute'
import InterestedCustomersReport from '../InterestedCustomers/raport/InterestedCustomersReport'
import BlueListSalesReport from '../Reports/BlueListSalesReport'
import { setMainTableRowIndex } from '../../ducks/persistSettings'
import { setMainTableCurrentPage } from '../commons/Table2/paginationSlice'
import {
  getIDsOfUnassignedCustomersWithActiveMessagesOrCustomerID,
  getNumberOfMessagesFromUnassignedCustomers,
  setNumberOfMessagesChange,
} from '../../ducks/unassignedCustomers'
import LeadAndInterestedReport from '../Reports/LeadAndInterestedReport'
import { getMessageAlerts } from '../../ducks/reminder'

const CancelledContractPlace = React.lazy(() =>
  import('../Place/CancelledContractPlace')
)
const CancelledContractCustomerData = React.lazy(() =>
  import('../Customer/CancelledContractCustomerData')
)
const DMAMarketingReport = React.lazy(() =>
  import('../Reports/DMAMarketingReport')
)
const LandStripAdminPanel = React.lazy(() =>
  import('../Administration/LandStripAdminPanel')
)
const BlueListAdminPanel = React.lazy(() =>
  import('../Administration/BlueListAdminPanel')
)
const ParkingPlacesAdminPanel = React.lazy(() =>
  import('../Administration/ParkingPlacesAdminPanel/ParkingPlacesAdminPanel')
)
const UtilityRoomAdminPanel = React.lazy(() =>
  import('../Administration/UtilityRoomAdminPanel/UtilityRoomAdminPanel')
)
const PlaceAdminPanel = React.lazy(() =>
  import('../Administration/PlaceAdminPanel/PlaceAdminPanel')
)
const GarageHallsAdminPanel = React.lazy(() =>
  import('../Administration/GaragesAdminPanel/GaragesAdminPanel')
)
const BuildingsAdminPanel = React.lazy(() =>
  import('../Administration/BuildingsAdminPanel/BuildingsAdminPanel')
)
const SalesRegisterLegalPerson = React.lazy(() =>
  import('../SalesRegister/SalesRegisterLegalPerson')
)
const SalesRegisterNaturalPerson = React.lazy(() =>
  import('../SalesRegister/SalesRegisterNaturalPerson')
)
const InterestedCustomers = React.lazy(() =>
  import('../InterestedCustomers/InterestedCustomers')
)
const Arrangements = React.lazy(() => import('../Arrangements/Arrangements'))
const Tasks = React.lazy(() => import('../Tasks/Tasks'))
const Place = React.lazy(() => import('../Place/Place'))
const FileHistory = React.lazy(() => import('../Place/FileHistory'))
const CustomerData = React.lazy(() => import('../Customer/CustomerData'))
const Contract = React.lazy(() => import('../Contract/Contract'))
const ContractEventsHistory = React.lazy(() =>
  import('../ContractHistory/ContractEventsHistory')
)
const ContractCorrespondenceHistory = React.lazy(() =>
  import('../ContractHistory/ContractCorrespondenceHistory')
)
const CancelledArchivalContracts = React.lazy(() =>
  import('../Header/CancelledArchivalContracts')
)
const CancelledContracts = React.lazy(() =>
  import('../Contract/cancelled-contracts/CancelledContracts')
)
const AdminITPanel = React.lazy(() => import('../AdminIT/AdminITPanel'))
const InvestmentAdminPanel = React.lazy(() =>
  import('../Administration/InvestmentsAdminPanel/InvestmentAdminPanel')
)
const DNIPlaceShares = React.lazy(() =>
  import('../DNIPlaceShares/DNIPlaceShares')
)
const BuildingAdministratorsAdminPanel = React.lazy(() =>
  import('../Administration/BuildingAdministratorsAdminPanel')
)
const NotarialOfficesAdminPanel = React.lazy(() =>
  import('../Administration/NotarialOfficesAdminPanel')
)
const LicensesPage = React.lazy(() => import('../Licenses/LicensesPage'))
const SettingsPage = React.lazy(() => import('../SettingsPage'))

const AppRouter: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch()
  const { roles } = useSelector((state: RootState) => state.globalUser)
  const { chosenPlacesContract } = useSelector(
    (state: RootState) => state.globalInvestment
  )
  const { mainTableHiddenColumns } = useSelector(
    (state: RootState) => state.persistSettings
  )
  const { numberOfNotTakenUnassignedCustomers } = useSelector(
    (state: RootState) => state.unassignedCustomers
  )
  const [numberOfUnassignedForUseEffect, setNumberOfUnassigned] = useState(
    numberOfNotTakenUnassignedCustomers
  )
  const [
    isUnassignedIntervalRunning,
    setIsUnassignedIntervalRunning,
  ] = useState(true)
  const isDNKCustomer = hasRoles(DNKRoles, roles || [])
  const isDSPCustomer = hasRoles(DSPRoles, roles || [])
  const isAlreadyHidden = Boolean(
    mainTableHiddenColumns?.firstName1 === false &&
      mainTableHiddenColumns?.lastName1 === false &&
      mainTableHiddenColumns?.firstName2 === false &&
      mainTableHiddenColumns?.lastName2 === false
  )
  const canSeeContractDetails =
    hasRoles(CorrespondenceEventsViewRoles, roles) && chosenPlacesContract
  const mainView =
    hasRoles(AdminITRoles, roles) || hasRoles(DMAAndDMAADMRoles, roles)
      ? '/main-table-view'
      : ''
  const needsTraderInitialsListRoleSpecific =
    hasRoles(DNKRoles, roles) ||
    hasRoles(DSPAndDSPADMRoles, roles) ||
    hasRoles(DARRoles, roles) ||
    hasRoles(DMAAndDMAADMRoles, roles)
  const isUnassignedCustomersView =
    history.location.pathname.indexOf('unassigned-customers') !== -1

  const onChangeChosenInvestment = useCallback(
    (selectedInvestment: IInvestment) => {
      const isSelecetedCanceledContracts = selectedInvestment.id === 0
      const isCancelledContractsLocation =
        history.location.pathname === '/cancelled-contracts'
      isSelecetedCanceledContracts && dispatch(push('/cancelled-contracts'))
      isCancelledContractsLocation && dispatch(push('/'))
      dispatch(setGlobalChosenInvestment(selectedInvestment))
      dispatch(setMainTableRowIndex(null))
      dispatch(setChosenPlacesContract(null))
    },
    [dispatch]
  )

  const onChangeChosenStage = useCallback(
    (selectedStage: IStage) => {
      dispatch(setMainTableCurrentPage(1))
      dispatch(setGlobalChosenStage(selectedStage))
      dispatch(setMainTableRowIndex(null))
      dispatch(setChosenPlacesContract(null))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(getIDsOfUnassignedCustomersWithActiveMessagesOrCustomerID())
    dispatch(getNumberOfMessagesFromUnassignedCustomers())
  }, [dispatch])

  useEffect(() => {
    if (isDSPCustomer || isDNKCustomer) {
      const interval = setInterval(() => {
        dispatch(getMessageAlerts()).then((res: any) => {
          const messageAlerts = res?.payload?.getMessageAlertsAndDelete || []
          if (messageAlerts.length > 0) {
            const leadEmail =
              messageAlerts.length === 1 ? messageAlerts[0]?.email : undefined
            infoToastNotify(
              leadEmail
                ? `Wpłynęła nowa wiadomość od ${leadEmail}`
                : 'Wpłynęły nowe wiadomości od podjętych leadów'
            )
            dispatch(setNumberOfMessagesChange(true))
          }
        })
      }, 60 * 200)
      return (): void => {
        clearInterval(interval)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    setIsUnassignedIntervalRunning(true)
  }, [numberOfNotTakenUnassignedCustomers])

  useEffect(() => {
    const hasStartValues =
      numberOfUnassignedForUseEffect === 0 ||
      numberOfNotTakenUnassignedCustomers === 0
    if (isDSPCustomer || isDNKCustomer) {
      const interval = setInterval(() => {
        dispatch(getIDsOfUnassignedCustomersWithActiveMessagesOrCustomerID())
        setNumberOfUnassigned(numberOfNotTakenUnassignedCustomers)
        if (
          !hasStartValues &&
          numberOfUnassignedForUseEffect !==
            numberOfNotTakenUnassignedCustomers &&
          isUnassignedIntervalRunning
        ) {
          infoToastNotify('Liczba Nieprzypisanych uległa zmianie')
          dispatch(setNumberOfMessagesChange(true))
          dispatch(getIDsOfUnassignedCustomersWithActiveMessagesOrCustomerID())
          setNumberOfUnassigned(numberOfNotTakenUnassignedCustomers + 1)
          !hasStartValues && setIsUnassignedIntervalRunning(false)
        }
      }, 60 * 200)
      return (): void => clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUnassignedIntervalRunning,
    numberOfNotTakenUnassignedCustomers,
    numberOfUnassignedForUseEffect,
  ])

  useEffect(() => {
    !isUnassignedCustomersView &&
      sessionStorage.removeItem('emailOfUnassignedCustomer')
  }, [isUnassignedCustomersView])

  useEffect(() => {
    !needsTraderInitialsListRoleSpecific && dispatch(getUserInitialsList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles.length])

  return (
    <ConnectedRouter history={history}>
      <Header
        onChangeChosenStage={onChangeChosenStage}
        onChangeChosenInvestment={onChangeChosenInvestment}
      />
      <Row className='d-flex justify-content-center mx-0'>
        <HeaderMenu />
      </Row>
      <Switch>
        <SuspendedRoute path={`${mainView}/cancelled-contracts`}>
          <CancelledContracts />
        </SuspendedRoute>
        <SuspendedRoute path={`${mainView}/archival-contracts`}>
          <CancelledArchivalContracts archivalContracts />
        </SuspendedRoute>
        <SuspendedRoute path={`${mainView}/valid-contract/place/file-history`}>
          <FileHistory />
        </SuspendedRoute>

        <SuspendedRoute
          path={`${mainView}/cancelled-contract/contract/contract-correspondence-history`}
        >
          {canSeeContractDetails && <ContractCorrespondenceHistory disabled />}
        </SuspendedRoute>
        <SuspendedRoute
          path={`${mainView}/valid-contract/contract/contract-correspondence-history`}
        >
          {canSeeContractDetails && <ContractCorrespondenceHistory />}
        </SuspendedRoute>
        <SuspendedRoute
          path={`${mainView}/archival-contract/contract/contract-correspondence-history`}
        >
          {canSeeContractDetails && <ContractCorrespondenceHistory disabled />}
        </SuspendedRoute>

        <SuspendedRoute
          path={`${mainView}/cancelled-contract/contract/contract-events-history`}
        >
          <ContractEventsHistory disabled />
        </SuspendedRoute>
        <SuspendedRoute
          path={`${mainView}/valid-contract/contract/contract-events-history`}
        >
          <ContractEventsHistory />
        </SuspendedRoute>
        <SuspendedRoute
          path={`${mainView}/archival-contract/contract/contract-events-history`}
        >
          <ContractEventsHistory disabled />
        </SuspendedRoute>
        <SuspendedRoute path={`${mainView}/cancelled-contract/contract`}>
          {canSeeContractDetails && (
            <Contract key={`cancelledContract-${chosenPlacesContract?.id}`} />
          )}
        </SuspendedRoute>
        <SuspendedRoute path={`${mainView}/valid-contract/contract`}>
          {canSeeContractDetails && (
            <Contract key={`cancelledContract-${chosenPlacesContract?.id}`} />
          )}
        </SuspendedRoute>
        <SuspendedRoute path={`${mainView}/archival-contract/contract`}>
          {canSeeContractDetails && (
            <Contract key={`archivalContract-${chosenPlacesContract?.id}`} />
          )}
        </SuspendedRoute>
        {!hasRoles(DPPRoles, roles) && (
          <SuspendedRoute path={`${mainView}/valid-contract/customer`}>
            {chosenPlacesContract && (
              <CustomerData
                key={`contractCustomer-${chosenPlacesContract.id}`}
                selectedPlace={chosenPlacesContract}
              />
            )}
          </SuspendedRoute>
        )}
        <SuspendedRoute path={`${mainView}/cancelled-contract/place`}>
          {chosenPlacesContract && <CancelledContractPlace />}
        </SuspendedRoute>
        <SuspendedRoute path={`${mainView}/cancelled-contract/customer`}>
          {chosenPlacesContract && <CancelledContractCustomerData />}
        </SuspendedRoute>
        <SuspendedRoute path={`${mainView}/valid-contract/place`}>
          {chosenPlacesContract && (
            <Place key={`placeValidContract${chosenPlacesContract.id}`} />
          )}
        </SuspendedRoute>
        <SuspendedRoute path={`${mainView}/archival-contract/place`}>
          {chosenPlacesContract && (
            <Place
              key={`placeArchivalContract${chosenPlacesContract.id}`}
              disabled
            />
          )}
        </SuspendedRoute>
        <SuspendedRoute path={`${mainView}/tasks`}>
          <Tasks />
        </SuspendedRoute>
        <SuspendedRoute path='/arrangements'>
          <Arrangements />
        </SuspendedRoute>
        <SuspendedRoute path='/interested-customers-sales-department'>
          <InterestedCustomers />
        </SuspendedRoute>
        <SuspendedRoute path='/unassigned-customers'>
          <TableUnassignedCustomers />
        </SuspendedRoute>
        <SuspendedRoute path='/natural-people'>
          {chosenPlacesContract && (
            <SalesRegisterNaturalPerson
              isEditButton={dispatch(hasUserEditCustomerAndContractDataRole())}
              selectedPlace={chosenPlacesContract}
            />
          )}
        </SuspendedRoute>
        <SuspendedRoute path='/legal-people'>
          {chosenPlacesContract && (
            <SalesRegisterLegalPerson selectedPlace={chosenPlacesContract} />
          )}
        </SuspendedRoute>
        <Route path='/mail-merge-report'>
          <DKOSerialCorrespondenceReport />
        </Route>
        <Route path='/dks-places-legal-person-report'>
          <DKSBusinessPlacesReport />
        </Route>
        <Route path='/cancelled-contracts-report'>
          <DSPCancelledContractsReport />
        </Route>
        <Route path='/places-list-report'>
          <DSPPlacesListReport />
        </Route>
        <Route path='/places-tally-report'>
          <DPPPlacesTallyReport />
        </Route>
        <Route path='/archival-contracts-report'>
          <CancelledArchivalContracts report archivalContracts />
        </Route>
        <Route path='/daily-sales-department-report'>
          <DSPDailyInterestedPartiesReport />
        </Route>
        <Route path='/tough-sales-report'>
          <BlueListSalesReport />
        </Route>
        <SuspendedRoute path='/building-administrators-admin'>
          <BuildingAdministratorsAdminPanel key='BuildingAdministratorsAdminPanelDNI' />
        </SuspendedRoute>
        <SuspendedRoute path='/offices-admin'>
          <NotarialOfficesAdminPanel key='NotarialOfficesAdminPanelDNI' />
        </SuspendedRoute>
        <SuspendedRoute path='/add-investment-admin'>
          <InvestmentAdminPanel key='AddUpdateTabsAdminPanelInvestment' />
        </SuspendedRoute>
        <SuspendedRoute path='/buildings-admin'>
          <BuildingsAdminPanel key='BuildingsAdminPanelDSP' />
        </SuspendedRoute>
        <SuspendedRoute path='/service-buildings-admin'>
          <BuildingsAdminPanel key='BuildingsAdminPanelDNK' />
        </SuspendedRoute>
        <SuspendedRoute path='/garages-admin'>
          <GarageHallsAdminPanel />
        </SuspendedRoute>
        <SuspendedRoute path='/service-garages-admin'>
          <GarageHallsAdminPanel />
        </SuspendedRoute>
        <SuspendedRoute path='/places-admin'>
          <PlaceAdminPanel />
        </SuspendedRoute>
        <SuspendedRoute path='/service-places-admin'>
          <PlaceAdminPanel />
        </SuspendedRoute>
        <SuspendedRoute path='/utility-rooms-admin'>
          <UtilityRoomAdminPanel key='AddUpdateTabsAdminPanelUtilityRooms' />
        </SuspendedRoute>
        <SuspendedRoute path='/service-utility-rooms-admin'>
          <UtilityRoomAdminPanel key='AddUpdateTabsAdminPanelUtilityRoomsService' />
        </SuspendedRoute>
        <SuspendedRoute path='/parking-places-admin'>
          <ParkingPlacesAdminPanel key='AddUpdateTabsAdminPanelParkingPlaces' />
        </SuspendedRoute>
        <SuspendedRoute path='/service-parking-places-admin'>
          <ParkingPlacesAdminPanel key='AddUpdateTabsAdminPanelParkingPlacesService' />
        </SuspendedRoute>
        <SuspendedRoute path='/tough-customers-admin'>
          <BlueListAdminPanel />
        </SuspendedRoute>
        <SuspendedRoute path='/service-land-strips-admin'>
          <LandStripAdminPanel />
        </SuspendedRoute>
        <SuspendedRoute path='/shares-admin'>
          <DNIPlaceShares />
        </SuspendedRoute>
        <Route path='/search'>
          <Search />
        </Route>
        <Route path='/panel'>
          <UserPanel />
        </Route>
        <Route path='/offices-report'>
          <NotarialOfficeReport />
        </Route>
        <Route path='/building-administrators-report'>
          <BuildingAdministratorsReport />
        </Route>
        <Route path='/interested-customers-traders-report'>
          <CustomerByTradersReport />
        </Route>
        <Route path='/execution-statuses-report'>
          <DKOExecutionStatusReport />
        </Route>
        <Route path='/receptions-report'>
          <DNIPickupScheduleReport />
        </Route>
        <Route path='/places-area-report'>
          <DKOAreasReport />
        </Route>
        <Route path='/acts-report'>
          <DNIActsReport />
        </Route>
        <Route path='/arrangements-report'>
          <DARArrangementReport />
        </Route>
        <Route path='/shares-report'>
          <DNIShareTableReport />
        </Route>
        <Route path='/interested-customers-broker-reports'>
          <DSPCustomersByBrokersReport isFromContract={false} />
        </Route>
        <Route path='/interested-customers-broker-contracts-reports'>
          <DSPCustomersByBrokersReport isFromContract={true} />
        </Route>
        <Route path='/concluded-contracts-report'>
          <DSPCustomerConcludedContractsReport />
        </Route>
        <Route path='/customer-source-contract-report'>
          <CustomerSourceContractReport />
        </Route>
        <Route path='/interested-customers-report'>
          <InterestedCustomersReport />
        </Route>
        <Route path='/summary-report-dsp'>
          <DSPSummaryReport />
        </Route>
        <Route path='/interested-customers-daily-report'>
          <DSPInterestedCustomersDailyReport />
        </Route>
        <Route path='/customer-resignation-report'>
          <DSPCustomerResignationReport />
        </Route>
        <Route path='/date-annexes-report'>
          <DARDateAnnexesReport />
        </Route>
        <Route path='/list-report'>
          <DARArrangementListReport />
        </Route>
        <Route path='/lead-and-interested-report'>
          <LeadAndInterestedReport />
        </Route>
        <SuspendedRoute path='/settings'>
          <SettingsPage />
        </SuspendedRoute>
        {(hasRoles(AdminITRoles, roles) ||
          hasRoles(DMAAndDMAADMRoles, roles)) && (
          <Route path='/main-table-view'>
            <MainTable isAlreadyHidden={isAlreadyHidden} />
          </Route>
        )}
        <SuspendedRoute path='/licenses'>
          <LicensesPage />
        </SuspendedRoute>
        <Route path='/'>
          {hasRoles(AdminITRoles, roles) ? (
            <React.Suspense fallback={<Loader />}>
              <AdminITPanel />
            </React.Suspense>
          ) : hasRoles(DMAAndDMAADMRoles, roles) ? (
            <React.Suspense fallback={<Loader />}>
              <DMAMarketingReport />
            </React.Suspense>
          ) : (
            <MainTable isAlreadyHidden={isAlreadyHidden} />
          )}
        </Route>
      </Switch>
    </ConnectedRouter>
  )
}

export default AppRouter
