import React from 'react'
import ReactDOM from 'react-dom'
import './i18n'
import * as serviceWorker from './serviceWorker'
import App from './components/App/App'
import './index.scss'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './ducks'
import { apolloClient } from './services/apollo-client'
import { ApolloProvider } from '@apollo/client'

const { persistor, store } = configureStore()

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
