import React, { FunctionComponent } from 'react'
import './Reports.scss'
import { useSelector } from 'react-redux'

import { RootState } from '../../ducks'
import { fetchDKOAreasReport } from '../../ducks/reports'

import { ReportWithList } from './ReportWithList'

const DKOAreasReport: FunctionComponent = () => {
  const { isLoading } = useSelector(
    (state: RootState) => state.combinedReports.DKOAreasReport
  )

  return (
    <ReportWithList
      reportName='Raport-powierzchni.xlsx'
      reportAPIName='DKOAreasReport'
      reduxAction={fetchDKOAreasReport}
      isLoading={isLoading}
    />
  )
}

export default DKOAreasReport
