import RaportsService, {
  DSPContractCustomerSearchReportProps,
  DSPCustomerSearchReportProps,
  IDKSBusinessPlacesReport,
  IDPPPlacesTallyReport,
  IDSPCustomerConcludedContractsReport,
  IDSPCustomerResignationReport,
  IDSPCustomersByBrokersContractsReport,
  IDSPDailyReport,
  IDSPPlacesListReport,
  IDSPSummaryReport,
  LeadAndInterestedReportProps,
} from '../services/reportsService'
import {
  AnyAction,
  AsyncThunk,
  CaseReducerActions,
  Draft,
  PayloadAction,
  Reducer,
  combineReducers,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { RootState } from './'
import InterestedCustomerService, {
  IDSPCustomerSourceInfoReportProps,
} from '../services/interestedCustomerService'

export interface IDMAReports {
  consentType?: string
  sourceInfo?: string[]
  traderInitials?: string[]
  endActDate?: string
  startActDate?: string
  startAcceptanceDate?: string
  endAcceptanceDate?: string
  endContractDealDate?: string
  startContractDate?: string
}

interface PlacesForReport {
  id: number
  name: string
  buildings: {
    id: number
    name: string
    isTypeService: boolean
    places: {
      id: number
      placeCode: string
      //TODO : activeContracts here works only with any
      activeContracts: any
    }[]
  }[]
}

interface ReportsState {
  placesForReport: PlacesForReport[]
  DARArrangementReport: string | undefined
  DSPBlueListReport: string | undefined
  DSPCancelledContractsReport: string | undefined
  DSPDailyInterestedPartiesReport: string | undefined
  DSPPlacesListReport: string | undefined
  DMAMarketingReport: string | undefined
  DARDateAnnexesReport: string | undefined
  DSPCustomerSearchReport: string | undefined
  isLoadingDARDateAnnexesReport: boolean
  LeadAndInterestedReport: string | undefined
}

interface ReducerStateType {
  data?: string
  isLoading: boolean
}

interface CreateApiDuckReturnType {
  reducer: Reducer<
    {
      data: undefined
      isLoading: boolean
    },
    AnyAction
  >
  actions: CaseReducerActions<{
    clearReportsState(
      state: Draft<{
        data?: undefined
        isLoading: boolean
      }>,
      _: PayloadAction<void>
    ): void
  }>
}

enum DATABASE_TYPE {
  CUSTOMER = 'Zainteresowani',
  CONTRACT_CUSTOMER = 'Klienci',
  COMPANIES = 'Spółki',
}

enum RAPORT_TYPE {
  MARKETING = 'Marketing',
  STATISTIC = 'Statystyczny',
}

const initialState = {
  placesForReport: [],
  DARArrangementReport: undefined,
  DSPBlueListReport: undefined,
  DSPCancelledContractsReport: undefined,
  DSPDailyInterestedPartiesReport: undefined,
  DSPPlacesListReport: undefined,
  DMAMarketingReport: undefined,
  DARDateAnnexesReport: undefined,
  DSPCustomerSearchReport: undefined,
  isLoadingDARDateAnnexesReport: false,
  LeadAndInterestedReport: undefined,
} as ReportsState

export const fetchDPPPlacesTallyReport = createAsyncThunk(
  'raports/fetchDPPPlacesTallyReport',
  async ({ investmentsIDs, stagesIDs }: IDPPPlacesTallyReport) => {
    return RaportsService.fetchDPPPlacesTallyReport({
      investmentsIDs,
      stagesIDs,
    })
  }
)

export const fetchDARArrangementReport = createAsyncThunk(
  'raports/fetchDARArrangementReport',
  async (placesIDs: number[]) => {
    return RaportsService.fetchDARArrangementReport(placesIDs)
  }
)

export const fetchStagesWithPlacesForReport = createAsyncThunk(
  'raports/fetchStagesWithPlacesForReport',
  async (investmentID: number) => {
    return RaportsService.getStagesWithInvestmentsForReport(investmentID)
  }
)

export const fetchDARArrangementListReport = createAsyncThunk(
  'raports/fetchDARArrangementListReport',
  async (stagesIDs: number[]) => {
    return RaportsService.fetchDARArrangementListReport(stagesIDs)
  }
)

export const fetchDKOSerialCorrespondenceReport = createAsyncThunk(
  'raports/fetchDKOSerialCorrespondenceReport',
  async (placeIDs: number[]) => {
    return RaportsService.fetchDKOSerialCorrespondenceReport(placeIDs)
  }
)

export const fetchDNIForNotarialOfficeReport = createAsyncThunk(
  'raports/fetchDNIForNotarialOfficeReport',
  async (placeIDs: number[]) => {
    return RaportsService.fetchDNIForNotarialOfficeReport(placeIDs)
  }
)

export const fetchDNIAdministrationReport = createAsyncThunk(
  'reports/fetchDNIAdministrationReport',
  async (placeIDs: number[]) => {
    return RaportsService.fetchDNIAdministrationReport(placeIDs)
  }
)
export const fetchDSPCustomerSourceContractReport = createAsyncThunk(
  'reports/fetchDSPCustomerSourceContractReport',
  async ({
    investmentIDs,
    toDate,
    fromDate,
    tradersInitials,
    sourceInfo,
    stagesIDs,
  }: {
    sourceInfo: string[]
    investmentIDs: number[]
    toDate: string
    fromDate: string
    tradersInitials?: string[]
    stagesIDs?: number[]
  }) => {
    return RaportsService.fetchDSPCustomerSourceContractReport({
      investmentIDs,
      toDate,
      fromDate,
      tradersInitials,
      sourceInfo,
      stagesIDs,
    })
  }
)

export const fetchDSPCustomerByTradersReport = createAsyncThunk(
  'reports/fetchDSPCustomerByTradersReport',
  async ({
    traderInitials,
    toDate,
    fromDate,
    stagesIDs,
    investmentIDs,
  }: {
    traderInitials: string[]
    toDate: string
    fromDate: string
    stagesIDs: number[]
    investmentIDs: number[]
  }) => {
    return RaportsService.fetchDSPCustomerByTradersReport({
      traderInitials,
      toDate,
      fromDate,
      stagesIDs,
      investmentIDs,
    })
  }
)

export const fetchDNIPickupScheduleReport = createAsyncThunk(
  'reports/fetchDNIPickupScheduleReport',
  async (placeIDs: number[]) => {
    return RaportsService.fetchDNIPickupScheduleReport(placeIDs)
  }
)

export const fetchDNIShareTableReport = createAsyncThunk(
  'reports/fetchDNIShareTableReport',
  async (placeIDs: number[]) => {
    return RaportsService.fetchDNIShareTableReport(placeIDs)
  }
)

export const fetchDKOAreasReport = createAsyncThunk(
  'raports/fetchDKOAreasReport',
  async (placeIDs: number[]) => {
    return RaportsService.fetchDKOAreasReport(placeIDs)
  }
)

export const fetchDARDateAnnexesReport = createAsyncThunk(
  'raports/fetchDARDateAnnexesReport',
  async (contractsIDs: number[]) => {
    return RaportsService.fetchDARDateAnnexesReport(contractsIDs)
  }
)

//1
export const fetchDKOExecutionStatusReport = createAsyncThunk(
  'reports/fetchDKOExecutionStatusReport',
  async (placeIDs: number[]) => {
    return RaportsService.fetchDKOExecutionStatusReport(placeIDs)
  }
)

export const fetchDNIActsReport = createAsyncThunk(
  'raports/fetchDNIActsReport',
  async (placeIDs: number[]) => {
    return RaportsService.fetchDNIActsReport(placeIDs)
  }
)

export const fetchDSPCustomersByBrokersContractsReport = createAsyncThunk(
  'reports/fetchDSPCustomersByBrokersReport',
  async (variables: IDSPCustomersByBrokersContractsReport) => {
    return RaportsService.fetchDSPCustomersByBrokersContractsReport(variables)
  }
)

export const fetchDSPCustomersByBrokersReport = createAsyncThunk(
  'raports/fetchDSPCustomersByBrokersReport',
  async ({
    toDate,
    fromDate,
    brokerNames,
    investmentIDs,
    stagesIDs,
  }: {
    toDate: string
    fromDate: string
    brokerNames: string[]
    investmentIDs: number[]
    stagesIDs: number[]
  }) => {
    return RaportsService.fetchDSPCustomersByBrokersReport({
      toDate,
      fromDate,
      brokerNames,
      investmentIDs,
      stagesIDs,
    })
  }
)

export const fetchDSPBlueListReport = createAsyncThunk(
  'reports/fetchDSPBlueListReport',
  async () => RaportsService.fetchDSPBlueListReport()
)

export const fetchDSPCancelledContractsReport = createAsyncThunk(
  'reports/fetchDSPCancelledContractsReport',
  async () => {
    return RaportsService.fetchDSPCancelledContractsReport()
  }
)

export const fetchDSPPlacesListReport = createAsyncThunk(
  'reports/fetchDSPPlacesListReport',
  async (variables: IDSPPlacesListReport) => {
    return RaportsService.fetchDSPPlacesListReport(variables)
  }
)

export const fetchDSPSummaryReport = createAsyncThunk(
  'reports/fetchDSPSummaryReport',
  async (variables: IDSPSummaryReport) => {
    return RaportsService.fetchDSPSummaryReport(variables)
  }
)

export const fetchDSPInterestedCustomersDailyReport = createAsyncThunk(
  'reports/fetchDSPInterestedCustomersDailyReport',
  async ({ startDate, endDate, stagesIDs }: IDSPDailyReport) => {
    return RaportsService.fetchDSPInterestedCustomersDailyReport({
      startDate,
      endDate,
      stagesIDs,
    })
  }
)

export const fetchDSPCustomerResignationReport = createAsyncThunk(
  'reports/fetchDSPCustomerResignationReport',
  async (variables: IDSPCustomerResignationReport) => {
    return RaportsService.fetchDSPCustomerResignationReport(variables)
  }
)

export const fetchDSPCustomerConcludedContractsReport = createAsyncThunk(
  'reports/fetchDSPCustomerConcludedContractsReport',
  async (variables: IDSPCustomerConcludedContractsReport) => {
    return RaportsService.fetchDSPCustomerConcludedContractsReport(variables)
  }
)

export const fetchDSPDailyInterestedPartiesReport = createAsyncThunk(
  'reports/fetchDSPDailyInterestedPartiesReport',
  async () => RaportsService.fetchDSPDailyInterestedPartiesReport()
)

export const fetchDMAMarketingCustomerReport = createAsyncThunk(
  'reports/fetchDMAMarketingCustomerReport',
  async (variables: IDMAReports) =>
    RaportsService.fetchDMAMarketingCustomerReport(variables)
)

export const fetchDMAStatisticCustomerReport = createAsyncThunk(
  'reports/fetchDMAStatisticCustomerReport',
  async (variables: IDMAReports) =>
    RaportsService.fetchDMAStatisticCustomerReport(variables)
)

export const fetchDMAMarketingContractCustomerReport = createAsyncThunk(
  'reports/fetchDMAMarketingContractCustomerReport',
  async (variables: IDMAReports) =>
    RaportsService.fetchDMAMarketingContractCustomerReport(variables)
)

export const fetchDMAStatisticContractCustomerReport = createAsyncThunk(
  'reports/fetchDMAStatisticContractCustomerReport',
  async (variables: IDMAReports) =>
    RaportsService.fetchDMAStatisticContractCustomerReport(variables)
)

export const fetchDMAMarketingContractCustomerCompaniesReport = createAsyncThunk(
  'reports/fetchDMAMarketingContractCustomerCompaniesReport',
  async (variables: IDMAReports) =>
    RaportsService.fetchDMAMarketingContractCustomerCompaniesReport(variables)
)

export const fetchDMAStatisticContractCustomerCompaniesReport = createAsyncThunk(
  'reports/fetchDMAStatisticContractCustomerCompaniesReport',
  async (variables: IDMAReports) =>
    RaportsService.fetchDMAStatisticContractCustomerCompaniesReport(variables)
)

export const fetchDKSBusinessPlacesReport = createAsyncThunk(
  'raports/fetchDKSBusinessPlacesReport',
  async (arg: IDKSBusinessPlacesReport) => {
    return await RaportsService.fetchDKSBusinessPlacesReport(arg)
  }
)

export const getDSPCustomerSourceInfoReport = createAsyncThunk(
  'interestedCustomers/getDSPCustomerSourceInfoReport',
  async (variables: {
    fromDate: string
    toDate: string
    sourceInfo?: string[]
    stagesIDs?: number[]
  }) => {
    return InterestedCustomerService.fetchDSPCustomerSourceInfoReport(
      variables as IDSPCustomerSourceInfoReportProps
    )
  }
)

export const fetchDSPCustomerSearchReport = createAsyncThunk(
  'reports/fetchDSPCustomerSearchReport',
  async (variables: DSPCustomerSearchReportProps) =>
    RaportsService.fetchDSPCustomerSearchReport(variables)
)

export const fetchDSPContractCustomerSearchReport = createAsyncThunk(
  'reports/fetchDSPContractCustomerSearchReport',
  async (variables: DSPContractCustomerSearchReportProps) =>
    RaportsService.fetchDSPContractCustomerSearchReport(variables)
)

export const fetchLeadAndInterestedReport = createAsyncThunk(
  'raports/fetchLeadAndInterestedReport',
  async (variables: LeadAndInterestedReportProps) => {
    return RaportsService.fetchLeadAndInterestedReport(variables)
  }
)

export function createApiDuck<ReportType>(
  name: string,
  thunk: AsyncThunk<any, ReportType, {}>
): CreateApiDuckReturnType {
  const initialState = {
    data: undefined,
    isLoading: false,
  }

  const slice = createSlice({
    name,
    initialState,
    reducers: {
      clearReportsState(state, _: PayloadAction<void>): void {
        state.data = undefined
      },
    },
    extraReducers: {
      [thunk.pending.toString()]: (state): void => {
        state.isLoading = true
      },
      [thunk.fulfilled.toString()]: (state, action): void => {
        state.data = action.payload[name]
        state.isLoading = false
      },
      [thunk.rejected.toString()]: (state): void => {
        state.isLoading = false
      },
    },
  })

  return {
    reducer: slice.reducer,
    actions: slice.actions,
  }
}
const {
  reducer: DNIActsReportReducer,
  actions: DNIActsReportActions,
} = createApiDuck('DNIActScheduleReport', fetchDNIActsReport)
const {
  reducer: DKOAreasReportReducer,
  actions: DKOAreasReportActions,
} = createApiDuck('DKOAreasReport', fetchDKOAreasReport)
const {
  reducer: DKOSerialCorrespondenceReportReducer,
  actions: DKOSerialCorrespondenceReportActions,
} = createApiDuck(
  'DKOSerialCorrespondenceReport',
  fetchDKOSerialCorrespondenceReport
)
const {
  reducer: DNIForNotarialOfficeReportReducer,
  actions: DNIForNotarialOfficeReportActions,
} = createApiDuck('DNIForNotarialOfficeReport', fetchDNIForNotarialOfficeReport)
const {
  reducer: DNIAdministrationReportReducer,
  actions: DNIAdministrationReportActions,
} = createApiDuck('DNIForAdministrationReport', fetchDNIAdministrationReport)
const {
  reducer: DNIPickupScheduleReportReducer,
  actions: DNIPickupScheduleReportActions,
} = createApiDuck('DNIPickupScheduleReport', fetchDNIPickupScheduleReport)
const {
  reducer: DNIShareTableReportReducer,
  actions: DNIShareTableReportActions,
} = createApiDuck('DNIShareTableReport', fetchDNIShareTableReport)
const {
  reducer: DKOExecutionStatusReportReducer,
  actions: DKOExecutionStatusReportActions,
} = createApiDuck('DKOExecutionStatusReport', fetchDKOExecutionStatusReport)
const {
  reducer: DARArrangementListReportReducer,
  actions: DARArrangementListReportActions,
} = createApiDuck('DARArrangementListReport', fetchDARArrangementListReport)
const {
  reducer: DSPCustomerSourceInfoReportReducer,
  actions: DSPCustomerSourceInfoReportActions,
} = createApiDuck('DSPCustomerSourceInfoReport', getDSPCustomerSourceInfoReport)
const {
  reducer: DSPCustomerConcludedContractsReportReducer,
  actions: DSPCustomerConcludedContractsReportActions,
} = createApiDuck(
  'DSPCustomerConcludedContractsReport',
  fetchDSPCustomerConcludedContractsReport
)
const {
  reducer: DSPCustomerResignationReportReducer,
  actions: DSPCustomerResignationReportActions,
} = createApiDuck(
  'DSPCustomerResignationReport',
  fetchDSPCustomerResignationReport
)
const {
  reducer: DSPInterestedCustomersDailyReportReducer,
  actions: DSPInterestedCustomersDailyReportActions,
} = createApiDuck(
  'DSPInterestedCustomersDailyReport',
  fetchDSPInterestedCustomersDailyReport
)
const {
  reducer: DSPSummaryReportReducer,
  actions: DSPSummaryReportActions,
} = createApiDuck('DSPSummaryReport', fetchDSPSummaryReport)
const {
  reducer: DSPPlacesListReportReducer,
  actions: DSPPlacesListReportActions,
} = createApiDuck('DSPPlacesListReport', fetchDSPPlacesListReport)
const {
  reducer: DSPCustomersByBrokersReportReducer,
  actions: DSPCustomersByBrokersReportActions,
} = createApiDuck(
  'DSPCustomersByBrokersReport',
  fetchDSPCustomersByBrokersReport
)
const {
  reducer: DSPCustomerByTradersReportReducer,
  actions: DSPCustomerByTradersReportActions,
} = createApiDuck('DSPCustomerByTradersReport', fetchDSPCustomerByTradersReport)
const {
  reducer: DSPCustomerSourceContractReportReducer,
  actions: DSPCustomerSourceContractReportActions,
} = createApiDuck(
  'DSPCustomerSourceContractReport',
  fetchDSPCustomerSourceContractReport
)
const {
  reducer: DKSBusinessPlacesReportReducer,
  actions: DKSBusinessPlacesReportActions,
} = createApiDuck('DKSBusinessPlacesReport', fetchDKSBusinessPlacesReport)
const {
  reducer: DMAMarketingContractCustomerCompaniesReportReducer,
  actions: DMAMarketingContractCustomerCompaniesReportActions,
} = createApiDuck(
  'DMAMarketingContractCustomerCompaniesReport',
  fetchDMAMarketingContractCustomerCompaniesReport
)
const {
  reducer: DMAStatisticContractCustomerReportReducer,
  actions: DMAStatisticContractCustomerReportActions,
} = createApiDuck(
  'DMAStatisticContractCustomerReport',
  fetchDMAStatisticContractCustomerReport
)
const {
  reducer: DMAMarketingContractCustomerReportReducer,
  actions: DMAMarketingContractCustomerReportActions,
} = createApiDuck(
  'DMAMarketingContractCustomerReport',
  fetchDMAMarketingContractCustomerReport
)
const {
  reducer: DMAStatisticCustomerReportReducer,
  actions: DMAStatisticCustomerReportActions,
} = createApiDuck('DMAStatisticCustomerReport', fetchDMAStatisticCustomerReport)
const {
  reducer: DMAMarketingCustomerReportReducer,
  actions: DMAMarketingCustomerReportActions,
} = createApiDuck('DMAMarketingCustomerReport', fetchDMAMarketingCustomerReport)
const {
  reducer: DMAStatisticContractCustomerCompaniesReportReducer,
  actions: DMAStatisticContractCustomerCompaniesReportActions,
} = createApiDuck(
  'DMAStatisticContractCustomerCompaniesReport',
  fetchDMAStatisticContractCustomerCompaniesReport
)
const {
  reducer: DSPCustomerSearchReportReducer,
  actions: DSPCustomerSearchReportReducerActions,
} = createApiDuck('fetchDSPCustomerSearchReport', fetchDSPCustomerSearchReport)
const {
  reducer: LeadAndInterestedReportReducer,
  actions: LeadAndInterestedReportReducerActions,
} = createApiDuck('fetchDSPCustomerSearchReport', fetchLeadAndInterestedReport)

export const clearDMAReportsState = createAsyncThunk(
  'reports/clearCustomReportsState',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(
      DMAMarketingContractCustomerCompaniesReportActions.clearReportsState()
    )
    thunkAPI.dispatch(
      DMAStatisticContractCustomerReportActions.clearReportsState()
    )
    thunkAPI.dispatch(
      DMAMarketingContractCustomerReportActions.clearReportsState()
    )
    thunkAPI.dispatch(DMAStatisticCustomerReportActions.clearReportsState())
    thunkAPI.dispatch(DMAMarketingCustomerReportActions.clearReportsState())
    thunkAPI.dispatch(
      DMAStatisticContractCustomerCompaniesReportActions.clearReportsState()
    )
  }
)

export const clearReportState = createAsyncThunk(
  'reports/clearReportState',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(DNIForNotarialOfficeReportActions.clearReportsState())
    thunkAPI.dispatch(DNIAdministrationReportActions.clearReportsState())
    thunkAPI.dispatch(DNIPickupScheduleReportActions.clearReportsState())
    thunkAPI.dispatch(DNIShareTableReportActions.clearReportsState())
    thunkAPI.dispatch(DKOExecutionStatusReportActions.clearReportsState())
    thunkAPI.dispatch(DARArrangementListReportActions.clearReportsState())
    thunkAPI.dispatch(DKOSerialCorrespondenceReportActions.clearReportsState())
    thunkAPI.dispatch(DKOAreasReportActions.clearReportsState())
    thunkAPI.dispatch(DNIActsReportActions.clearReportsState())
  }
)

export const getDMAReportState = (
  reportType: string,
  consentType: string,
  databeseType: string
) =>
  createSelector(
    (state: RootState) => state.combinedReports,
    (combinedReports) => {
      let reportState: ReducerStateType = {} as ReducerStateType
      if (databeseType === DATABASE_TYPE.COMPANIES) {
        if (Boolean(consentType) && reportType === RAPORT_TYPE.MARKETING) {
          reportState =
            combinedReports.DMAMarketingContractCustomerCompaniesReport
        }
        if (reportType === RAPORT_TYPE.STATISTIC) {
          reportState =
            combinedReports.DMAStatisticContractCustomerCompaniesReport
        }
      } else if (databeseType === DATABASE_TYPE.CONTRACT_CUSTOMER) {
        if (Boolean(consentType) && reportType === RAPORT_TYPE.MARKETING) {
          reportState = combinedReports.DMAMarketingContractCustomerReport
        }
        if (reportType === RAPORT_TYPE.STATISTIC) {
          reportState = combinedReports.DMAStatisticContractCustomerReport
        }
      } else if (databeseType === DATABASE_TYPE.CUSTOMER) {
        if (Boolean(consentType) && reportType === RAPORT_TYPE.MARKETING) {
          reportState = combinedReports.DMAMarketingCustomerReport
        }
        if (reportType === RAPORT_TYPE.STATISTIC) {
          reportState = combinedReports.DMAStatisticCustomerReport
        }
      }
      return reportState
    }
  )

export const downloadXlsxReport = createAsyncThunk(
  'reports/downloadReport',
  async (variables: { content: string; filename: string }, _) => {
    const byteCharacters = atob(variables.content)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512)
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays)
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', variables.filename)
    document.body.appendChild(link)
    link.click()
  }
)

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clearReportsState(state, _: PayloadAction<void>): void {
      state.DARDateAnnexesReport = undefined
      state.DARArrangementReport = undefined
      state.DSPBlueListReport = undefined
      state.DSPCancelledContractsReport = undefined
      state.DSPDailyInterestedPartiesReport = undefined
      state.DSPPlacesListReport = undefined
      state.DMAMarketingReport = undefined
      state.DSPCustomerSearchReport = undefined
      state.LeadAndInterestedReport = undefined
    },
  },
  extraReducers: {
    [fetchDARArrangementReport.fulfilled.toString()]: (state, action): void => {
      if (action.payload.DARArrangementReport) {
        state.DARArrangementReport = action.payload.DARArrangementReport
      }
    },
    [fetchStagesWithPlacesForReport.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.placesForReport = action.payload.investment?.stages ?? []
    },
    [fetchDARArrangementReport.rejected.toString()]: (state, _): void => {
      state.DARArrangementReport = undefined
    },
    [fetchDSPBlueListReport.fulfilled.toString()]: (state, action): void => {
      if (action.payload.DSPBlueListReport) {
        state.DSPBlueListReport = action.payload.DSPBlueListReport
      }
    },
    [fetchDSPCancelledContractsReport.fulfilled.toString()]: (
      state,
      action
    ): void => {
      if (action.payload.DSPCancelledContractsReport) {
        state.DSPCancelledContractsReport =
          action.payload.DSPCancelledContractsReport
      }
    },
    [fetchDSPCancelledContractsReport.rejected.toString()]: (
      state,
      _
    ): void => {
      state.DSPCancelledContractsReport = undefined
    },
    [fetchDSPDailyInterestedPartiesReport.fulfilled.toString()]: (
      state,
      action
    ): void => {
      if (action.payload.DSPDailyInterestedPartiesReport) {
        state.DSPDailyInterestedPartiesReport =
          action.payload.DSPDailyInterestedPartiesReport
      }
    },
    [fetchDSPDailyInterestedPartiesReport.rejected.toString()]: (
      state,
      _
    ): void => {
      state.DSPDailyInterestedPartiesReport = undefined
    },
    [fetchDMAStatisticContractCustomerReport.fulfilled.toString()]: (
      state,
      action
    ): void => {
      if (action.payload.DMAStatisticContractCustomerReport) {
        state.DMAMarketingReport =
          action.payload.DMAStatisticContractCustomerReport
      }
    },
    [fetchDMAStatisticContractCustomerReport.rejected.toString()]: (
      state,
      _
    ): void => {
      state.DMAMarketingReport = undefined
    },
    [fetchDARDateAnnexesReport.fulfilled.toString()]: (state, action): void => {
      if (action.payload.DARDateAnnexesReport) {
        state.DARDateAnnexesReport = action.payload.DARDateAnnexesReport
        state.isLoadingDARDateAnnexesReport = false
      }
    },
    [fetchDARDateAnnexesReport.pending.toString()]: (state, _): void => {
      state.isLoadingDARDateAnnexesReport = true
    },
    [fetchDARDateAnnexesReport.rejected.toString()]: (state, _): void => {
      state.DARDateAnnexesReport = undefined
      state.isLoadingDARDateAnnexesReport = false
    },
    [fetchDSPCustomerSearchReport.fulfilled.toString()]: (
      state,
      action
    ): void => {
      if (action.payload.DSPCustomerSearchReport) {
        state.DSPCustomerSearchReport = action.payload.DSPCustomerSearchReport
      }
    },
    [fetchDSPCustomerSearchReport.rejected.toString()]: (state, _): void => {
      state.DSPCustomerSearchReport = undefined
    },
    [fetchLeadAndInterestedReport.fulfilled.toString()]: (
      state,
      action
    ): void => {
      if (action.payload.MiniCRMUnassignedCustomersAndCustomersReport) {
        state.LeadAndInterestedReport =
          action.payload.MiniCRMUnassignedCustomersAndCustomersReport
      }
    },
    [fetchLeadAndInterestedReport.rejected.toString()]: (state, _): void => {
      state.DSPCustomerSearchReport = undefined
    },
  },
})

export const reportsActions = {
  DSPCustomerConcludedContractsReportActions,
  DSPCustomerResignationReportActions,
  DSPInterestedCustomersDailyReportActions,
  DSPSummaryReportActions,
  DSPPlacesListReportActions,
  DSPCustomersByBrokersReportActions,
  DSPCustomerByTradersReportActions,
  DSPCustomerSourceContractReportActions,
  DKSBusinessPlacesReportActions,
  DMAMarketingContractCustomerCompaniesReportActions,
  DMAStatisticContractCustomerReportActions,
  DMAMarketingContractCustomerReportActions,
  DMAStatisticCustomerReportActions,
  DMAMarketingCustomerReportActions,
  DMAStatisticContractCustomerCompaniesReportActions,
  DSPCustomerSourceInfoReportActions,
  DSPCustomerSearchReportReducerActions,
  LeadAndInterestedReportReducerActions,
}

export const combinedReportReducers = combineReducers({
  DKSBusinessPlacesReport: DKSBusinessPlacesReportReducer,
  DMAStatisticContractCustomerCompaniesReport: DMAStatisticContractCustomerCompaniesReportReducer,
  DMAMarketingContractCustomerCompaniesReport: DMAMarketingContractCustomerCompaniesReportReducer,
  DMAStatisticContractCustomerReport: DMAStatisticContractCustomerReportReducer,
  DSPCustomerConcludedContractsReport: DSPCustomerConcludedContractsReportReducer,
  DSPCustomerResignationReport: DSPCustomerResignationReportReducer,
  DSPInterestedCustomersDailyReport: DSPInterestedCustomersDailyReportReducer,
  DSPSummaryReport: DSPSummaryReportReducer,
  DSPPlacesListReport: DSPPlacesListReportReducer,
  DSPCustomersByBrokersReport: DSPCustomersByBrokersReportReducer,
  DSPCustomerByTradersReport: DSPCustomerByTradersReportReducer,
  DSPCustomerSourceContractReport: DSPCustomerSourceContractReportReducer,
  DMAMarketingContractCustomerReport: DMAMarketingContractCustomerReportReducer,
  DMAStatisticCustomerReport: DMAStatisticCustomerReportReducer,
  DMAMarketingCustomerReport: DMAMarketingCustomerReportReducer,
  DSPCustomerSourceInfoReport: DSPCustomerSourceInfoReportReducer,
  DARArrangementListReport: DARArrangementListReportReducer,
  DSPCustomerSearchReport: DSPCustomerSearchReportReducer,
  DKOExecutionStatusReport: DKOExecutionStatusReportReducer,
  DNIShareTableReport: DNIShareTableReportReducer,
  DNIPickupScheduleReport: DNIPickupScheduleReportReducer,
  DNIAdministrationReport: DNIAdministrationReportReducer,
  DNIForNotarialOfficeReport: DNIForNotarialOfficeReportReducer,
  DKOSerialCorrespondenceReport: DKOSerialCorrespondenceReportReducer,
  DKOAreasReport: DKOAreasReportReducer,
  DNIActsReport: DNIActsReportReducer,
  LeadAndInterestedReport: LeadAndInterestedReportReducer,
})

export const { clearReportsState } = reportsSlice.actions
export default reportsSlice.reducer
