import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import { fetchInvestmentsWithStagesOnly } from '../../ducks/globalInvestment'
import { getPlacesByInvestmentsNamesForSelectList } from '../../ducks/places'

interface IProposedItem {
  id: number
  name: string
}

interface IUseDataForProposedInputs {
  proposedState: {
    stage: IProposedItem
    investment: IProposedItem
    place: IProposedItem
  }
}

export const useDataForProposedInputs = ({
  proposedState,
}: IUseDataForProposedInputs): any => {
  const { adminPlaces } = useSelector((state: RootState) => state.places)
  const { investmentsList } = useSelector(
    (state: RootState) => state.globalInvestment
  )
  const dispatch = useDispatch()

  const investments = !!investmentsList ? investmentsList : []
  const investmentsWithFirstPositionEmpty = React.useMemo(() => {
    const excludedInvestmentNames = ['Wszystkie', 'Umowy anulowane']
    const notArchivedInvestments = investments.filter(
      (investment) =>
        !investment.isArchive &&
        !excludedInvestmentNames.includes(investment.name)
    )
    return [{ id: -1, name: '', stages: [] }, ...notArchivedInvestments]
  }, [investments])

  const placesByChosenStage = React.useMemo(() => {
    const places = adminPlaces.filter(
      (place) => place.stage.id === proposedState.stage?.id
    )
    const placesForList =
      places.map((place) => {
        return {
          name: place.placeCode,
          id: place.id,
        }
      }) || []
    return [{ id: -1, name: '' }, ...placesForList]
  }, [adminPlaces, proposedState.stage])

  const stagesByInvestment = React.useMemo(() => {
    const investment = investmentsWithFirstPositionEmpty.filter(
      (inv) => inv.id === proposedState.investment?.id
    )
    const stages = investment?.[0]?.stages || []
    return [{ id: -1, name: '' }, ...stages]
  }, [investmentsWithFirstPositionEmpty, proposedState.investment])

  React.useEffect(() => {
    dispatch(fetchInvestmentsWithStagesOnly())
  }, [dispatch])

  React.useEffect(() => {
    dispatch(
      getPlacesByInvestmentsNamesForSelectList([
        proposedState.investment?.name || investments[0]?.name,
      ])
    )
  }, [dispatch, investments, proposedState.investment])

  return {
    stagesByInvestment,
    placesByChosenStage,
    investmentsWithFirstPositionEmpty,
  }
}
