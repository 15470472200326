import { useEffect, useState } from 'react'

export enum StorageKeys {
  MainTable = 'MainTable',
  InterestedCustomersTable = 'InterestedCustomersTable',
  MessagesOfUnassignedCustomersTable = 'MessagesOfUnassignedCustomersTable',
  BuildingAdministratorsAdmin = 'BuildingAdministratorsAdmin',
  BuildingsTable = 'BuildingsTable',
  GaragesAdminPanel = 'GaragesAdminPanel',
  InvestmentDataAdminPanel = 'InvestmentDataAdminPanel',
  ParkingPlacesAdminPanel = 'ParkingPlacesAdminPanel',
  EditPlaceAdminPanelTable = 'EditPlaceAdminPanelTable',
  EditPlaceAdminPanelTableUtilRooms = 'EditPlaceAdminPanelTableUtilRooms',
  PlaceAdminPanel = 'PlaceAdminPanel',
  UtilityRoomAdminPanel = 'UtilityRoomAdminPanel',
  AdminClientsInInvestments = 'AdminClientsInInvestments',
  EmployeeLogs = 'EmployeeLogs',
  EmployeesList = 'EmployeesList',
  ArrangementsTable = 'ArrangementsTable',
  AddBlueListCustomer = 'AddBlueListCustomer',
  BlueListSales = 'BlueListSales',
  AttorneyTable = 'AttorneyTable',
  CorrespondenceTable = 'CorrespondenceTable',
  EventsTable = 'EventsTable',
  NotesTable = 'NotesTable',
  CancelledContracts = 'CancelledContracts',
  InterestedEventsTable = 'InterestedEventsTable',
  DisclaimersAnnexTable = 'DisclaimersAnnexTable',
  FileHistory = 'FileHistory',
  PlaceAnnexTable = 'PlaceAnnexTable',
  ProtocolsAnnexTable = 'ProtocolsAnnexTable',
  UnassignedParkingPlaces = 'UnassignedParkingPlaces',
  UnassignedUtilityRooms = 'UnassignedUtilityRooms',
  ContactWithClientsTable = 'ContactWithClientsTable',
  UnassignedCustomersTable = 'UnassignedCustomersTable',
  BannedEmails = 'BannedEmails',
}

type StorageType = 'localStorage' | 'sessionStorage'

export function useBrowserStorage<T>(
  key?: StorageKeys,
  storageType?: StorageType,
  initialValue?: T
): any {
  //Cant type. No tuples support
  const storage = storageType || 'sessionStorage'
  function readValue(): any {
    try {
      const item = key && window[storage].getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return initialValue
    }
  }

  const [storedValue, setStoredValue] = useState<T>(readValue)

  useEffect(() => {
    key && window[storage].setItem(key, JSON.stringify(storedValue))
  }, [initialValue, key, storedValue, storage])

  return [storedValue, setStoredValue]
}
